import {Component, OnInit} from '@angular/core';
import {SharedService} from '../shared.service';
import {ApiService} from '../api.service';
import {filter} from 'rxjs/operators';
import {runway_use_codes as RU_CODES, chart_sidenav as CHART_TEXT, flight_toolbar as FLIGHT_TEXT, aircraft_opertype as AIRCRAFT_TYPES} from '../env-translate';
import {runway_use_colors as RU_COLORS, site_defaults as DEFAULTS, chart_sidenav as CHART_CONST, global_chart_config} from '../env-constants';


@Component({
  selector: 'app-chart-sidenav',
  templateUrl: './chart-sidenav.component.html',
  styleUrls: []
})

export class ChartSidenavComponent implements OnInit {

  public LABEL_HEADER = CHART_TEXT.LABEL_HEADER;
  public SUMMARY = CHART_TEXT.SUMMARY;
  public RUS_HREF = CHART_CONST.RUS_HREF;
  public LINK_TEXT = CHART_TEXT.LINK_TEXT;
  public LABEL_NO_DATA = CHART_TEXT.LABEL_NO_DATA;
  public label_chart_header = DEFAULTS.MAIN_AIRPORT_LOCAL_CODE + ' ' + CHART_TEXT.LABEL_AIRPORT_HEADER;
  public label_chart_date;
  public hasOpsData: boolean;
  private _mainAirportCode = DEFAULTS.MAIN_AIRPORT_LOCAL_CODE;
  private _REPLAY_OPTIONS_ANIMATED = FLIGHT_TEXT.REPLAY_OPTIONS_ANIMATED;
  private _REPLAY_OPTIONS_REALTIME = FLIGHT_TEXT.REPLAY_OPTIONS_REALTIME;
  private _replayType: string;
  private _systemClock: any;
  private _currentChartDate: any;

  public GLOBAL_CHART_CONFIG = global_chart_config;
  public chart1Data: any;
  public chart1Layout: any;
  public chart2Data: any;
  public chart2Layout: any;

  constructor(private _sharedService: SharedService, private _apiService: ApiService) {}

  ngOnInit() {
    // Initialize the services
    this._sharedService.replay$.subscribe(
      data => this._replayType = data
    );

    this._sharedService.systemClock$.subscribe(
      data => this._systemClock = data
    );

    // Triggers the charts when the sidenav is opened
    this._sharedService.sidenavClicked$
      .pipe(filter(data => data === 'charts'))
      .subscribe(
      data => this._startCharts()
      );
  }


  private _startCharts() {
    /*
     * Starts the charting process by making an api call to get RUS data
     * Does not work with replay = Static
     */
    let currentDateTime: Date;

    // Wipe the charts that already exist
    this.hasOpsData = false;
    this.chart1Data = null;
    this.chart2Data = null;

    if (this._replayType === this._REPLAY_OPTIONS_ANIMATED) {
      currentDateTime = this._systemClock.animatedCurrent;
    } else {
      currentDateTime = this._systemClock.realtimeCurrent;
    }
    this.label_chart_date = !!currentDateTime ? currentDateTime.toDateString() : '';

    // Don't try and pull data if we have no date (animated won't have a date until the user runs an animated query for the first time)
    if (!!currentDateTime) {
      this._currentChartDate = new Date(currentDateTime.toDateString());

      this._apiService.callRunwayUseSummary(this._mainAirportCode, this._currentChartDate).subscribe(
        data => {
          this._parseData(data);
        }
      );
    }
  }

  private _parseData(data: any): void {
    /*
     * Sort through the array of operations and populate the objects we need to create our charts
     * Only pull MSP data (airport === 'MSP')
     */

    data.sort(
      function(a, b) {
        const atime = new Date(a['stime']).valueOf();
        const btime = new Date(b['stime']).valueOf();
        return atime < btime ? -1 : (atime > btime ? 1 : 0);
      }
    );

    const opsByHour = {
      opCounts: [],
      hours: [],
      labels: [],
      colors: []
    };
    const aircraftCounts = {};

    // Loop through each hour and craft the operations data array
    for (let x = 0, y = 24; x < y; x++) {
      const tmp = data[x];
      // If we are missing hours then the chart will look weird, so that's
      // a source data issue.  this continue prevents the UI from crashing
      // when trying to display the chart though, which helps.
      if (! tmp) {
        continue;
      }
      const ru_code = tmp['label'] || 'NULL',
            total_ops = tmp['total_operations'];


      var op_count = 0; // total_operations sometimes returns a null value,
      opsByHour.hours.push(x);
      opsByHour.colors.push(RU_COLORS[ru_code]);

      // Now populate the operations counts for our aircraft types
      for (const key in tmp['total_operations']) {
        if (key !== 'total') {
          const count = tmp['total_operations'][key];
          aircraftCounts[key] = (aircraftCounts[key] === undefined) ? count : aircraftCounts[key] + count;
          op_count += count;
        }
      }
      opsByHour.opCounts.push(op_count);
      const runway_use = op_count === 0 ? '' : RU_CODES[ru_code]; // runway use is blank if we have no operations for the hour
      const text = CHART_TEXT.TEXT_HOUR + ' ' + x + '<br>' + CHART_TEXT.TEXT_OPERATION + ': ' + op_count + (!!runway_use ? '<br>' + runway_use : '');
      opsByHour.labels.push(text);
      // Set the hasOpsData flag

      if (!!total_ops) {
        this.hasOpsData = true;
      }
    }

    // Only build the charts if we actually have operations data
    if (this.hasOpsData) {
      this._buildOpsChart(opsByHour);
      this._buildAircraftChart(aircraftCounts);
    }

  }

  private _buildOpsChart(opsByHour: any): void {
    /*
     * Create the Operations by Hour chart
     */
    this.chart1Data = [{
      x: opsByHour['hours'],
      y: opsByHour['opCounts'],
      text: opsByHour['labels'],
      hoverinfo: 'text',
      marker: {
        color: opsByHour['colors']
      },
      type: 'bar',
      textposition: 'none'
    }];

    this.chart1Layout = {
      font: {
        family: 'Roboto, "Helvetica Neue", sans-serif'
      },
      title: CHART_TEXT.TITLE_OPS_CHART,
      titlefont: {
        size: 15,
        color: '#000000'
      },
      showlegend: false,
      hoverlabel: {
        bgcolor: '#009ed2'
      },
      margin: {
        l: 36,
        r: 24,
        t: 48,
        b: 24
      },
      height: 200,
      width: 320,
      plot_bgcolor: '#f2f2f2',
      xaxis: {
        ticks: '',
        showticklabels: false,
        fixedrange: true,
      },
      yaxis: {
        ticks: '',
        fixedrange: true
      }
    };

    console.log('Data', this.chart1Data);
    console.log('Layout', this.chart1Layout);
  }

  private _buildAircraftChart(aircraftCounts: any): void {
    const aircraft_types = [],
      op_counts = [],
      labels = [];
    let aircraft_label,
      op_count;

    for (const ac_type in aircraftCounts) {
      if (aircraftCounts.hasOwnProperty(ac_type)) {
        aircraft_label = AIRCRAFT_TYPES[ac_type.toUpperCase()];
        op_count = aircraftCounts[ac_type];
        aircraft_types.push(aircraft_label);
        op_counts.push(op_count);
        labels.push(CHART_TEXT.TEXT_TYPE + ': ' + aircraft_label + '<br>' + CHART_TEXT.TEXT_OPERATION + ': ' + op_count);
      }
    }

    this.chart2Data = [{
      x: aircraft_types,
      y: op_counts,
      text: labels,
      hoverinfo: 'text',
      type: 'bar',
      textposition: 'none'
    }];

    this.chart2Layout = {
      font: {
        family: 'Roboto, "Helvetica Neue", sans-serif'
      },
      title: CHART_TEXT.TITLE_AIRCRAFT_CHART,
      titlefont: {
        size: 15,
        color: '#000000'
      },
      showlegend: false,
      hoverlabel: {
        bgcolor: '#009ed2'
      },
      margin: {
        l: 36,
        r: 24,
        t: 24,
        b: 100
      },
      height: 260,
      width: 320,
      plot_bgcolor: '#f2f2f2',
      xaxis: {
        ticks: '',
        showticklabels: true,
        tickangle: -60,
        tickfont: {
          size: 8
        },
        fixedrange: true
      },
      yaxis: {
        ticks: '',
        fixedrange: true
      }
    };
  }

}
