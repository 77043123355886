import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Text from 'ol/style/Text';
import VectorTile from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';

import {BaseLayerService} from './base-layer.service';


export class ContourLayerService extends BaseLayerService {
  private style: any;
  protected layer: any;



  public getLayer() {
    /**
     * We only create one layer for the service, so handle that
     * here.
     */
    if (this.layer === undefined) {
      // Create a new layer.
      this.layer = new VectorTile({
        declutter: true,
        opacity: .7,
        maxResolution: 300,
        source: new VectorTileSource({
          format: new MVT({'layers': ['Noise Contours']}),
          url: this._url
        }),
        updateWhileAnimating: true,
        visible: true,
        zIndex: 10
      });
      this.style = this.getStyle();
      this.layer.setStyle(this.style);
    }
    return this.layer;
  }

  /**
   * Given a layer, return a style for that layer
   */
  public getStyle(): any {
    const stroke = new Stroke({
      color: '#ff6600',
      width: .75
    });
    const lineStyle = new Style({
      stroke: stroke,
      text: new Text({
        font: 'bold 11px "Open Sans", "Arial Unicode MS", "sans-serif"',
        placement: 'line',
        fill: new Fill({
          color: 'white'
        })
      })
    });
    return (feature, resolution) => {
 //     console.log(feature.getProperties());
      lineStyle.getText().setText([feature.get('contour'), ' ', feature.get('metricname')].join(''));
      return lineStyle;
    };
  }

}
