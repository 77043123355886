import {Component, OnInit} from '@angular/core';
import {filter_sidenav as FILTER_TEXT} from '../env-translate';
import {site_defaults as DEFAULTS} from '../env-constants';
import {SharedService} from '../shared.service';
import {ApiService} from '../api.service';
import {MatSelectionListChange, MatListOption, MatSelectionList} from '@angular/material/list';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, first, debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';


class Airline {
  display: boolean;
  iata: string;
  icao: string;
  name: string;
  short_name: string;
}

class Filter {
  label: string;
  value: string | [string, string];
  category: string;
  selected: boolean;
  constructor(l: string, v: string | [string, string], c: string, s?: boolean) {
    this.label = l;
    this.value = v;
    this.category = c;
    this.selected = !!s;
  }
}

class FilterGroup {
  selectAll?: Filter;
  filters: Filter[];
  constructor(f: Filter[], sa?: Filter) {
    this.filters = f;
    if (sa !== undefined) {
      this.selectAll = sa;
    }
  }
}

class Tab {
  label: string;
  filterGroups: FilterGroup[];
  constructor(s: string) {
    this.label = s;
    this.filterGroups = [];
  }
}

@Component({
  selector: 'app-filter-sidenav',
  templateUrl: './filter-sidenav.component.html',
  styleUrls: []
})


export class FilterSidenavComponent implements OnInit {

  public LABEL_HEADER = FILTER_TEXT.LABEL_HEADER;
  public LABEL_TAB_1 = FILTER_TEXT.LABEL_TAB_1;
  public LABEL_TAB_2 = FILTER_TEXT.LABEL_TAB_2;
  public LABEL_TAB_3 = FILTER_TEXT.LABEL_TAB_3;
  public LABEL_TAB_SEARCH = FILTER_TEXT.LABEL_TAB_SEARCH;
  public PLACEHOLDER_SEARCH = FILTER_TEXT.PLACEHOLDER_SEARCH;
  public ARIA_LABEL_SEARCH = FILTER_TEXT.ARIA_LABEL_SEARCH;

  public framework: Tab[];

  private _airports: any[];

  // Search input for airlines
  public searchCtrl: FormControl;
  // Observable that returns airlines for auto complete
  public filteredAirlines: Observable<Airline[]>;
  // Airlines selected from search stored as filter objects
  public selectedSearchFilters: Filter[] = [];

  constructor(private _sharedService: SharedService, private _apiService: ApiService) {
    // When the url has been parsed start creating our filters framework
    this._sharedService.urlParsed$
      .pipe(first(data => data === true))
      .subscribe(
        data => this._initializeFiltersFramework()
      );
  }

  ngOnInit() {
    this.searchCtrl = new FormControl();
    this.filteredAirlines = this._getFilteredAirlines();
  }

  private _initializeFiltersFramework(): void {
    /*
     * Create the framework for each Tab
     */
    const tab1 = new Tab(this.LABEL_TAB_1);
    const tab2 = new Tab(this.LABEL_TAB_2);
    const tab3 = new Tab(this.LABEL_TAB_3);

    this.framework = [tab1, tab2, tab3];
    // Create the flight type filters
    tab1.filterGroups = this._populateTypeFilters();

    // Create the runway filters AFTER we get the airports from the API
    // Subscribe to the service that pulls the list of airport information
    this._sharedService.ftAirports$
    .pipe(first())
    .subscribe(
      airports => {
        this._airports = airports;
        // Now subscribe to the list of runways and start creating the filter ui for the runways tab
        this._sharedService.ftRunways$
        .pipe(first())
        .subscribe(
          runways => tab2.filterGroups = this._populateRunwayFilters(runways)
        );
      }
    );

    // Create the airline filters once we get the airlines from the API
    this._sharedService.ftAirlines$
      .pipe(first())
      .subscribe(
      data => tab3.filterGroups = this._populateAirlineFilters(data)
    );
  }

  private _populateTypeFilters(): FilterGroup[] {
    /*
     * Creates the filters for the Flight Types tab
     */
    const filters = [];
    const selFilters = this._sharedService.pullFilters('type');
    filters.push(new Filter(FILTER_TEXT.LABEL_ARRIVALS, 'A', 'type', selFilters.indexOf('A') > -1));
    filters.push(new Filter(FILTER_TEXT.LABEL_DEPARTURES, 'D', 'type', selFilters.indexOf('D') > -1));
    filters.push(new Filter(FILTER_TEXT.LABEL_UNKNOWN, 'O', 'type', selFilters.indexOf('O') > -1));
    filters.push(new Filter(FILTER_TEXT.LABEL_ARRIVING_AND_DEPARTING, 'D/A', 'type', selFilters.indexOf('D/A') > -1));
    return [new FilterGroup(filters)];
  }

  private _populateAirlineFilters(data: any[]): FilterGroup[] {
    /*
     * Loop through the default airlines and create a filter for each
     */
    const selFilters = this._sharedService.pullFilters('airline');
    const filters = data.map(
      obj => {
        const label = obj['short_name'];
        const val = obj['icao'];
        const selected = selFilters.indexOf(val) > -1;
        return new Filter(label, val, 'airline', selected);
      }
    );
    return [new FilterGroup(filters)];
  }

  private _populateRunwayFilters(data: any[]): FilterGroup[] {
    /*
     * Loop through the airports and create a filter group for each
     * That filter group contains a filter for the airport and an array of filters for each runway at the airport
     */
    const filterGroups: FilterGroup[] = [];
    const selFilters = this._sharedService.pullFilters('runway').map(vals => vals.split('-'));
    this._airports.sort(function(a, b): number {
        // Sort the airports alphabetically by display_name. Put the default airport at the top of the list
        return b['local_code'] === DEFAULTS.MAIN_AIRPORT_LOCAL_CODE ? 1 : (a['display_name'] < b['display_name'] ? -1 : 1);
      });
    // Now sort the runways and create the filters
    this._airports.map(
      airport => {
        const airport_filter = new Filter(airport['display_name'], '', 'selectall');
        const valid_runways = data.filter(
          // Filter only the runways for this airport code
          runway => runway['icao_code'] === airport['icao_code']
        )
        .sort(
          // Sort the runways by runway_code, comparing them as numbers first
          // If it's the same number then sort it by string (so L comes before R)
          function(a, b): number {
            const val_a = parseInt(a['runway_code'], 10);
            const val_b = parseInt(b['runway_code'], 10);
            return val_a === val_b ? (a['runway_code'] < b['runway_code'] ? -1 : 1) : (val_a < val_b ? -1 : 1);
          }
        );
        // Each airport needs a filter with a blank run-way value to help filter real-time data (which generally has an airport but no run-way)
        valid_runways.push({runway_code: '', local_code: airport['local_code']});
        const filters = valid_runways.map(
          // Create the filters for each runway
          runway => {
            const label = runway['runway_code'] || 'Unknown';
            const val = [runway['local_code'], runway['runway_code']].join('-');
//            const selected = !!find(
//              selFilters,
//              selFilter => selFilter[0] === runway['local_code'] && selFilter[1] === runway['runway_code']
//            );
            const selected = !!selFilters.find(selFilter => selFilter[0] === runway['local_code'] && selFilter[1] === runway['runway_code']);
            return new Filter(label, val, 'runway', selected);
          }
          );

        filterGroups.push(new FilterGroup(filters, airport_filter));
      }
      );

    return filterGroups;
  }

  private _getFilteredAirlines() {
    /* Calls the _apiService to get airlines for the search
     * debounceTime - Number of milliseconds wait until the service is called
     * distinctUntilChanged - Only call the service if the value actually changes (prevents add/delete letter scenario)
     * switchMap - If there are multiple calls to the service, pull the values from each stream and create a single stream
     */
    return this.searchCtrl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => this._apiService.callAirlinesSearch(val)),
        map(results => results['objects'])
      );
  }

  public searchDisplayFn(airline?: Airline): string | undefined {
    /*
     * Applied to the auto complete on the search field
     * Allows us to save the selected option as an object but display the place name as the selected value
     */
    return airline ? airline.short_name : undefined;
  }

  public selectAirline(evt): void {
    /*
     * Called when the user clicks on an airline in the airline auto complete
     * Stores the airline object selected by the user from the auto complete
     * Applies the airline to the filter interface
     */
    const airline_obj = evt['option']['value'];
    const label = airline_obj.short_name;
    const val = airline_obj.icao;
    const filter = new Filter(label, val, 'airline', true);
    this.selectedSearchFilters.push(filter);
  }

  public onSelectionChange(e: MatSelectionListChange) {
    /*
     * Event emitted by the MatSelectionList when a ListOption is clicked
     * If the list option has a blank value then it is a selectAll option
     * Clicking it should select or deselect all values under it in the selection list
     * If the value is not blank then it's a standard filter, and we should add or remove it from the list of selected filters
     */
    const option: MatListOption = e.options[0];
    const list: MatSelectionList = e.source;
    const selFilter: Filter = option.value;
    const vals: any[] = [];
    let filterCat: string;
    if (selFilter.category === 'selectall') {
      // User clicked a selectAll
      if (option.selected) {
        list.selectAll();
        list.selectedOptions.selected.map(
          opt => {
            const f = opt.value;
            const f_value = f.value;
            if (f.category !== 'selectall') {
              vals.push(f_value);
              filterCat = f.category;
            }
            f.selected = option.selected;
          }
        );
      } else {
        list.deselectAll();
        list.options.map(
          opt => {
            const f = opt.value;
            const f_value = f.value;
            if (f.category !== 'selectall') {
              vals.push(f_value);
              filterCat = f.category;
            }
            f.selected = option.selected;
          }
        );
      }
      // Remove or add all options in the list to the selected filters except the airport (category = 'selectall')
      this._updateSelFilterLists(filterCat, vals, option.selected);
    } else {
      // User clicked a standard filter
      selFilter.selected = option.selected;
      this._updateSelFilterLists(selFilter.category, [selFilter.value], option.selected);
    }
  }

  private _updateSelFilterLists(filterCategory: string, targetVals: any[], addToList: boolean): void {
    const tgtList = this._sharedService.pullFilters(filterCategory);
    let newList;

    if (addToList) {
      newList = [...new Set(tgtList.concat(targetVals))];
    } else {
      newList = tgtList.filter((val: string) => targetVals.indexOf(val) === -1);
    }

    if (filterCategory === 'airline') {
      this._sharedService.publishFiltersAirline(newList);
    } else if (filterCategory === 'runway') {
      this._sharedService.publishFiltersRunway(newList);
    } else {
      this._sharedService.publishFiltersType(newList);
    }
  }

  public clearAllFilters(): void {
    this.framework.map(
      tab => {
        tab.filterGroups.map(
          fg => {
            fg.filters.map(
              filter => {
                if (filter.selected) {
                  filter.selected = false;
                  this._updateSelFilterLists(filter.category, [filter.value], false);
                }
              }
            );
            if (!!fg.selectAll) {
              fg.selectAll.selected = false;
            }
          }
        );
      }
    );
  }

}
