import {BaseDrawingLayerService} from './base-drawing-layer.service';
import Fill from 'ol/style/Fill';
import Overlay from 'ol/Overlay';
import Text from 'ol/style/Text';
import {fromEvent} from 'rxjs';
import {RouteUtilsService} from '../route-utils.service';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import { Type } from 'ol/geom/Geometry';

export class MeasureDrawingService extends BaseDrawingLayerService {
  public nomsLayerLabel = 'measure';
  protected drawing_type: Type = 'LineString';
  protected _maxPoints = 25;
  protected observable_name = 'publishMapMeasureGeom';

  private helpTooltipElement;
  private helpTooltip;
  private measureTooltipElement;
  private measureTooltip;
  private pointerMoveEvent;
  private mouseOutEvent;
  private drawStartEvent;
  private drawEndEvent;
  private sketch;
  private subscribers: any[] = [];
  private routeUtilInstance;
  private double_click_zoom_interaction;

  private _createHelpTooltip() {
    if (this.helpTooltip) {
      this._map.removeOverlay(this.helpTooltip);
    }
    this.helpTooltipElement = document.createElement('div');
    this.helpTooltipElement.className = 'tooltip hidden';
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: 'center-left',
    });
    this._map.addOverlay(this.helpTooltip);
  }

  private _createMeasureTooltip() {
    if (this.measureTooltip) {
      this._map.removeOverlay(this.measureTooltip);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'tooltip tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
    });
    this._map.addOverlay(this.measureTooltip);
  }

  private _removeOverlays(): void {
    if (this.measureTooltip) {
      this._map.removeOverlay(this.measureTooltip);
    }
    if (this.helpTooltip) {
      this._map.removeOverlay(this.helpTooltip);
    }
  }

  protected getLayerStyle(): any {
    const style = this.getStyle();

    const textStyle = new Text({
      textAlign: 'left',
      textBaseline: 'ideographic',
      font: 'normal 12px Arial',
      fill: new Fill({color: '#ffcc33'}),
      //            stroke: new Stroke({ color: '#ffcc33', width: 3 }),
      offsetX: 15,
      offsetY: -15,
      overflow: true,
      rotation: 0
    });
    style.setText(textStyle);
    return (feature, resolution) => {
      const length = this.routeUtilInstance.computeGeomLength(
        feature.getGeometry(),
        this._sharedService.getUnits(),
        this._map.getView().getProjection().getCode());
      textStyle.setText(length);
      return [style];
    };
  }




  protected postAddInteraction(): void {
    this.routeUtilInstance = new RouteUtilsService();
    this.pointerMoveEvent = fromEvent(this._map, 'pointermove');
    this.mouseOutEvent = fromEvent(this._map, 'mouseout');
    this.drawStartEvent = fromEvent(this.interaction, 'drawstart');
    this.drawEndEvent = fromEvent(this.interaction, 'drawend');



    // Handle the adding/setup for help tooltips, so the user knows how to
    // draw stuff.

    this._createHelpTooltip();
    this.subscribers.push(
      this.pointerMoveEvent.subscribe((evt: any) => {
        if (evt.dragging) { return; }
        let help_message;
        if (this.sketch) {
          help_message = 'Click to continue, double-click to end';
        } else {
          help_message = 'Click to start drawing';
        }
        this.helpTooltipElement.innerHTML = ['<font class="measure_text">', help_message, '</font>'].join('');
        this.helpTooltip.setPosition(evt.coordinate);
        this.helpTooltipElement.classList.remove('hidden');
      })
    );

    // When the mouse moves out of the window, hide it.  Note that pointermove
    // will fire when the mouse is back in, and will remove hidden, so no need to look for
    // mouse in type events.
    this.subscribers.push(
      this.mouseOutEvent.subscribe((evt: any) => this.helpTooltipElement.classList.add('hidden'))
    );


    /**
     * Add in the measure tooltips, which show the current measurement data, and lock in the
     * measure line when it is done.
     */

    this._createMeasureTooltip();
    this.subscribers.push(
      this.drawStartEvent.subscribe((evt: any) => {
        //                this.double_click_zoom_interaction=this._map.removeInteraction(DoubleClickZoom);
        this.clearFeatures(true);
        this._map.getInteractions().forEach((interxn: any) => {
          if (interxn instanceof DoubleClickZoom) {
            this.double_click_zoom_interaction = interxn;
          }
        });
        this._map.removeInteraction(this.double_click_zoom_interaction);
        this.sketch = evt.feature;
        const listener = fromEvent(this.sketch.getGeometry(), 'change');
        this.subscribers.push(
          listener.subscribe((evt2: any) => {
            const dist = this.routeUtilInstance.computeGeomLength(
              evt2.target,
              this._sharedService.getUnits(),
              this._map.getView().getProjection().getCode());

            this.measureTooltipElement.innerHTML = ['<font class="measure_text">', dist, '</font>'].join('');
            this.measureTooltip.setPosition(evt2.target.getLastCoordinate());
          })
        );
      })
    );
    this.subscribers.push(
      this.drawEndEvent.subscribe((evt: any) => {
        setTimeout(() => { this._map.addInteraction(this.double_click_zoom_interaction); }, 500);
        this.subscribers.forEach(sub => {
          sub.unsubscribe();
        });
        this.layer.getSource();
      })
    );
  }

  protected postRemoveInteraction(): void {
    // Remove all subscribers.
    this.subscribers.forEach(sub => {
      sub.unsubscribe();
    });
    this.sketch = null;
    this._removeOverlays();
  }


}
