import {Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {SharedService} from '../shared.service';
import {ApiService} from '../api.service';
import {create_account as ACCOUNT_TEXT, authentication as AUTH_TEXT} from '../env-translate';
import {FormBuilder, FormGroup, Validators, FormControl, AbstractControl} from '@angular/forms';
import {Observable} from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map, retry, catchError } from 'rxjs/operators';
import { ft_api_urls as FT_URLS, map_component as MAP_CONST } from '../env-constants';
import {HttpClient} from '@angular/common/http';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import XYZSource from 'ol/source/XYZ';
import { defaults as defaultInteractions} from 'ol/interaction';
import { defaults as defaultControls} from 'ol/control';
import WKT from 'ol/format/WKT';
import GeoJSON from 'ol/format/GeoJSON';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { MatStepper } from '@angular/material/stepper';
import { AddressHelpDialogComponent } from '../address-help-dialog/address-help-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Geometry from 'ol/geom/Geometry';
import SimpleGeometry from 'ol/geom/SimpleGeometry';

@Component({
  selector: 'app-create-account-dialog',
  templateUrl: './create-account-dialog.component.html',
  styleUrls: ['./create-account-dialog.component.css']
})


export class CreateAccountDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper', {static: true}) stepper: MatStepper;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public ACCOUNT_TEXT = ACCOUNT_TEXT;
  public API_ERROR: string;
  public sending = false;
  // Observable that returns addresses for auto complete
  public filteredLocs: Observable<Location[]>;
  private _vectorLayer: VectorLayer<any>;
  private _view: View;
  private _defaultGeom: Geometry;
  public map: Map;

  constructor(
    private _dialogRef: MatDialogRef<CreateAccountDialogComponent>,
    private _sharedService: SharedService,
    private _fb: FormBuilder,
    private _apiService: ApiService,
    private _http: HttpClient,
    private _dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.firstFormGroup = this._fb.group({
      'agree': [false],
      'agree_tracking': ['', Validators.required]
    });

    this.agree.valueChanges.subscribe(
      val => this.firstFormGroup.get('agree_tracking').setValue(val ? 'selected' : '')
    );

    this.secondFormGroup = this._fb.group({
      'find_address': ['', Validators.required],
      'address1': [{value: '', disabled: true}, Validators.required],
      'address2': [''],
      'phone': ['']
    });

    this.thirdFormGroup = this._fb.group({
      'email': ['', [Validators.required, Validators.email]],
      'confirm_email': ['', [Validators.required, Validators.email, this._matchFieldVal.bind(this)]],
      'first_name': [''],
      'last_name': [''],
    });

    this.filteredLocs = this._getFilteredLocations();

  }

  ngAfterViewInit() {
    this._initMap();
  }

  private _matchFieldVal(ctrl: AbstractControl): { [key: string]: boolean } | null {
      if (this.thirdFormGroup && ctrl.value !== this.email.value) {
        return { 'match': true };
      }
      return null;
  }


  private _initMap(): void {
    this._view = new View({
      projection: MAP_CONST.MAP_PROJECTION,
      minZoom: MAP_CONST.VIEW_MIN_ZOOM,
      maxZoom: MAP_CONST.VIEW_MAX_ZOOM
    });

    this._defaultGeom = new WKT().readGeometry(this._sharedService.getMapDefaultExtent(), {dataProjection: MAP_CONST.PROJ_WGS84});

    this._defaultGeom.transform(MAP_CONST.PROJ_WGS84, MAP_CONST.MAP_PROJECTION);
    this._view.fit(this._defaultGeom as SimpleGeometry);

    this._vectorLayer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
            image: new Icon({
                anchor: [.5, 1],
                size: [24, 24],
                scale: 1,
                opacity: 1,
                color: '#FF0000',
                src: MAP_CONST['MARKER_ICON']
            })
        })
    });

    this.map = new Map({
        layers: [
            new TileLayer({
                source: this._createSource(MAP_CONST.BASE_LAYERS[1].url)
            }),
            this._vectorLayer
        ],
        target: 'new-account-map',
        view: this._view,
        controls: defaultControls({
            attribution: false,
            rotate: false,
            zoom: false
        }),
        interactions: defaultInteractions({altShiftDragRotate: false, pinchRotate: false})
    });
  }

  private _createSource(src_url: string): XYZSource {
    const mapboxAPIKey = this._sharedService.getMapAPIKey();
    return new XYZSource({
      url: ['https://api.mapbox.com/styles/v1/'
        , src_url
        , '/tiles/256/{z}/{x}/{y}?access_token=',
        , mapboxAPIKey].join(''),
      projection: MAP_CONST.PROJ_MERCATOR
    });
  }

  public clearSearch(): void {
    this.find_address.setValue('');
    this.address1.setValue('');
    const src = this._vectorLayer.getSource();
    src.clear();
    this._view.fit(this._defaultGeom as SimpleGeometry);
  }

  public searchDisplayFn(loc?: any): string | undefined {
    /*
     * Applied to the auto complete on the search field
     * Allows us to save the selected option as an object but display the place name as the selected value
     */
    return loc ? loc.address : undefined;
  }

  public selectAddress(evt: any): void {
    const val = evt['option']['value'];
    // Get the geoJSON for the point
    const geom = val.location;
    // Grab the address and set the field
    this.address1.setValue(val.address);
    // Now create a feature from the geoJSON and place it on the map
    const ftr = new GeoJSON().readFeature(geom, {dataProjection: MAP_CONST.PROJ_WGS84, featureProjection: MAP_CONST.MAP_PROJECTION});
    const src = this._vectorLayer.getSource();
    src.clear();
    src.addFeature(ftr);
    // Zoom to the point
    this._view.fit(ftr.getGeometry() as SimpleGeometry);
  }

  private _getFilteredLocations() {
    /* Calls the _callGeoLocation to get addresses for the type ahead
     * debounceTime - Number of milliseconds wait until the service is called
     * distinctUntilChanged - Only call the service if the value actually changes (prevents add/delete letter scenario)
     * switchMap - If there are multiple calls to the service, pull the values from each stream and create a single stream
     */
    return this.find_address.valueChanges
    .pipe(
      debounceTime(400),
      distinctUntilChanged(),
      // Prevent blanks and undefined from being sent
      filter( val => !!val),
      switchMap(val => this._callGeoLocation(val)),
      map(results => results['objects'])
    );
  }

 private _callGeoLocation(val: string): Observable<any> {
    const baseUrl = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_ADDRESS_LOOKUP;
    const url = baseUrl + val;
    return this._http
      .get(url)
      .pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this._apiService.handleError) // then handle the error
      );
  }

  get agree() {
      return this.firstFormGroup.get('agree');
  }

  get email() {
      return this.thirdFormGroup.get('email');
  }

  get confirm_email() {
      return this.thirdFormGroup.get('confirm_email');
  }

  get find_address() {
      return this.secondFormGroup.get('find_address');
  }

  get address1() {
      return this.secondFormGroup.get('address1');
  }

  get address2() {
      return this.secondFormGroup.get('address2');
  }

  get first_name() {
      return this.thirdFormGroup.get('first_name');
  }

  get last_name() {
      return this.thirdFormGroup.get('last_name');
  }

  get phone() {
      return this.secondFormGroup.get('phone');
  }

  public isStepComplete(stepIndex?: number): boolean {
    const index = stepIndex || this.stepper.selectedIndex;
    const step = !this.stepper.steps ? null : this.stepper.steps.toArray()[index];
    let status = false;
    if (!step) {
        status = false;
    } else if (index === 0) {
        // They have to check the agree checkbox
        status = this.agree.value;
    } else if (index === 1) {
        // They must have selected a valid address (which then populates the address1 field)
        status = !!this.address1.value;
    } else if (index === 2) {
        // Nothing is required on this tab, they just need to view it at least once
        status = step.interacted;
    } else {
        // Both email fields must pass validation and they must match
        status = this.email.valid && this.confirm_email.valid;
    }
    return status;
  }

  public nextStep(): void {
      this.stepper.next();
      // If we move to step 3 (index 2) then we need to set it's interacted flag to true
      if (this.stepper.selectedIndex === 2) {
          this.stepper.selected.interacted = true;
      }
  }

  public getErrorMsg(ctrl: FormControl): string {
    const errMap = [
      {error: 'email', msg: AUTH_TEXT.ERROR_MSGS.VALID_EMAIL},
      {error: 'required', msg: AUTH_TEXT.ERROR_MSGS.REQUIRED},
      {error: 'match', msg: AUTH_TEXT.ERROR_MSGS.EMAIL_MATCH},
    ];
    let msg: string;

    errMap.forEach(
      err => {
        if (ctrl.errors[err.error]) {
          msg = err.msg;
        }
      }
    );

    return msg;
  }

  public openHelpDialog(): void {
    this._dialog.open(AddressHelpDialogComponent, {
      width: '400px'
    });
  }

    /**
   * Submits the form values to the API and processes any issues that are returned
   * If no issues then close the dialog
   */
  public submitForm(): any {
    // Wipe any API Errors
    this.API_ERROR = '';
    // Set the progress bar to display
    this.sending = true;
    // Google Analytics
    window['gtag']('event', 'user_account', { 'event_category': 'Submit' });
    this._apiService.addUser({
        address: this.address1.value,
        address2: this.address2.value,
        email: this.email.value,
        first_name: this.first_name.value,
        last_name: this.last_name.value,
        phone: this.phone.value,
        // application:
    }).subscribe(data => this._processApiResults(data));
  }

  private _processApiResults(data: any) {
    // Login/ Logout api returns {'success': false, 'error':<string>}
    // Change Password api returns {'success': false, 'reason': <string>[]}
    if (!!data.success) {
      const successMsg = data.message;
      if (successMsg) {
        this._sharedService.publishFTMessage(successMsg, null);
      }
      this._dialogRef.close(true);
    } else {
      // Track any errors returned by the API
      this.API_ERROR = ACCOUNT_TEXT.API_ERROR_MSG;
      // Turn off the progress bar
      this.sending = false;
    }
  }

}
