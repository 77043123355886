import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import {GateInfoDialogComponent} from '../gate-info-dialog/gate-info-dialog.component';
import {FeatureBottomsheetComponent} from '../feature-bottomsheet/feature-bottomsheet.component';
import {SharedService} from '../shared.service';
import {ApiService} from '../api.service';
import {debounceTime, first} from 'rxjs/operators';
import {fromEvent} from 'rxjs';
import {map_component as MAP_CONST, ft_api_urls as API_CONST} from '../env-constants';
import {flight_toolbar as FT_TEXT} from '../env-translate';
import {map_component as MAP_TEXT, mapbox_attrib} from '../env-translate';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZSource from 'ol/source/XYZ';
import { defaults as defaultInteractions } from 'ol/interaction';

import Style from 'ol/style/Style';
import WKT from 'ol/format/WKT';
import Point from 'ol/geom/Point';
import Geometry from 'ol/geom/Geometry';
import Select from 'ol/interaction/Select';
import { defaults as defaultControls } from 'ol/control';
import Control from 'ol/control/Control';
import ScaleLine from 'ol/control/ScaleLine';


// Style components
import {CorridorLayerService} from './layers/corridor-layer.service';
import {RunwayLayerService} from './layers/runway-layer.service';
import {OtherRunwayLayerService} from './layers/other-runway-layer.service';
import {ContourLayerService} from './layers/contour-layer.service';
import {AirportLayerService} from './layers/airport-layer.service';
import {NoiseMonitorLayerService} from './layers/noise-monitor-layer.service';
import {AnimatedLayerService} from './layers/animated-layer.service';
import {StaticTrackLayerService} from './layers/static-track-layer.service';
import {NexradLayerService} from './layers/nexrad-layer.service';
import {RealtimeLayerService} from './layers/realtime-layer.service';

import {PointDistanceDrawingService} from './layers/point-distance-drawing.service';
import {GateDrawingService} from './layers/gate-drawing.service';
import {MeasureDrawingService} from './layers/measure-drawing.service';


@Component({
  selector: 'app-map',
  template: `<DIV id="ol-map"><a href="http://mapbox.com/about/maps" class='mapbox-wordmark' target="_blank">Mapbox</a></DIV>`,
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit {
  public map: Map;

  private _baseLayer: TileLayer<any>;
  private _view: View;
  private _wsIntervalSeconds: number;
  private _minDelay: number;
  private _mapboxAPIKey: string;
  private _selectInteraction: Select;
  private _wsExtentGeom: Geometry;

  private _cachedFilters: any = {};
  private _heldDataBucketRanges: any[] = [];

  private _bucketHoldCount: number;

  private _dataPullInProgress: boolean;
  private _cacheBufferSeconds: number;
  private _countDisplayOverlay;
  private _countDisplayOverlayElement;

  private _featureInformationEnabled = false;
  private _createdLayerServices: any;



  private _serviceURLs: any = {
    'corridors': [API_CONST.PROTOCOL, API_CONST.HOSTNAME, API_CONST.PATH_STATIC_TILES].join(''),
    'runways': [API_CONST.PROTOCOL, API_CONST.HOSTNAME, API_CONST.PATH_STATIC_TILES].join(''),
    'other_runways': [API_CONST.PROTOCOL, API_CONST.HOSTNAME, API_CONST.PATH_STATIC_TILES].join(''),
    'contours': [API_CONST.PROTOCOL, API_CONST.HOSTNAME, API_CONST.PATH_STATIC_TILES].join(''),
    'airports': [API_CONST.PROTOCOL, API_CONST.HOSTNAME, API_CONST.PATH_STATIC_TILES].join(''),
    'rmts': [API_CONST.PROTOCOL, API_CONST.HOSTNAME, API_CONST.PATH_STATIC_TILES].join(''),
    'nexrad': API_CONST.NEXRAD
  };

  private _layerDefinitions: any = {
    'corridors': CorridorLayerService,
    'runways': RunwayLayerService,
    'other_runways': OtherRunwayLayerService,
    'contours': ContourLayerService,
    'airports': AirportLayerService,
    'rmts': NoiseMonitorLayerService,
    'animated': AnimatedLayerService,
    'static_tracks': StaticTrackLayerService,
    'nexrad': NexradLayerService,
    'realtime': RealtimeLayerService,
    'point_distance': PointDistanceDrawingService,
    'gate': GateDrawingService,
    'measure': MeasureDrawingService,
  };

  private _subscribedServices: any = {
    'radarActive$': 'nexrad',
    'rmtActive$': 'rmts',
    'contoursActive$': 'contours',
    'runwaysActive$': 'runways',
    'otherRunwaysActive$': 'other_runways',
    //        'weatherActive$': 'weather', // we don't have a weather layer yet.
    'corridorsActive$': 'corridors',
    'airportsActive$': 'airports',
    'operationsActive$': 'animated',
    'staticTracksActive$': 'static_tracks',
    'realtimeTracksActive$': 'realtime'
  };

  private _replayServices: any = {};


  constructor(private _sharedService: SharedService,
    private _apiService: ApiService,
    private _dialog: MatDialog,
    private _bottomSheet: MatBottomSheet) {
    this._createdLayerServices = {};

    this._replayServices[FT_TEXT.REPLAY_OPTIONS_REALTIME] = 'realtime';
    this._replayServices[FT_TEXT.REPLAY_OPTIONS_ANIMATED] = 'animated';
    this._replayServices[FT_TEXT.REPLAY_OPTIONS_STATIC] = 'static_tracks';

    this._countDisplayOverlay = this._createCountOverlay();
  }

  private _createCountOverlay() {
    this._countDisplayOverlayElement = document.createElement('div');
    this._countDisplayOverlayElement.className = 'noms-opsinfo ol-unselectable ol-control';
    return (new Control({element: this._countDisplayOverlayElement}));
  }

  ngOnInit() {
    // Make the OL proj object aware of proj4 so it can do more
    // reprojection stuff.  This happens once after we load both
    // OL and the projection code (so here..)
//    proj.setProj4(proj4);
    this._initMap(null);
    this._subscribeToServices();
  }


  private _findLayerByName(layer_name: string): any {
    /**
     * Given a layer name, locate the layer on the map and return a reference
     * to the layer object.
     */
    const foundLayer = this.map.getLayers().getArray().find(layer => {
      return (layer.get('overlayId') === layer_name);
    });
    return foundLayer;
  }

  /**
   * Given a layer name get the related layer service if it exists.  If it does not, then
   * initialize it with the appropriate bucket sizes and urls, etc.
   */
  private _getLayerService(layer_name: string): any {
    if (!(layer_name in this._createdLayerServices)) {
      if (layer_name in this._layerDefinitions) {
        // yay! It's a valid layer probably.
        this._createdLayerServices[layer_name] = new this._layerDefinitions[layer_name](
          this.map,
          this._serviceURLs[layer_name],
          this._bucketHoldCount,
          this._sharedService,
          this._apiService);
        this._createdLayerServices[layer_name].getLayer().set('overlayId', layer_name);
        // re-apply filters to all created layers.
        this._updateFilters();
        // Re enable select interactions if needed
        this._enableFeatureInformation(this._featureInformationEnabled);
      } else {
        console.log(['Unknown layer name specified: ', layer_name].join(''));
        return null;
      }
    }
    return this._createdLayerServices[layer_name];
  }

  private _enableLayer(layer_name: string): any {
    let layer = this._findLayerByName(layer_name);
    if (!layer) {
      layer = this._getLayerService(layer_name).getLayer();
      if (layer) {
        this.map.addLayer(layer);
        this._getLayerService(layer_name).layerAdded();
      }
    }
    return layer;
  }

  private _disableLayer(layer_name: string): boolean {
    if (layer_name) {
      const layer = this._findLayerByName(layer_name);
      if (layer) {
        const svc = this._getLayerService(layer_name);
        this.map.removeLayer(layer);
        svc.layerRemoved();
      }
      return layer;
    }
  }

  /**
   * Go through all the created layers and apply any filters to them.
   */
  private _updateFilters(): void {
    const filters = {
      'airline': 'setAirlineFilter',
      'runway': 'setRunwayFilter',
      'adflag': 'setADFlagFilter'
    };
    for (const layer_ident in this._createdLayerServices) {
      if (this._createdLayerServices.hasOwnProperty(layer_ident)) {
        const layerService = this._createdLayerServices[layer_ident];
        for (const filter_name in this._cachedFilters) {
          if (layerService[filters[filter_name]]) {
            layerService[filters[filter_name]](this._cachedFilters[filter_name]);
          }
        }
      }
    }
  }

  private _subscribeToServices(): void {
    /*
     * Subscribe to sharedservices
     * Allows us to pull default values from the url
     * Also responds to any changes from the left navigation or base layer menu
     */


    // Subscribe to the web socket extent service and create the map once a new extent is delivered from the web socket
    this._sharedService.wsExtent$.pipe(first()).subscribe(
      ext => {
        const format = new WKT();
        const center = this._sharedService.pullUrlParams()['center'];
        // Set the extent geometry so we can reuse it later if the user re-centers the map
        this._wsExtentGeom = format.readGeometry(ext, {dataProjection: MAP_CONST.PROJ_WGS84});
        this._wsExtentGeom.transform(MAP_CONST.PROJ_WGS84, MAP_CONST.MAP_PROJECTION);
        if (center === undefined) {
          // No center was defined in the url so center on the extent
          this._centerMapOnExtent();
        }
      }
    );

    // Update the map when a new systemCurrentDateTime is sent
    this._sharedService.systemCurrentDateTime$.subscribe(
      data => this.updateMap(data)
    );


    // Get the bucketHoldCount from the animated call
    this._sharedService.wsBucketHoldCount$.pipe(first()).subscribe(
      data => {
        this._bucketHoldCount = data;
        for (const key in this._createdLayerServices) {
          if (this._createdLayerServices[key].time_enabled) {
            this._createdLayerServices[key].updateBucketHoldCount(this._bucketHoldCount);
          }
        }
      }
    );
    // Tracks start dates for all cached data
    this._sharedService.wsCacheDates$.subscribe(
      data => this._heldDataBucketRanges = data
    );
    // Pull wsInterval from animated also
    this._sharedService.wsInterval$.pipe(first()).subscribe(
      data => {
        const sec = data * 60;
        this._wsIntervalSeconds = sec;
        this._cacheBufferSeconds = sec * .4;
      }
    );
    // Pull the minDelay for animated
    this._sharedService.wsMinDelay$.pipe(first()).subscribe(
      data => {
        this._minDelay = data;
      }
    );
    // Subscribe to wsDataPullInProgress so we know when websocket calls are in progress
    this._sharedService.wsDataPullInProgress$.subscribe(
      data => this._dataPullInProgress = data
    );

    // Subscribe to the mapCenter Observable so the map can respond to any requests to change the center and zoom
    this._sharedService.mapCenter$.subscribe(
      data => {
        const point = this._applyNewCenter(data);
        // Now we want to put a marker on the map for this
        // so we enable the point distance tool and then
        // add a feature using this new geometry for the centerpoint
        // so that it shows on the map.
        if (data.displayPin) {
          const svc = this._getLayerService('point_distance');
          // Now turn on the Draw a Point button on the sidebar
          // This will trigger the mapTogglePointDistance shared service which will enable the point tool for us
          // so no need for a separate command
          this._sharedService.publishMapPointDistance(true);
          svc.addFeature(point);
        }
      }
    );
    // Subscribe to the mapBaseLayer Observable so we can respond to any changes from the layer menu
    this._sharedService.mapBaseLayer$.subscribe(
      data => this._changeBaseLayer(data)
    );



    this._sharedService.filtersAirline$.subscribe(data => {
      //            console.log('Got airline filter', data);
      this._cachedFilters.airline = data;
      this._updateFilters();

    });
    this._sharedService.filtersRunway$.subscribe(data => {
      //            console.log('Got runway filter', data);
      this._cachedFilters.runway = data.map(filter => filter.split('-'));
      this._updateFilters();
    });

    this._sharedService.filtersType$.subscribe(data => {
      //            console.log('Got type filter', data);
      this._cachedFilters.adflag = data;
      this._updateFilters();
    });

    this._sharedService.wsStaticEvent$.subscribe(
      data => this._processStaticTrackData(data)
    );


    this._sharedService.infoActive$.subscribe(
      data => this._enableFeatureInformation(data)
    );

    this._sharedService.infoChoices$.subscribe(
      data => this._updateDisplayFields(data)
    );

    this._sharedService.centerMapClicked$.subscribe(
      data => this._centerMapOnExtent()
    );

    this._sharedService.mapTogglePointDistance$.subscribe(
      data => this._enableTool('point_distance', data)
    );

    this._sharedService.mapToggleGate$.subscribe(
      data => this._enableTool('gate', data)
    );

    this._sharedService.mapGateGeom$.subscribe(
      data => this.pullGateData(data)
    );

    this._sharedService.mapToggleMeasure$.subscribe(
      data => this._enableTool('measure', data)
    );

    this._sharedService.wsMapAPIKey$.pipe(first()).subscribe(
      data => {
        this._mapboxAPIKey = data;
        const layer = this._sharedService.pullBaseLayer() || MAP_CONST.BASE_LAYERS[0].url;
        this._changeBaseLayer(layer);
      }
    );


    /**
     * Whenever the replay state changes we need to properly enable or disable the
     * related layer.
     */
    this._sharedService.replay$.subscribe(
      data => {
        for (const key in this._replayServices) {
          if (this._replayServices.hasOwnProperty(key)) {
            this._disableLayer(this._replayServices[key]);
          }
        }
        this._enableLayer(this._replayServices[data]);
      }
    );


    // Loop through the subscribed services and set the actions
    for (const key in this._subscribedServices) {
      if (key in this._sharedService) {
        this._sharedService[key].subscribe(result => {
          // console.log('got result for service enabler for', key, result);
          this._toggleOverlay(this._subscribedServices[key], result);
        });
      }
    }

    this._sharedService.wsRMTsOnly$.subscribe(
      data => this._processNewDataSample(data, 'rmts')
    );

    this._sharedService.wsFlightsAndRmts$.subscribe(
      data => this._processNewDataSample(data, 'animated')
    );

    this._sharedService.wsRealtimeEvent$.subscribe(
      data => this._processNewDataSample(data, 'realtime')
    );

    this._sharedService.mapPublishActiveData$.subscribe(
      data => this._updateCounts(data)
    );

    this._sharedService.tailsActive$.subscribe(
        data => this._toggleTails(data)
    );
  }



  /**
   * Handle enabling select interactions for any/all applicable layers.  Call the toggleInteractions
   * method on the layers so that we can properly enable the interaction if it exists.
   */
  private _enableFeatureInformation(enable_interactions: boolean): void {
    this._featureInformationEnabled = enable_interactions || this._featureInformationEnabled;
    for (const key in this._createdLayerServices) {
      if (this._createdLayerServices.hasOwnProperty(key)) {
        const service = this._createdLayerServices[key];
        service.toggleInformationDisplay(enable_interactions);
      }
    }
  }

  private _updateDisplayFields(displayFields: string[]): void {
    for (const key in this._createdLayerServices) {
      if (this._createdLayerServices.hasOwnProperty(key)) {
        const service = this._createdLayerServices[key];
        service.setDisplayFields(displayFields);
      }
    }
  }

  private _enableTool(layer_name: string, enable: boolean): void {
    if (this._createdLayerServices[layer_name] && !enable) {
      this._createdLayerServices[layer_name].disableDrawing();
    }
    this._toggleOverlay(layer_name, enable);
    // Enables/disables the bottom menu
    this._manageBottomsheet(enable, this._createdLayerServices[layer_name]);
  }

  private _toggleTails(tailsEnabled: boolean) {
      Object.keys(this._createdLayerServices).forEach(
          svcName => this._createdLayerServices[svcName].toggleAircraftTails(tailsEnabled)
      );
  }

  private _initMap(ext: any): void {
    /*
     * Build the map
     * Triggered when the websocket connects and returns it's default information
     * Set the views center, use url value and if none then fit to extent
     */
    this._view = new View({
      projection: MAP_CONST.MAP_PROJECTION,
      minZoom: MAP_CONST.VIEW_MIN_ZOOM,
      maxZoom: MAP_CONST.VIEW_MAX_ZOOM
    });

    this._baseLayer = new TileLayer({});


    // Set the extent of the view based on what we got from the websocket connection
    //        this._view.setProperties({ 'extent': ext });
    this.map = new Map({
      target: 'ol-map',
      layers: [
        this._baseLayer
      ],
      view: this._view,
      controls: defaultControls({
        attributionOptions: {
          collapsible: false
        },
        rotate: false,
      }),
      interactions: defaultInteractions({altShiftDragRotate: false, pinchRotate: false})
    });
    this.map.addControl(new ScaleLine({'units': 'imperial'}));

    const viewChange = fromEvent(this._view, 'change');
    const view_result = viewChange.pipe(debounceTime(500));
    view_result.subscribe((evt) => this._updateUrl(evt));

    // Enable some default layers.
    ['realtime'].forEach(layer_name => {
      const layer = this._enableLayer(layer_name);
    });

    // Configure a select interaction on any layers that are setup as "selectable".
    this._selectInteraction = new Select(
      {
        layers: (layer) => layer.get('selectable') || false,
        // Comment out the line below to allow shift-selection of multiple features. TODO/Jerry
        toggleCondition: (evt: any) => false,
        hitTolerance: 5,
        style: new Style(),
      }
    );

    const featureSelected = fromEvent(this._selectInteraction, 'select');
    featureSelected.subscribe((evt: any) => {
      evt.deselected.forEach((item) => {
        // Check to see if the item is in the selected list, if it is, then this is a
        // toggle off
        for (const key in this._createdLayerServices) {
          if (this._createdLayerServices[key].selectInteractionSupport) {
            if (item.get('opnum')) {
              this._createdLayerServices[key].removeSelectedOperation(item.get('opnum'));
            } else if (item.get('rmt_id')) {
              this._createdLayerServices[key].removeSelectedRMTs(item.get('rmt_id'));
            }
          }
        }
      });
      evt.selected.forEach((item) => {
        // Okay. So this is the magic that makes this work.  the UNDOCUMENTED unskipFeature/skipFeature methods
        // of the map object allow it to optionally skip rendering of vector features.  When the select interaction
        // finds a feature it turns on the skipping - here we turn it back off so the feature renders on the map.
        for (const key in this._createdLayerServices) {
          if (this._createdLayerServices[key].selectInteractionSupport) {
            if (item.get('opnum')) {
              // this.map.unskipFeature(item);
              this._createdLayerServices[key].addSelectedOperation(item.get('opnum'));
            } else if (item.get('rmt_id')) {
              this._createdLayerServices[key].addSelectedRMTs(item.get('rmt_id'));
            }
          }
        }
      });
    });
    this.map.addInteraction(this._selectInteraction);
  }

  private _createSource(src_url: string): XYZSource {
    return new XYZSource({
      url: ['https://api.mapbox.com/styles/v1/'
        , src_url
        , '/tiles/256/{z}/{x}/{y}?access_token=',
        , this._mapboxAPIKey].join(''),
      projection: MAP_CONST.PROJ_MERCATOR,
      attributions: [mapbox_attrib]
    });
  }

  private _updateUrl(evt: any): void {
    const zoom = evt.target.getZoom();
    const center = evt.target.getCenter();

    if (center && zoom) {
      const point = new Point(center);
      point.transform(evt.target.getProjection().getCode(), MAP_CONST.PROJ_WGS84);
      const wgs84_coords = point.getCoordinates();
      this._sharedService.updateMapCenter([(+wgs84_coords[0].toFixed(4)), (+wgs84_coords[1].toFixed(4)), zoom]);
    }
  }


  private _changeBaseLayer(src: string): void {
    /*
     * Sets the base layer on the map
     */
    const new_src = this._createSource(src);
    this._baseLayer.setSource(new_src);
    // Mark the layers changed so the features will update with any new colors/styles
    for (const key in this._createdLayerServices) {
      if (this._createdLayerServices.hasOwnProperty(key)) {
        this._createdLayerServices[key]._markLayersChanged();
      }
    }
  }

  private _toggleOverlay(overlayId: string, active: boolean): void {
    /*
     * Wrapper function that evaluates which overlay is being modified and hides/displays it
     */
    if (active) {
      this._enableLayer(overlayId);
    } else {
      this._disableLayer(overlayId);
    }
  }

  private _applyNewCenter(newCenter: Object): Point {
    // Should apply a marker and set the lat/lon, bounds and then zoom
    const pointData = newCenter['center'];
    const lon = pointData[0];
    const lat = pointData[1];
    const zoom = pointData[2];
    const point = new Point([lon, lat]);
    point.transform(MAP_CONST.PROJ_WGS84, this.map.getView().getProjection().getCode());
    this.map.getView().setCenter(point.getCoordinates());
    this.map.getView().setZoom(zoom);
    return point;
  }


  public updateMap(newDate: any): void {
    /*
     * Wrapper function that manages the update of layer information on the map
     * newDate<Date> = the date/time that we need to update the map to (if no date is passed then use the current date)
     * Will either be called by the Flight Track slider when replay is in Animated mode
     * Or by a setInterval when the replay is in Real Time mode
     * Triggered by sharedService
     */
    const replay = this._sharedService.pullReplayType();
    // If 0 is passed DON'T make a websocket call, we just want to clear the layers not pull data from 1970
    if (replay === FT_TEXT.REPLAY_OPTIONS_ANIMATED && newDate !== 0) {
      this._updateFlightAndRmtInfo(newDate);
    }

    // Update the timestamps of all time enabled layers using
    // the current date on the slider.
    this._updateLayerTimestamps(newDate);
  }

  private _updateFlightAndRmtInfo(newDate): void {
    /*
     * Pull/parse flight information (tracks and animated) and rmt information
     * newDate = type Date
     */
    const startDates = this._findCachedDataStartDate(newDate);
    const startDate = startDates[0];
    // Check if we have any data in the requested range
    if (startDate !== undefined) {
      // Now see if we hit our buffer
      const cacheEndDate = new Date(startDate.valueOf() + (this._wsIntervalSeconds * 1000));
      const bufferMinimum = this._cacheBufferSeconds * 1000;
      const bufferCurrent = cacheEndDate.valueOf() - newDate.valueOf();
      if (bufferMinimum > bufferCurrent) {
        // See if we already have data for the next range, otherwise we need to pull the next data_sample
        if (!this._findCachedDataStartDate(cacheEndDate).length) {
          this._requestWSDataSample(cacheEndDate);
        }
      }

    } else {
      // We don't have data in the required date range, pull data
      this._requestWSDataSample(newDate);
    }
  }

  private _findCachedDataStartDate(targetDate: any): any[] {
    /*
     * Loop through the cache dates array and figure out if the targetDate fits in any of the ranges (startDate + interval)
     * Return all valid startDates (there really should only be one, but there could also be 0)
     */
    return this._heldDataBucketRanges.filter(
      data => {
        const startDate = data;
        const endDate = new Date(startDate.valueOf() + (this._wsIntervalSeconds * 1000));
        return targetDate >= startDate && targetDate < endDate;
      }
    );

  }

  private _requestWSDataSample(reqDate: any): void {
    /*
     * Call web socket and perform the data_sample pull
     * Calculate the correct start date based off the required date
     */
    const interval = this._wsIntervalSeconds * 1000;
    const delay_in_ms = this._minDelay * 1000;
    const maxAPIStartDate = new Date().valueOf() - delay_in_ms - interval;
    // We can't pull data if the requested date is newer than what the API can pull or if a dataPull is already in progress
    if (!this._dataPullInProgress && reqDate <= maxAPIStartDate) {
      const floor = Math.floor(reqDate.valueOf() / interval) * interval;
      const startDate = new Date(floor);
      this._sharedService.pullDataSample(startDate);
    }
  }

  /**
   * A method that is called whenever we get new static track data from the server,
   * it sets the start and end time, as well as the associated data for the specified
   * time here.
   */
  private _processStaticTrackData(data: any): void {
    const static_track_service = this._getLayerService('static_tracks');
    static_track_service.enableLayer(data.data);
  }

  private _updateCounts(data: any) {
    let message;
    if (data.active === 0) {
      message = MAP_TEXT.NO_OPERATIONS_MSG;
    } else {
      message = MAP_TEXT.OPERATIONS_COUNT_MSG.replace('{count}', data.displayed).replace('{total}', data.active);
    }
    this._countDisplayOverlayElement.innerHTML = message;
  }

  private _processNewDataSample(data: any, layer_name: string): void {
    /*
     * Post process new Flight and RMT information that we get from our web socket
     * {date_range: [date, date], animated: {}, tracks: {features: []}, rmts: {features: []}}
     */
    if (this._createdLayerServices['rmts']) {
      this._createdLayerServices['rmts'].addDataSet(data.rmts);
    }
    if (layer_name !== 'rmts') {
      if (this._createdLayerServices[layer_name]) {
        this._createdLayerServices[layer_name].addDataSet({
          'features': data.tracks,
          'operations': data.operations
        });
      }
    }
  }

  /**
   * Update the current time slider value on all time enabled layers,
   * this will (in turn) cause the layers to re-render as needed.
   */

  private _updateLayerTimestamps(newDate: number, layers?: string[]) {
    const seconds = Math.floor(newDate / 1000);
    let keys;
    if (layers && layers.length > 0) {
      keys = layers;
    } else {
      keys = Object.keys(this._createdLayerServices);
    }
    keys.forEach((key) => {
      if (this._createdLayerServices[key].time_enabled &&
          this._createdLayerServices[key].isEnabled) {
        this._createdLayerServices[key].updateDisplayTime(seconds);
      }
    });
  }

  private _centerMapOnExtent(): void {
    // No center was defined in the url so center on the extent
    const operatorExtent = this._wsExtentGeom.getExtent();
    const view = this.map.getView();
    view.fit(operatorExtent);
  }

  private _openGateInfoDialog(data: any): void {
    const static_layer = this._getLayerService('static_tracks');
    const filtered_data = static_layer.filterData(data);

    const dialogRef = this._dialog.open(GateInfoDialogComponent, {
      width: '360px',
      data: filtered_data
    });
  }

  /**
   * Opens/closes the bottom menu for Gate, Point and Measure tools
   */
  private _manageBottomsheet(enable: boolean, layerService: any): void {
    if (enable) {
      this._bottomSheet.open(
        FeatureBottomsheetComponent,
        {hasBackdrop: false, disableClose: true, data: {layerService: layerService, chartCallback: this.pullGateData.bind(this)}}
      );
    } else {
      this._bottomSheet.dismiss();
    }
  }

  public pullGateData(geoms: any) {
    if (!!geoms.length) {
      const clock = this._sharedService.pullSystemClock();
      const obs = this._apiService.gateAnalysis(geoms, clock.staticStart, clock.staticEnd);
      obs.subscribe((data: any) => {
        this._openGateInfoDialog(data);
      });
    }
  }
}


