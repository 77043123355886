import {Component, OnInit, Inject} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {SharedService} from '../shared.service';
import {map_component as MAP_CONST} from '../env-constants';
import {feature_bottomsheet as FTR_TEXT} from '../env-translate';


@Component({
  selector: 'app-feature-bottomsheet',
  templateUrl: './feature-bottomsheet.component.html',
  styleUrls: ['./feature-bottomsheet.component.scss']
})

export class FeatureBottomsheetComponent implements OnInit {
  private _layerService: any;
  public featureType: string;
  public LABEL_CHART = FTR_TEXT.LABEL_CHART;
  public LABEL_CANCEL = FTR_TEXT.LABEL_CANCEL;
  public LABEL_GATE = FTR_TEXT.LABEL_GATE;
  public LABEL_PIN = FTR_TEXT.LABEL_PIN;
  public LABEL_MEASURE = FTR_TEXT.LABEL_MEASURE;
  public minimize = false;

  get LABEL_MINIMIZE(): string {
    return this.featureType === 'gate' ? FTR_TEXT.LABEL_MINIMIZE_GATE : (this.featureType === 'measure' ? FTR_TEXT.LABEL_MINIMIZE_MEASURE : FTR_TEXT.LABEL_MINIMIZE_PIN);
  }

  get LABEL_EXPAND(): string {
    return this.featureType === 'gate' ? FTR_TEXT.LABEL_EXPAND_GATE : (this.featureType === 'measure' ? FTR_TEXT.LABEL_EXPAND_MEASURE : FTR_TEXT.LABEL_EXPAND_PIN);
  }

  constructor( @Inject(MAT_BOTTOM_SHEET_DATA) private _data: any,
    private _sharedService: SharedService,
    private _bottomSheetRef: MatBottomSheetRef<FeatureBottomsheetComponent>) {

    const layer = this._data.layerService;
    this._layerService = layer;
    this.featureType = layer.nomsLayerLabel;
  }

  ngOnInit() {

  }

  /**
   * Allows user to remove the existing feature and draw a new one without clicking on the left nav twice
   */
  public drawNewFeature(): void {
    this._layerService.clearFeatures(true);
    this._layerService.enableDrawing();
  }

  /**
   * Reopen the Gate chart dialog, only available for featureType = 'gate'
   */
  public viewChart(): void {
    const geoms = this._layerService.getGeometries(MAP_CONST.MAP_PROJECTION);
    if (geoms.length > 0) {
      this._data.chartCallback(geoms);
    }
  }

  /**
   * Close the bottom menu and turn off the action on the left nav
   */
  public close(): void {
    const obs_name = this.featureType === 'gate' ? 'publishMapGate' : (this.featureType === 'measure' ? 'publishMapMeasure' : 'publishMapPointDistance');
    this._sharedService[obs_name](false);
    this._bottomSheetRef.dismiss();
  }

  public toggleHeight(): void {
    this.minimize = !this.minimize;
  }

}
