import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {ft_api_urls as FT_URLS, map_component as MAP_CONSTANTS} from './env-constants';
import WKT from 'ol/format/WKT';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable()
export class ApiService {

  constructor(private _http: HttpClient) {}

  public callAirportDetails(): Observable<any> {
    /*
     * HTTP call to pull airport information, used for our runway filters
     * Airports are used to categorize the runways
     * Array of objects with the following properties: display_name, icao_code, local_code, night_end, night_start, operator, timezone
     */
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_DETAILS;
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );
  }

  public callAirlinesIcao(qry: string[]): Observable<any> {
    /*
     * HTTP call to search for specific airlines using their ICAO Code
     * ICAO codes are appended to the end and are comma delimited
     */
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_AIRLINES + '?' + FT_URLS.QRY_PARAMS_AIRLINES_ICAO + qry.join(',');
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );
  }

  public callAirlinesSearch(qry: string): Observable<any> {
    /*
     * HTTP call to search for specific airlines using a text search
     * Searches across the name and short name fields
     */
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_AIRLINES + '?' + FT_URLS.QRY_PARAMS_AIRLINES_SEARCH + qry;
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );
  }

  public callOperationsSummary(startDate: any, endDate?: any): Observable<any> {
    /*
     * HTTP call to pull operations information for a specific time period
     * If no endDate is supplied then assume we want a 24 hr period
     * startDate and endDate should be an ISO8601 timestamp ("2017-11-01T05:00:00.000Z")
     */
    const sDate: Date = new Date(startDate);
    const eDate: Date = !!endDate ? new Date(endDate) : _calcEndDate();

    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_OP_SUMMARY + sDate.toISOString() + '/' + eDate.toISOString() + '/';
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );

    function _calcEndDate(): Date {
      const dt = new Date(sDate);
      return new Date(dt.setDate(dt.getDate() + 1));
    }
  }

  public callRunwayUseSummary(airport_code: string, startDate: any, endDate?: any): Observable<any> {
    /*
     * HTTP call to pull runway use information for a specific time period
     * Airport code is needed to specify which runways are summarized
     * If no endDate is supplied then assume we want a 24 hr period
     * startDate and endDate should be an ISO8601 timestamp ("2017-11-01T05:00:00.000Z")
     *
     * data returned = [{
     *  airport: "msp"
     *  arr_config: "30L,30R"
     *  dep_config: "17,30L,30R"
     *  label: "MA"
     *  stime:"2018-01-08T00:00:00+00:00"
     *  total_operations: {b: 0, c: 12, h: 0, j: 0, m: 0, p: 0, t: 0, u: 0, total: 12}
     * }]
     */
    const sDate: Date = new Date(startDate);
    const eDate: Date = !!endDate ? new Date(endDate) : _calcEndDate();

    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_RU_SUMMARY_P1 + airport_code + FT_URLS.PATH_RU_SUMMARY_P2
      + sDate.toISOString() + '/' + eDate.toISOString() + '/';
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );

    function _calcEndDate(): Date {
      const dt = new Date(sDate);
      return new Date(dt.setDate(dt.getDate() + 1));
    }
  }

  public callRunways(): Observable<any> {
    /*
     * HTTP call to pull the list of runways for our filter UI
     * Array of objects with the following properties: icao_code, local_code, opposite_code, runway, runway_code, start
     */
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_RUNWAYS;
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );
  }

  public getTimezone(): Observable<any> {
    /*
     * HTTP call to pull the list of possible time zones for our locale
     * Need to send it the timezone offset, returns {"matches": <STRING>}
     */
    const tz_offset = new Date().getTimezoneOffset();
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_TIMEZONE + tz_offset + '/';
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );
  }


  public fetchMatchingEvents(opnum: number): Observable<any> {
    /*
     * HTTP call to pull noisematch events tied to a particular operation.
     */
    const url = [FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_EVENTS,  ''].join('/');
    const params = { 'opnum': opnum + '' };
    return this._http
      .get(url, {
        params: params
      })
      .pipe(
      catchError(this.handleError)
      );
  }

  public fetchOperation(opnum: number, interpolate: any): Observable<any> {
    /*
     * HTTP call to pull detailed data for a specific operation
     */
    const url = [FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_OPERATIONS, opnum + '', ''].join('/');
    const params = {};
    if (interpolate) {
      params['interpolate'] = 1;
    }
    return this._http
      .get(url, {params: params})
      .pipe(
      catchError(this.handleError)
      );
  }
  public fetchRMTRange(start_time: string, end_time: string): Observable<any> {
    /*
     * HTTP call to pull rmt data for a specific time range.
     */
    const url = [FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.NOISE_DATA, start_time, end_time, ''].join('/');
    const params = {};

    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );
  }


  public gateAnalysis(geomArray: any, sDate: any, eDate: any, geom_projection?: any): Observable<any> {
    /*
     * HTTP call to pull the list of runways for our filter UI
     * Array of objects with the following properties: icao_code, local_code, opposite_code, runway, runway_code, start
     */

    const wgs_geom = geomArray[0].clone();
    wgs_geom.transform((geom_projection || MAP_CONSTANTS.MAP_PROJECTION), MAP_CONSTANTS.PROJ_WGS84);
    const format = new WKT();
    const wkt = format.writeGeometry(wgs_geom);

    const url = [FT_URLS.PROTOCOL,
    FT_URLS.HOSTNAME,
    FT_URLS.GATE_ANALYSIS, '/',
    sDate.toISOString(), '/',
    eDate.toISOString(), '/'].join('');
    const params = {
      'geometry': wkt,
      'srid': MAP_CONSTANTS.PROJ_WGS84.split(/:/)[1]
    };
//    console.log('Request params are ', params);
    return this._http
      .get(url, {params: params})
      .pipe(
      catchError(this.handleError)
      );
  }


  public getComplaintReasons(): Observable<any> {
    /*
     * HTTP call to pull the list of reasons for our complaint form
     * Array of objects with the following properties: description, id, operator, reason
     * objects[
     *  {   description: "Early/Late"
            id: 1
            operator: 1
            reason: "early_late"
        }
     * ]
     */
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_COMPLAINT_REASONS;
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );
  }


  public addComplaint(complaintInfo: any): Observable<any> {
    /**
     * Send a complaint to the server
     * {user_location: number, reasons: string[], icao_code: string, adflag: string, complaint_date: isoString}
     */
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_COMPLAINT;
    return this._http
      .post(url, complaintInfo, httpOptions)
      .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * HTTP call to request an authentication token for the websocket
   * Returns {authenticated: false} or {authenticated: true, token: ......}
   */
//   public requestWSToken(): Observable<any> {
//       const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_SOCKET_TOKEN;
//       return this._http
//       .get(url, httpOptions)
//       .pipe(
//         catchError(this.handleError)
//       );
//   }


  /**
   * HTTP call to verify the user has or has not authenticated into the system
   */
  public getUserStatus(): Observable<any> {
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_USER_STATUS;
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );
  }

  /**
  * HTTP call to pull the users location information
  * objects: [{
        address: "121 Tatooine Lane"
        address2: ""
        customer_id: 12345
        date_created: "2019-08-19T22:49:37.811386+00:00"
        date_inactive: null
        id: 1234
        location: "{ "type": "Point", "coordinates": [ -93.155290412431128, 44.661408843973192 ] }"
        saved_location: 5678
  * }
  * ]
  */
  public getUserLocation(userId: number): Observable<any> {
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_USER_LOCATION;
    const params: any = {
        'date_inactive__isnull': true,
        'user': userId
    };
    return this._http
      .get(url, {params: params})
      .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * HTTP call to change the users password
   */
  public changePassword(info: any): Observable<any> {
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_CHANGE_PASSWORD;
    return this._http
      .post(url, info, httpOptions)
      .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * HTTP call to REQUEST A TOKEN to reset the users password (the api should return success=true)
   * Send the info {email: <string>}
   */
  public requestResetToken(info: any): Observable<any> {
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_RESET_REQUEST;
    return this._http
      .post(url, info, httpOptions)
      .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * HTTP call to reset the users password with the token we received
   * Construct the url with the token
   * Send the info {new_password1: <string>, new_password2: <string>}
   */
  public resetPassword(token: string, info: any): Observable<any> {
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_RESET_PASSWORD_P1 + token + FT_URLS.PATH_RESET_PASSWORD_P2;
    return this._http
      .post(url, info, httpOptions)
      .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * HTTP call to add the user into the system
   * {
   *    address: string,
   *    address2: string,
   *    email: string,
   *    first_name: string,
   *    last_name: string,
   *    phone: string,
   *    application:
   * }
   */
  public addUser(userInfo: any): Observable<any> {
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_NEW_USER;
    return this._http
      .post(url, userInfo, httpOptions)
      .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * HTTP call to send a request for help
   * {
   *    email: string,
   *    address: string
   * }
   */
  public requestHelp(userInfo: any): Observable<any> {
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_ADDRESS_HELP;
    return this._http
      .post(url, userInfo, httpOptions)
      .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * HTTP call to log the user into the system
   */
  public loginUser(userInfo: any): Observable<any> {
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_USER_LOGIN;
    return this._http
      .post(url, userInfo, httpOptions)
      .pipe(
      catchError(this.handleError)
      );
  }


 /**
 * HTTP call to log the user out of the system
 */
  public logoutUser(): Observable<any> {
    const url = FT_URLS.PROTOCOL + FT_URLS.HOSTNAME + FT_URLS.PATH_USER_LOGOUT;
    return this._http
      .get(url)
      .pipe(
      catchError(this.handleError)
      );
  }


  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an error with a user-facing error message
    return throwError('System encountered an error; please try again later.');
  }
}
