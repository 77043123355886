import {Component, OnInit, Inject, EventEmitter} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {SharedService} from '../shared.service';
import {ApiService} from '../api.service';
import {authentication as AUTH_TEXT} from '../env-translate';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';


@Component({
  selector: 'app-authentication-dialog',
  templateUrl: './authentication-dialog.component.html',
  styleUrls: []
})

export class AuthenticationDialogComponent implements OnInit {
  form: FormGroup;
  public openAuthDialog = new EventEmitter<string>();
  public BUTTON_LABEL_1: string = AUTH_TEXT.BUTTON_CANCEL;
  public BUTTON_LABEL_2: string;
  public TITLE: string;
  public EMAIL_FIELD_LABEL: string = AUTH_TEXT.EMAIL_FIELD_LABEL;
  public PASSWORD_FIELD_LABEL: string = AUTH_TEXT.PASSWORD_FIELD_LABEL;
  public NEW_PASSWORD_FIELD_LABEL: string = AUTH_TEXT.NEW_PASSWORD_FIELD_LABEL;
  public REENTER_NEW_PASSWORD_FIELD_LABEL: string = AUTH_TEXT.REENTER_NEW_PASSWORD_FIELD_LABEL;
  public MESSAGE: string;
  public LINK_NEW_ACCOUNT: string = AUTH_TEXT.LINK_NEW_ACCOUNT;
  public LINK_RESET: string = AUTH_TEXT.LINK_RESET;
  public LINK_CHANGE: string = AUTH_TEXT.LINK_CHANGE;
  public formType: string; /* login, change, reset, request */
  public token: string;
  public ERRORS: any = AUTH_TEXT.ERROR_MSGS;
  public API_ERROR: string;
  public sending = false;
  private _userInfo: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _dialogRef: MatDialogRef<AuthenticationDialogComponent>,
    private _sharedService: SharedService,
    private _fb: FormBuilder,
    private _apiService: ApiService
  ) {}

  ngOnInit() {
    this._sharedService.userInfo$.subscribe( info => this._userInfo = info);

    this.form = this._fb.group({
      'email': [this._userInfo ? this._userInfo['email'] : '', [Validators.required, Validators.email]],
      'password': ['', Validators.required],
      'newPassword': ['', Validators.required],
      'reenterNewPassword': ['', Validators.required]
    });

    this._calcLabelsAndFields();

  }

  /**
   * Getter for the email form control
   */
  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  get password(): FormControl {
    return this.form.get('password') as FormControl;
  }

  get newPassword(): FormControl {
    return this.form.get('newPassword') as FormControl;
  }

  get reenterNewPassword(): FormControl {
    return this.form.get('reenterNewPassword') as FormControl;
  }

  private _calcLabelsAndFields(): void {
    // form types are login, change, request and reset
    this.formType = this._data['type'];
    this.token = this._data['token'];
    if (this.formType === 'reset') {
      // Resetting the users password (they got here by clicking on an email link)
      this.TITLE = AUTH_TEXT.TITLE_RESET;
      this.BUTTON_LABEL_2 = AUTH_TEXT.BUTTON_SUBMIT;
      this.password.disable();
      this.email.disable();
    } else if (this.formType === 'request') {
      // Requesting a password reset token
      // If they clicked on an expired link, they will be sent to this page with a blank token
      // We need to change the text of a few messages to notify them
      this.TITLE = this.token === '' ? AUTH_TEXT.TITLE_REQUEST_EXPIRED : AUTH_TEXT.TITLE_REQUEST;
      this.BUTTON_LABEL_2 = this.token === '' ? AUTH_TEXT.BUTTON_REQUEST : AUTH_TEXT.BUTTON_SEND_EMAIL;
      this.MESSAGE = this.token === '' ? AUTH_TEXT.RESET_REQUEST_INVALID_MESSAGE : AUTH_TEXT.RESET_REQUEST_MESSAGE;
      this.password.disable();
      this.newPassword.disable();
      this.reenterNewPassword.disable();
    } else if (this.formType === 'change') {
      // Changing the users existing password
      this.TITLE = AUTH_TEXT.TITLE_CHANGE;
      this.BUTTON_LABEL_2 = AUTH_TEXT.BUTTON_SUBMIT;
      // Default the email value (and disable it) if the user is already logged in
      if (this._sharedService.getUserStatus()) {
        // this.email.setValue(this._sharedService.getUserEmail());
        this.email.disable();
      }
    } else {
      // Standard login
      this.TITLE = AUTH_TEXT.TITLE_LOGIN;
      this.BUTTON_LABEL_2 = AUTH_TEXT.BUTTON_LOGIN;
      this.newPassword.disable();
      this.reenterNewPassword.disable();
    }
  }

  public newDialog(dialogType: string): void {
    this.openAuthDialog.emit(dialogType);
  }

  public getErrorMsg(control: FormControl): string {
    const errMap = [
      {error: 'email', msg: this.ERRORS.VALID_EMAIL},
      {error: 'required', msg: this.ERRORS.REQUIRED},
      {error: 'minlength', msg: this.ERRORS.MIN_SIZE},
      {error: 'pattern', msg: this.ERRORS.VALID_PASSWORD},
    ];
    let msg: string;

    errMap.forEach(
      err => {
        if (control.errors[err.error]) {
          msg = err.msg;
        }
      }
    );

    return msg;
  }

  public checkForSubmit(evt: any): void {
    if (evt.keyCode === 13 && this.form.valid) {
      this.submitForm();
    }
  }


  /**
   * Submits the form values to the API and processes any issues that are returned
   * If no issues then close the dialog
   */
  public submitForm(): any {
    // Wipe any API Errors
    this.API_ERROR = '';
    // Set the progress bar to display
    this.sending = true;
    // Perform one last validation on the form, check that the new password fields match
    if (this.reenterNewPassword.enabled && this.reenterNewPassword.value !== this.newPassword.value) {
      this.form.setErrors({passwordMatch: true});
      this.sending = false;
    } else {
      // Add the call to the API here
      if (this.formType === 'login') {
        // User is logging in
        // Google Analytics
        window['gtag']('event', 'user_account', { 'event_category': 'Login' });
        this._apiService.loginUser({email: this.email.value, password: this.password.value})
          .subscribe(data => this._processApiResults(data));
      } else if (this.formType === 'change') {
        // Google Analytics
        window['gtag']('event', 'user_account', { 'event_category': 'Change Password' });
        // User is changing their password
        this._apiService.changePassword({
          email: this.email.value,
          password: this.password.value,
          new_password1: this.newPassword.value,
          new_password2: this.reenterNewPassword.value
        }).subscribe(data => this._processApiResults(data));
      } else if (this.formType === 'request') {
        // Google Analytics
        window['gtag']('event', 'user_account', { 'event_category': 'Request Password Reset' });
        // User is requesting a password reset token
        this._apiService.requestResetToken({email: this.email.value})
          .subscribe(data => this._processApiResults(data));
      } else {
        // Google Analytics
        window['gtag']('event', 'user_account', { 'event_category': 'Reset Password' });
        // User is resetting their password
        this._apiService.resetPassword(
          this._data['token'],
          {
            new_password1: this.newPassword.value,
            new_password2: this.reenterNewPassword.value
          }).subscribe(data => this._processApiResults(data));
      }
    }
  }

  private _processApiResults(data: any) {
    // Login/ Logout api returns {'success': false, 'error':<string>}
    // Change Password api returns {'success': false, 'reason': <string>[]}
    if (!!data.success) {
      this._sharedService.pollUserStatus();
      const successMsg = AUTH_TEXT.SUCCESS_MSGS[this.formType.toUpperCase()];
      if (successMsg) {
        this._sharedService.publishFTMessage(successMsg, null);
      }
      this._dialogRef.close(true);
    } else {
      // Track any errors returned by the API
      this.API_ERROR = !data.reason ? data.error : data.reason.join('\n');
      // Turn off the progress bar
      this.sending = false;
    }
  }

}


