import VectorSource from 'ol/source/Vector';
import {map_component as MAP_CONST} from '../../env-constants';

import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';
import Icon from 'ol/style/Icon';
import DrawInteraction from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import { Type } from 'ol/geom/Geometry';

import {fromEvent} from 'rxjs';
import {BaseLayerService} from './base-layer.service';

export class BaseDrawingLayerService extends BaseLayerService {
  private source: VectorSource;
  public nomsLayerLabel: string;
  protected _maxFeatureCount = 1;
  protected interaction: any;
  protected drawing_type: Type = 'Point';
  protected _maxPoints = 2;
  protected _publishService: any;
  protected observable_name = 'uninitialized';


  protected pinStyle: Style = new Style({
    image: new Icon({
      anchor: [.5, 1],
      size: [24, 24],
      scale: 1,
      opacity: 1,
      src: MAP_CONST['PIN_ICON']
    })
  });

  protected getStyle(): Style {
    return new Style({
      fill: new Fill({
        color: 'rgba(255,255,255,0.2)'
      }),
      stroke: new Stroke({
        color: '#ffcc33',
        width: 2
      }),
      image: new Circle({
        radius: 3,
        fill: new Fill({
          color: '#ffcc33'
        })
      })
    });
  }

  protected getLayerStyle(): any {
    return this.getStyle();
  }

  public getLayer(): any {
    /**
     * We only create one layer for the service, so handle that
     * here.
     */
    if (this.layer === undefined) {
      // Create a new layer.
      this.source = new VectorSource();
      this.layer = new VectorLayer({
        source: this.source,
        zIndex: 200,
        style: this.getLayerStyle()
      });
      // Handle the addfeature event.
      const featureAdded = fromEvent(this.source, 'addfeature');
      featureAdded.subscribe((evt: any) => {
        const geom = evt.feature.getGeometry();
        // If the geometry is a point then the user is adding a pin or a pin was added through the search bar
        if (geom.getType() === 'Point') {
          evt.feature.setStyle(this.pinStyle);
        }
        evt.feature.set('create_ts', (new Date).getTime());
        // Add the timestamp to the feature so we can intelligently remove
        // extra features.
        this.clearFeatures(false);
        // Once a feature is drawn, disable the interaction.
        this.disableDrawing();
        // Get the geometries and propagate them to listeners.
        this._publishGeometries(this.getGeometries(MAP_CONST.MAP_PROJECTION));
      });

    }
    return this.layer;
  }

  public addFeature(geom: any): void {
    const feature = new Feature({
      geometry: geom
    });
    this.source.addFeature(feature);
  }


  /**
   * Remove all features if passed a true as an argument. Otherwise, ensure that the
   * feature count does not exceed the maximum allowed feature count.
   */
  public clearFeatures(all: boolean): void {
    if (all) {
      this.source.clear();
    } else {
      const features = this.source.getFeatures();
      // Remove features so that we remain under the mex feature count.
      if (features.length > this._maxFeatureCount) {
        const sorted_features = features.sort((f1, f2) => f2.get('create_ts') - f1.get('create_ts'));
        const prune_count = features.length - this._maxFeatureCount;
        sorted_features.splice(0, prune_count);
        sorted_features.forEach(f => this.source.removeFeature(f));
      }
    }
  }

  /**
   * Method to call after an interaction has been added to the map, to setup
   * other listeners etc.
   */
  protected postAddInteraction(): void {

  }

  /**
   * Method to call after an interaction has been removed to the map, to destroy
   * other listeners etc.
   */
  protected postRemoveInteraction(): void {

  }

  public enableDrawing() {
    if (!this.interaction) {
      this.interaction = new DrawInteraction({
        source: this.source,
        type: this.drawing_type,
        maxPoints: this._maxPoints,
        style: this.getStyle(),
      });
    }
    this._map.addInteraction(this.interaction);
    this.postAddInteraction();
  }

  public disableDrawing() {
    if (this.interaction) {
      this._map.removeInteraction(this.interaction);
      this.postRemoveInteraction();
    }
  }

  protected _publishGeometries(geoms: any): void {
    // don't bother to publish if we don't have data.
    if (geoms && geoms.length) {
      this._sharedService[this.observable_name](geoms);
    }
  }


  /**
   * Transform geometries to WGS84 and return them.
   */
  public getGeometries(srid: any) {
    srid = srid || MAP_CONST.PROJ_WGS84;
    const features = this.source.getFeatures();
    const geoms = [];

    features.forEach(f => {
      const this_geom = (f.getGeometry()).clone();
      if (MAP_CONST.MAP_PROJECTION !== srid) {
        this_geom.transform(MAP_CONST.MAP_PROJECTION, MAP_CONST.PROJ_WGS84);
      }
      geoms.push(this_geom);
    });
    return geoms;
  }

}
