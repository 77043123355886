import { BaseDrawingLayerService } from './base-drawing-layer.service';
import { Type } from 'ol/geom/Geometry';


export class GateDrawingService extends BaseDrawingLayerService {
    public nomsLayerLabel = 'gate';
    protected drawing_type: Type = 'LineString';
    protected _maxPoints = 2;
    protected observable_name = 'publishMapGateGeom';
}
