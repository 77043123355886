/*jshint bitwise: false*/
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Text from 'ol/style/Text';
import Circle from 'ol/style/Circle';
import BaseLayer from 'ol/layer/Base';
import Feature from 'ol/Feature';
import VectorTile from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import Gradient from 'javascript-color-gradient';

import {BaseTimeEnabledLayerService} from './base-time-enabled-layer.service';


export class NoiseMonitorLayerService extends BaseTimeEnabledLayerService {
  // Range of colors - at 0% we are all green
  // At 100% we are all red
  public percentColors = [
    {pct: 65, color: '#00ff00'},
    {pct: 80, color: '#ffff00'},
    {pct: 90, color: '#ff9900'},
    {pct: 100, color: '#ff0000'}];
  private style: any;
  protected layer: any;
  private _trackRMTLmaxData: any = {};
  public time_enabled = true;
  protected layer_data: any[] = [];
  public nomsLayerLabel = 'noise_monitors';

  public selectInteractionSupport = true;
  public colors: string[] = [];

  private _getColorForRMT(noise: number): string {
    if (this.colors.length === 0) {
      this.percentColors.forEach((o: any, index: number) => {
        if (index > 0) {
          const tmp = new Gradient().setColorGradient(this.percentColors[index - 1].color, this.percentColors[index].color).setMidpoint(this.percentColors[index].pct - this.percentColors[index - 1].pct).getColors();
          this.colors = this.colors.concat(tmp);
        };
      });
    }

    noise = noise > 100 ? 100 : noise;

    if (!noise) {
      return '#959ca2cc';
    }
    const index = noise < this.percentColors[0].pct ? 0 : noise - this.percentColors[0].pct;
    return this.colors[index];
  }

  public toggleSelectedRMT(opnum: number): number[] {
    const index = this._selectedRMTs.indexOf(opnum);
    if (index === -1) {
      this._selectedRMTs.push(opnum);
    } else {
      this._selectedRMTs.splice(index, 1);
    }
    this._markLayersChanged();
    return this._selectedRMTs;
  }


  public addSelectedRMT(opnum: number): number[] {
    const index = this._selectedRMTs.indexOf(opnum);
    if (index === -1) {
      this._selectedRMTs.push(opnum);
      this._markLayersChanged();
    }
    return this._selectedRMTs;
  }

  public removeSelectedRMT(opnum: number): number[] {
    const index = this._selectedRMTs.indexOf(opnum);
    if (index > -1) {
      this._selectedRMTs.splice(index, 1);
      this._markLayersChanged();
    }
    return this._selectedRMTs;
  }

  public clearSelectedRMTs() {
    this._selectedRMTs.length = 0;
  }

  public addDataSet(data: any): void {
    this.layer_data.push(data);
    // console.log('Got new noise monitor data', data);
    if (this.layer_data.length > this._bucketHoldCount) {
      this.layer_data.shift();
    }
    this.layer.set('noise_events', this.layer_data);
    this.layer.changed();
  }

  public getLayer() {
    /**
     * We only create one layer for the service, so handle that
     * here.
     */
    if (this.layer === undefined) {
      // Handle when we need to display lmax data for RMTs that are on the map.
      this._sharedService.mapSelectedTrackRMTLmax$.subscribe((data?: any): void => {
        this._trackRMTLmaxData = data || {};
        this.layer.changed();
      });
      // Create a new layer.
      this.layer = new VectorTile({
        declutter: false,
        renderBuffer: 300,
        maxResolution: 300,
        source: new VectorTileSource({
          format: new MVT({
            'layers': ['Noise Monitors'],
            'featureClass': Feature
          }),
          url: this._url,
          // renderMode: 'vector'
        }),
        updateWhileAnimating: true,
        visible: true,
        properties: {
          selectable: false,
          noms_layer_label: this.nomsLayerLabel,
        },
        zIndex: 15,
      });
      this.style = this.getStyle(this.layer);
      this.layer.setStyle(this.style);
    }
    return this.layer;
  }



  public getStyle(layer: BaseLayer): any {
    const stroke = new Stroke({
      color: '#000000',
      width: 1
    });
    const circleFill = new Fill({'color': [224, 244, 244, .4]});
    const circle = new Circle({
      fill: circleFill,
      radius: 10,
      stroke: stroke
    });
    const text = new Style({
      text: new Text({
        font: '10px sans-serif',
        fill: new Fill({
          color: this._getTextColor('rmt_label_fill')
        }),
        stroke: new Stroke({
          color: this._getTextColor('rmt_label_stroke'),
          width: 3
        })
      })
    });

    const labelText = new Text({
      textAlign: 'center',
      textBaseline: 'middle',
      font: 'normal 11px Arial',
      fill: new Fill({color: this._getTextColor('fill')}),
      stroke: new Stroke({color: this._getTextColor('stroke'), width: 3}),
      offsetY: -15,
      overflow: true,
      rotation: 0
    });
    const labelStyle = new Style({
      text: labelText
    });


    const style = new Style({
      image: circle
    });

    // This is the function that determines the feature style,
    // we use a base linestyle for each feature to make things easier.
    return (feature: Feature, resolution: number): any => {
      const text_style = text.getText();
      const this_rmt = feature.get('rmt');
      if (resolution > 40) {
        text_style.setText('');
      } else {
        text_style.setText(this_rmt + '');
      }
      const noise_events = layer.get('noise_events');
      const optime = layer.get('current_time');
      let color = this._getColorForRMT(null);
      let radius = 9;
      if (resolution > 150) {
        radius = 4.5;
      }
      let noise_level = null;
      if (optime && noise_events) {
        noise_events.some(data_set => {
          if (data_set[this_rmt] && data_set[this_rmt][optime]) {
            noise_level = data_set[this_rmt][optime];
            return true;
          }
        });
      }
      if (noise_level) {
        color = this._getColorForRMT(noise_level);
      }

      const style_components = [];

      // Handle the case when we need to label the RMT for a selected track's LMAX value.
      if (this._trackRMTLmaxData) {
        Object.keys(this._trackRMTLmaxData).forEach((key): void => {
          if (Number(key) === this_rmt) {
            const mtime = new Date(this._trackRMTLmaxData[key].mtime).toLocaleTimeString();
            const label = [this._trackRMTLmaxData[key].lmax, 'dB', ' @ ', mtime].join('');
            labelText.setText(label);
            labelText.getFill().setColor(this._getTextColor('fill'));
            labelText.getStroke().setColor(this._getTextColor('stroke'));
            color = this._getColorForRMT(this._trackRMTLmaxData[key].lmax);
            style_components.push(labelStyle);
          }
        });
      }
      circleFill.setColor(color);

      let this_stroke = stroke;
      // The highlighted RMT should be styled slighly different so that
      // someone can differentiate it from other RMTs. this happens
      // in the event detective use case...
      if (layer.get('highlight') === this_rmt) {
        // The style for a hightlighted RMT.
        this_stroke = new Stroke({
          color: '#00008B',
          width: 4
        });
      }


      const final_style = new Style({
        image: new Circle({
          fill: circleFill,
          radius: radius,
          stroke: this_stroke
        })
      });
      style_components.push(final_style);
      style_components.push(text);


      return style_components;
    };
  }
}
