import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Projection from 'ol/proj/Projection';
import GeoJSON from 'ol/format/GeoJSON';
import LayerGroup from 'ol/layer/Group';
import geojsonvt from 'geojson-vt';
import Collection from 'ol/Collection';
import {fromEvent} from 'rxjs';
import Overlay from 'ol/Overlay';
import {BaseOperationLayerService} from './base-operation-layer.service';
import VectorTile from 'ol/VectorTile';


export class StaticTrackLayerService extends BaseOperationLayerService {

  protected _nomsLayerLabel = 'static_tracks';
  public selectInteractionSupport = true;
  private _selectedOperationLayers: any = {};
  private _pointerMoveEvent;
  private _featureOverlay: Overlay;
  private _lmax_times = [];
  private _subscribedPointerMoveEvent: any;
  private _geojsonSource: any;
  public getLayer() {
    /**
     * We only create one layer for the service, so handle that
     * here.
     */
    if (this.layer === undefined) {
      // Create a new layer.
      this.layer = new LayerGroup({
        layers: [],
        zIndex: 100, // Get this bad boy on top..
      });
      this._createFeatureOverlay();
      this._pointerMoveEvent = fromEvent(this._map.getViewport(), 'pointermove');
    }
    return this.layer;
  }


  private _hideFeatureOverlay() {
    /*
     * Hide the feature overlay
     */
    this._featureOverlay.setPosition(undefined);
    // Disable any displayed RMT data.
    this._sharedService.publishSystemCurrentDateTime(new Date(0));
  }


  private _createFeatureOverlay(): void {
    /*
     * Create the open layers overlay
     * This should only happen once!
     */
    const ov = new Overlay({
      position: undefined,
      positioning: 'center-center',
      stopEvent: false,
      element: document.getElementById('feature_overlay_popup')
    });
    this._map.addOverlay(ov);
    this._featureOverlay = ov;
  }

  private _updateFeatureOverlay(pixel: any, features: any[]): void {
    /*
     * Updates the selected features for the feature overlay
     * Also sets the position of the overlay (which displays it)
     */
    const position = this._map.getCoordinateFromPixel(pixel);
    const newData = [];

    features.map(
      selFtr => {
        const opData = this._selectedOperationLayers[selFtr.get('opnum')].get('operation');
        const ftrData = selFtr.getProperties();
        // Really, this needs to be called on the first feature...
        this._sharedService.publishSystemCurrentDateTime(new Date(ftrData['epoch_seconds'] * 1000));
        newData.push(({'op_data': opData, 'ftr_data': ftrData}));
      }
    );

    this._sharedService.publishMapFeaturePopupData(newData);
    this._featureOverlay.setPosition(position);
  }

  public layerRemoved() {
    super.layerRemoved();
    this.disableLayer();
  }

  public disableLayer() {
    this.layer.getLayers().clear();
    this._toggleMouseOverHandler(false);
    this._sharedService.publishRMTLmaxData();
    this._map.render();
  }

  public addHoverOperation(item: any) {
    // console.log('Hover on', item);
  }

  public removeHoverOperation(item: any) {
    // console.log('hover off', item);
  }


  protected publishOpsCounts(current_time: number) {
    if (this._geojsonSource) {
      const total_operations = this._geojsonSource.features.length;

      let displayed_operations = 0;
      this._geojsonSource.features.forEach((feat) => {
        if (this._filterFeature(feat.properties)) {
          displayed_operations++;
        }
      });
      this._sharedService.publishActiveData({
        'active': total_operations,
        'displayed': displayed_operations
      });
    }
  }


  private handleHoverInteractionEvent(evt: any) {
  }

  private _toggleMouseOverHandler(enable: boolean): void {
    this._hideFeatureOverlay();
    if (!this._subscribedPointerMoveEvent && enable) {
      this._subscribedPointerMoveEvent = this._pointerMoveEvent.subscribe((evt) => {
        const pixel = this._map.getEventPixel(evt);
        const features = this._map.getFeaturesAtPixel(pixel, {
          layerFilter: (layer) => {
            let found = false;
            Object.keys(this._selectedOperationLayers).forEach((k) => {
              if (this._selectedOperationLayers[k] === layer) {
                found = true;
              }
            });
            return found;
          }
        });
        if (!features?.length) {
          this._hideFeatureOverlay();
        } else {
          this._updateFeatureOverlay(pixel, features);
        }
      });
    } else if (this._subscribedPointerMoveEvent && !enable) {
      this._subscribedPointerMoveEvent.unsubscribe();
      this._subscribedPointerMoveEvent = null;
    }
  }


  protected _markLayersChanged(): void {
    super._markLayersChanged();
    this.publishOpsCounts(0);
  }


  public addSelectedOperation(opnum: number): any {
    if (this.layer.getLayers().getLength() > 0) {
      const response = super.toggleSelectedOperation(opnum);
      const featureReader = new GeoJSON({'featureProjection': 'EPSG:3857'});
      this._apiService.fetchOperation(opnum, false).subscribe((data: any) => {
        const vectorSource = new VectorSource({
          features: featureReader.readFeatures(data.track)
        });
        this._selectedOperationLayers[opnum] = new Vector({
          source: vectorSource,
          zIndex: 100,
          properties: {
            selectable: false,
            mouseover: true,
            operation: data
          }
        });

        // Listen for mouse over events on this.
        this._toggleMouseOverHandler(true);
        this.updateNoiseMonitorData();
        this.updateMatchingEventData(opnum);
        const this_style = this.getPointStyle(this._selectedOperationLayers[opnum]);
        this._selectedOperationLayers[opnum].setStyle(this_style);
        this.layer.getLayers().push(this._selectedOperationLayers[opnum]);
      });
    }
  }


  public removeSelectedOperation(opnum: number): any[] {
    if (this._selectedOperationLayers[opnum]) {
      // Clear any matching event data.
      this.updateMatchingEventData();
      // Remove the selected operation from the current layer.
      this.layer.getLayers().remove(this._selectedOperationLayers[opnum]);
      delete this._selectedOperationLayers[opnum];
    }
    const keys = Object.keys(this._selectedOperationLayers);
    if (keys.length === 0) {
      this._toggleMouseOverHandler(false);
    }
    return keys;
  }


  /**
   * Fetch events that are noisematched to this particular track so that users
   * can see what the max values are.
   */
  private updateMatchingEventData(opnum?: number): void {
    const _rmt_data = {};
    this._lmax_times.length = 0;
    if (opnum) {
      this._apiService.fetchMatchingEvents(opnum).subscribe((data: any) => {
        if (data && data.objects && data.objects.length > 0) {
          data.objects.forEach((rec) => {
            // keep a list of the times for lmax events so we can highlight on the track.
            // this._lmax_times.push(moment(rec.mtime).unix());
            this._lmax_times.push(this._unix(rec.mtime));
            _rmt_data[rec.rmt] = {
              'lmax': rec.lmax,
              'mtime': rec.mtime
            };
          });
          this._sharedService.publishRMTLmaxData(_rmt_data);
        }
      });
    } else {
      this._sharedService.publishRMTLmaxData();
    }
  }

 private updateNoiseMonitorData(): any {
   let stime = null;
   let etime = null;
   // Go through all the operations and get a time range that encompasses them all.  That way we can get
   // a single bucket of data to use for the RMT display.
   Object.keys(this._selectedOperationLayers).forEach((opnum: any) => {
     const op = this._selectedOperationLayers[opnum].get('operation');
     if (!stime) {
       stime = this._unix(op.stime);
       etime = this._unix(op.etime);
     } else {
       stime = Math.min(this._unix(op.stime), stime);
       etime = Math.max(this._unix(op.etime), etime);
     }
   });
   const stime_date = new Date(0);
   stime_date.setUTCSeconds(stime);

   const etime_date = new Date(0);
   etime_date.setUTCSeconds(etime);

   this._apiService.fetchRMTRange(stime_date.toISOString(),
     etime_date.toISOString()).subscribe((data: any) => {
       // Here we need to set data for the noise monitor layer.
       this._sharedService.publishRMTData(data);
     });
 }

  private getPointStyle(layer: Vector<any>): any {
    const adflag = layer.get('operation').adflag;
    const small_circle = new Circle({
      radius: 2,
      fill: new Fill({
        color: this._styleADFlag(adflag),
      })
    });
    const large_circle = new Circle({
      radius: 4,
      fill: new Fill({
        color: this._styleADFlag(adflag),
      })
    });
    const base_style = new Style({
      image: small_circle
    });
    return (feature, resolution) => {
      const es = feature.get('epoch_seconds');
      if (this._lmax_times.find(v => v === es)) {
        base_style.setImage(large_circle);
      } else {
        base_style.setImage(small_circle);
      }
      return base_style;
    };
  }


  /**
   * Update the layer with the provided geojson data.
   */
  public enableLayer(geojson_data) {
    this._geojsonSource = geojson_data;

    const layer = new VectorTileLayer({
      source: this._buildVectorSource(),
      properties: {
        noms_layer_label: this._nomsLayerLabel,
        selectable: this.selectInteractionSupport,
      }
    });
    const layer2 = new VectorTileLayer({
      source: this._buildVectorSource(),
      properties: {
        selectable: false,
      }
    });

    const style = this.getStyle(layer);
    layer.setStyle(style);
    layer2.setStyle(style);

    this.layer.setLayers(new Collection([layer, layer2]));
    this.publishOpsCounts(0);
  }


  private _buildVectorSource(): VectorTileSource {
    // Converts geojson-vt data to GeoJSON
    const replacer = function (key, value) {
      if (!value || !value.geometry) {
        return value;
      }

      let type;
      const rawType = value.type;
      let geometry = value.geometry;
      if (rawType === 1) {
        type = 'MultiPoint';
        if (geometry.length == 1) {
          type = 'Point';
          geometry = geometry[0];
        }
      } else if (rawType === 2) {
        type = 'MultiLineString';
        if (geometry.length == 1) {
          type = 'LineString';
          geometry = geometry[0];
        }
      } else if (rawType === 3) {
        type = 'Polygon';
        if (geometry.length > 1) {
          type = 'MultiPolygon';
          geometry = [geometry];
        }
      }

      return {
        'type': 'Feature',
        'geometry': {
          'type': type,
          'coordinates': geometry,
        },
        'properties': value.tags,
      };
    };

    const format = new GeoJSON({
      // Data returned from geojson-vt is in tile pixel units
      dataProjection: new Projection({
        code: 'TILE_PIXELS',
        units: 'tile-pixels',
        extent: [0, 0, 4096, 4096],
      }),
    });
    const tileIndex = geojsonvt(this._geojsonSource, {
      maxZoom: 24,
      extent: 4096,
      debug: 1
    });
    const map = this._map;
    const vtSource: VectorTileSource = new VectorTileSource({
      format: new GeoJSON(format),
      tileUrlFunction: function (tileCoord) {
        // Use the tile coordinate as a pseudo URL for caching purposes
        return JSON.stringify(tileCoord);
      },
      tileLoadFunction: function (tile: VectorTile, url) {
        const tileCoord = JSON.parse(url);
        const data = tileIndex.getTile(
          tileCoord[0],
          tileCoord[1],
          tileCoord[2]
        );
        const geojson = JSON.stringify(
          {
            type: 'FeatureCollection',
            features: data ? data.features : [],
          },
          replacer
        );
        const features = format.readFeatures(geojson, {
          extent: vtSource.getTileGrid().getTileCoordExtent(tileCoord),
          featureProjection: map.getView().getProjection(),
        });
        tile.setFeatures(features);
      }
    });

    return vtSource;
  }



  /**
   * Given a layer, return a style for that layer
   */
  public getStyle(layer): any {
    /**
     * Apply the runway filter operation.  Note that we could have mismatched
     * sets in some cases (like MSP/21D flight with 12L/22) so we
     * need to be careful to split them if needed and then check to see
     * whether the match is correct.
     *
     * In the case above, a filter for MSP/22 shouldn't match, since the
     * runway used was MSP/12L - meaning a simple "in" check would fail.
     */

    const stroke = new Stroke({
      color: '#ff6600',
      width: 1
    });
    const lineStyle = new Style({stroke: stroke});
    return (feature, resolution) => {
      const display_feature = this._filterFeature(feature.getProperties());
      if (display_feature) {
        const fill = this._styleADFlag(feature.get('adflag'));
        stroke.setColor(fill);
        if (this._selectedOperations.indexOf(feature.get('opnum')) > -1) {
          stroke.setWidth(1);
        } else {
          stroke.setWidth(1);
        }
        return [lineStyle];
      }
      // Dont' show anything.

      return [];
    };
  }

  /**
   * Converts ISO date to epoch seconds (to make Chander happy since I took his .unix() away)
   */
  private _unix(dt: any): number {
    const js_date = new Date(dt);
    const ms = js_date.valueOf();
    const epoch = Math.floor(ms / 1000);
    return epoch;
  }
}


