import {Component, OnInit} from '@angular/core';
import {SharedService} from '../shared.service';
import {layer_sidenav as LAYER_TEXT} from '../env-translate';
import {map_component as MAP_CONST} from '../env-constants';
import {map_component as MAP_TEXT} from '../env-translate';
import { first } from 'rxjs/operators';
import {MatListOption} from '@angular/material/list';


class Layer {
  label: string;
  src_url: string;
  active: boolean;
}

class Overlay {
  label: string;
  id: string;
  active: boolean;
  disabled: boolean;

  constructor(l: string, i: string) {
    this.label = l;
    this.id = i;
    this.disabled = false;
    this.active = false;
  }
}


@Component({
  selector: 'app-layer-sidenav',
  templateUrl: './layer-sidenav.component.html',
  styleUrls: []
})

export class LayerSidenavComponent implements OnInit {

  private _runwayOverlay: Overlay = new Overlay(LAYER_TEXT.RUNWAYS, 'runway');
  private _otherRunwayOverlay: Overlay = new Overlay(LAYER_TEXT.OTHER_RUNWAYS, 'other_runway');
  private _contourOverlay: Overlay = new Overlay(LAYER_TEXT.DNL_CONTOURS, 'contour');
  private _rmtOverlay: Overlay = new Overlay(LAYER_TEXT.REMOTE_MONITORING_TOWERS, 'rmt');
  private _radarOverlay: Overlay = new Overlay(LAYER_TEXT.NEXRAD, 'radar');
  private _tailsOverlay: Overlay = new Overlay(LAYER_TEXT.TAILS, 'tails');
  private _corridorsOverlay: Overlay = new Overlay(LAYER_TEXT.CORRIDORS, 'corridors');
  private _defaultBaseLayer: string;

  public LABEL_HEADER: string = LAYER_TEXT.LABEL_HEADER;
  public layers: Layer[] = [];
  public overlays: Overlay[] = [
    this._contourOverlay,
    this._runwayOverlay,
    this._otherRunwayOverlay,
    this._radarOverlay,
    this._rmtOverlay,
    this._tailsOverlay,
    this._corridorsOverlay
  ];

  private _layerHasBeenChangedByUser = false;

  constructor(private _sharedService: SharedService) {}

  ngOnInit() {
    this._sharedService.urlParsed$
      .pipe(first(data => data === true))
      .subscribe(
        data => {
          // We have a default layer defined in the url so we need to use it
          this._defaultBaseLayer = this._sharedService.pullBaseLayer();
          // Since it was defined in the url we should prevent any layer autoswitching from happening
          if (!!this._defaultBaseLayer) {
            this._layerHasBeenChangedByUser = true;
          }

          this._initializeLayersMenu();
          this._initializeServices();
        }
      );
    this._sharedService.autoChangeBaseLayers$.subscribe(
      data => {
        // The shared service just told us that it thinks we should change the baseLayer due to a time change
        // We need to see if the user has ever made a manual change or if the layer loaded from the url
        // If either of these are true then we won't change it
        // Otherwise we will change it by simulating a click on the radio button
        if (!this._layerHasBeenChangedByUser) {
          this.selectBaseLayer(data.url, true);
          // Now set the radio button, but first deactivate the current one
          this.layers.find(o => o.active).active = false;
          this.layers.find(o => o.src_url === data.url).active = true;
        }
      }
    );

  }

  private _initializeLayersMenu(): void {
    const layer_urls = MAP_CONST.BASE_LAYERS.map(o => o.url);
    const layer_labels = MAP_TEXT.LABEL_BASE_LAYERS;
    const layer_count = layer_urls.length;

    for (let x = 0, y = layer_count; x < y; x++) {
      this.layers.push({
        src_url: layer_urls[x],
        label: layer_labels[x],
        active: !!this._defaultBaseLayer ? this._defaultBaseLayer === layer_urls[x] : x === 0
      });
    }
  }

  private _initializeServices(): void {
    this._sharedService.radarActive$.subscribe(
      data => this._radarOverlay.active = data
    );
    this._sharedService.contoursActive$.subscribe(
      data => this._contourOverlay.active = data
    );
    this._sharedService.runwaysActive$.subscribe(
      data => this._runwayOverlay.active = data
    );
    this._sharedService.otherRunwaysActive$.subscribe(
      data => this._otherRunwayOverlay.active = data
    );
    this._sharedService.rmtActive$.subscribe(
      data => this._rmtOverlay.active = data
    );
    this._sharedService.radarDisabled$.subscribe(
      data => this._radarOverlay.disabled = data
    );
    this._sharedService.rmtDisabled$.subscribe(
      data => this._rmtOverlay.disabled = data
    );
    this._sharedService.corridorsDisabled$.subscribe(
      data => this._corridorsOverlay.disabled = data
    )
    this._sharedService.tailsActive$.subscribe(
        data => this._tailsOverlay.active = data
    );
    this._sharedService.corridorsActive$.subscribe(
      data => this._corridorsOverlay.active = data
    );
  }

  public selectBaseLayer(src_url: string, autoChange: boolean = false): void {
    /*
     * Sets the mapBaseLayer Observable which triggers a map change
     * This function is triggered when user clicks on a radio button
     */
    const label = this.layers.find(layer => layer.src_url === src_url).label;
    this._sharedService.publishMapBaseLayer(src_url);
    if (!autoChange) {
      this._layerHasBeenChangedByUser = true;
    }
    // Google Analytics
    window['gtag']('event', 'layers', {'event_category': 'General', 'event_label': label});
  }

  public selectOverlay(e: any): void {
    const cb: MatListOption = e['options'][0];
    const overlay_id = cb.value;
    const sel = cb.selected;

    if (overlay_id === 'contour') {
      this._sharedService.publishContours(sel);
    } else if (overlay_id === 'rmt') {
      this._sharedService.publishRmt(sel);
    } else if (overlay_id === 'radar') {
      this._sharedService.publishRadar(sel);
    } else if (overlay_id === 'runway') {
      this._sharedService.publishRunways(sel);
    } else if (overlay_id === 'other_runway') {
      this._sharedService.publishOtherRunways(sel);
    } else if (overlay_id === 'tails') {
      this._sharedService.publishTails(sel);
    } else if (overlay_id === 'corridors') {
      this._sharedService.publishCorridors(sel);
    }
    // Google Analytics
    window['gtag']('event', 'layers', {'event_category': 'General', 'event_label': overlay_id, 'value': !!sel ? 1 : 0});
  }

}
