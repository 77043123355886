import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';
import VectorTile from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import {BaseLayerService} from './base-layer.service';


export class AirportLayerService extends BaseLayerService {
  private style: any;
  protected layer: any;



  public getLayer() {
    /**
     * We only create one layer for the service, so handle that
     * here.
     */
    if (this.layer === undefined) {
      // Create a new layer.
      this.layer = new VectorTile({
        declutter: true,
        source: new VectorTileSource({
          format: new MVT({'layers': ['Airports']}),
          url: this._url,
          // renderMode: 'vector'
        }),
        updateWhileAnimating: true,
        visible: true,
        properties: {
          selectable: true
        }
      });
      this.style = this.getStyle(this.layer);
      this.layer.setStyle(this.style);
    }
    return this.layer;
  }

  /**
   * Given a layer, return a style for that layer
   */
  public getStyle(layer): any {
    const unselected = new Circle({
      fill: new Fill({'color': [100, 149, 237, .7]}),
      radius: 3,
      stroke: new Stroke({
        color: [237, 188, 100, .7],
        width: 1
      })
    });
    const selected = new Circle({
      fill: new Fill({'color': [237, 188, 100, .7]}),
      radius: 3,
      stroke: new Stroke({
        color: [100, 149, 237, .7],
        width: 1
      })
    });

    const style = new Style({
      image: unselected,
    });
    return (feature, resolution) => {
      if (feature.get('icao_code') === layer.get('icao_code')) {
        style.setImage(selected);
      } else {
        style.setImage(unselected);
      }
      return [style];
    };

  }
}
