import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { weather_app as WEATHER_TEXT } from '../env-translate';

@Component({
    selector: 'app-weather-info-dialog',
    templateUrl: './weather-info-dialog.component.html',
    styleUrls: ['./weather-info-dialog.component.scss']
})

export class WeatherInfoDialogComponent implements OnInit {

  public LABEL_WIND_SPEED = WEATHER_TEXT.LABEL_WIND_SPEED;
  public LABEL_TEMP = WEATHER_TEXT.LABEL_TEMP;
  public LABEL_PRESSURE = WEATHER_TEXT.LABEL_PRESSURE;
  public LABEL_LAST_UPDATED = WEATHER_TEXT.LABEL_LAST_UPDATED;
  public BUTTON_CLOSE = WEATHER_TEXT.BUTTON_CLOSE;
  public UNITS_TEMP_F = WEATHER_TEXT.UNITS_TEMP_F;
  public UNITS_TEMP_C = WEATHER_TEXT.UNITS_TEMP_C;
  public UNITS_PRESSURE = WEATHER_TEXT.UNITS_PRESSURE;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any) { }

  public weatherIcon = this.data.weatherIcon;
  public weatherTemp = this.data.weatherTemp;
  public weatherTempC = this.data.weatherTempC;
  public weatherLocation = this.data.weatherLocation;
  public currentCondition = this.data.currentCondition;
  public windSummary = this.data.windSummary;
  public observationTime = !!this.data.observationTime ? new Date(this.data.observationTime) : '';
  public windDeg = this.data.windDeg;
  public pressure = this.data.pressure;
  public arrowDeg = this.data.arrowDeg;


  ngOnInit() {
  }

}
