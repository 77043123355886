import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class WithCredentialsInterceptor implements HttpInterceptor {
  public HOSTNAME = environment.api_server;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // We only want to apply the withCredentials flag when making http calls to our own servers
    // If calling outside servers, such as mapbox, it will throw an error
    const request2 = request.clone({
        withCredentials: request.url.indexOf(this.HOSTNAME) > -1
    });
    return next.handle(request2);
  }
}
