import ImageLayer from 'ol/layer/Image';
import ImageWMSSource from 'ol/source/ImageWMS';
import {map_component as MAP_CONST} from '../../env-constants';

import {BaseLayerService} from './base-layer.service';


export class NexradLayerService extends BaseLayerService {
  protected layer: any;
  private _radarDateTimeString: string;
  public overlayId = 'nexrad';

  public time_enabled = true;


  public getLayer() {
    /**
     * We only create one layer for the service, so handle that here.
     * NEXRAD layers will always return an image, so if we don't want one we need to send a '0' in the time parameter
     * Reasons we wouldn't want one: first time NEXRAD is clicked on Animated replay - or - time is switched to 0 (ie 1970) when we change the replay type
     */
    if (this.layer === undefined) {
      // Create a new layer.
      this.layer = new ImageLayer({
        source: new ImageWMSSource({
          url: this._url,
          params: {
            layers: 'nexrad-n0q-wmst',
            transparent: true,
            format: 'image/png',
            time: this._radarDateTimeString || '0'
          },
          projection: MAP_CONST.PROJ_WGS84
        }),
        visible: true,
        zIndex: 0,
        properties: {
          overlayId: this.overlayId,
        },
        opacity: .4
      });
    }
    return this.layer;
  }


  private _createWeatherRadarDateString(dt): string {
    /*
     * Date format for nexrad needs to be YYYY-MM-DDTHH:MM ex.'2018-01-01T14:00'
     * We don't need seconds since the layers are only updated in 5 minute increments
     */
    const year = ___pad(dt.getUTCFullYear());
    const month = ___pad(dt.getUTCMonth() + 1); // UTC month starts at 0 so need to add 1
    const day = ___pad(dt.getUTCDate());
    const hours = ___pad(dt.getUTCHours());
    const minutes = ___pad(___calculateTimeFloor(dt.getUTCMinutes()));

    return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes;

    function ___pad(x: number): string {
      return x < 10 ? '0' + x : x.toString();
    }

    function ___calculateTimeFloor(x: number): number {
      /*
       * NEXRAD layers are only created every 5 minutes
       */
      const interval = MAP_CONST.WEATHER_RADAR_INTERVAL_IN_MINUTES;
      return Math.floor(x / interval) * interval;
    }
  }

  public updateBucketHoldCount(count: number): void {
    // Just a placeholder - this layer doesn't hold buckets, but is
    // time enabled so it needs to use the interface.
  }

  public updateDisplayTime(newDate: number): void {
    /*
     * Updates the weather radar overlay (currently using NexRad data)
     * Updates the radarDateTimeString which is the time parameter for the layers source
     * NEXRAD layers will always return an image, so if we don't want one we need to send a '0' in the time parameter
     * Reasons we wouldn't want one: first time NEXRAD is clicked on Animated replay - or - time is switched to 0 (ie 1970) when we change the replay type
     */
    let inputDate;
    let newDtString: string;
    // For epoch seconds we need to convert to a date object.
    if (newDate !== 0) {
      inputDate = new Date(0);
      inputDate.setUTCSeconds(newDate);
      newDtString = this._createWeatherRadarDateString(new Date(inputDate));
    } else {
      newDtString = '0';
    }

    if (newDtString !== this._radarDateTimeString) {
//      console.log('NexRad Info: ' + newDate + ' : ' + newDtString);
      const src = this.layer.getSource();
      src.updateParams({time: newDtString});
      this._radarDateTimeString = newDtString;
    }
  }
}
