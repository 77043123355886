import {Injectable} from '@angular/core';
import * as Sphere from 'ol/sphere';

import * as Qty from 'js-quantities';

@Injectable()
export class RouteUtilsService {

  // private wgs84sphere;

  constructor() {
    // this.wgs84sphere = new Sphere(6378137); // one of WGS84 earth radius'
  }



  /**
   * Given a geometry calculate it's length.
   */
  public computeGeomLength(geom: any, units: string, projection: string): string {
    const working_geom = geom.clone();
    working_geom.transform(projection, 'EPSG:4326');
    const length = Sphere.getLength(working_geom, {projection: 'EPSG:4326'});
    const len = new Qty(length, 'm');
    if (/^i/i.test(units)) {
      if (len.lt(Qty('1 mi'))) {
        return len.to('ft').toPrec('1 ft').toString();
      } else {
        return len.to('mi').toPrec('.01 mi').toString();
      }
    } else {
      if (len.lt(Qty('1 km'))) {
        return len.to('m').toPrec('.1 m').toString();
      } else {
        return len.to('km').toPrec('.01 km').toString();
      }
    }
  }

  /**
   * Given a geometry calculate it's length.
   */
  public computeGeom3DLength(geom: any, units: string, projection: string, altitude: number): string {
    const working_geom = geom.clone();
    working_geom.transform(projection, 'EPSG:4326');
    const length = Sphere.getLength(working_geom, {projection: 'EPSG:4326'});
    const length_3d = Math.sqrt(length ** 2 + altitude ** 2);
    const len = new Qty(length_3d, 'm');
    if (/^i/i.test(units)) {
      if (len.lt(Qty('1 mi'))) {
        return len.to('ft').toPrec('1 ft').toString();
      } else {
        return len.to('mi').toPrec('.01 mi').toString();
      }
    } else {
      if (len.lt(Qty('1 km'))) {
        return len.to('m').toPrec('.1 m').toString();
      } else {
        return len.to('km').toPrec('.01 km').toString();
      }
    }
  }

}
