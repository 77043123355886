import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {WebSocketSubject} from 'rxjs/webSocket';
import {first, distinctUntilChanged, filter} from 'rxjs/operators';
import {site_defaults as DEFAULTS, ft_api_urls as API_CONST, map_component as MAP_CONST} from './env-constants';
import {flight_toolbar as FLIGHT_TEXT, message_snackbar as MSG_TEXT} from './env-translate';

import {ApiService} from './api.service';
import {IdleService} from './idle.service';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';


class UrlParams {
  radar: boolean;
  rmt: boolean;
  contours: boolean;
  runways: boolean;
  otherrunways: boolean;
  corridors: boolean;
  tails: boolean;
  filters_a: string[];
  filters_r: string[];
  filters_t: string[];
  info: boolean;
  infochoices: string[];
  baselayer: string;
  replay: string;
  startdate: number;
  starttime: number;
  duration: number;
  playspeed: number;
  slider: number;
  center: number[];
}

class Clock {
  animatedCurrent: any;
  realtimeCurrent: any;
  staticStart: any;
  staticEnd: any;
}

class Weather {
  realtime: any[];
  animated: any[];

  constructor() {
    this.animated = [];
    this.realtime = [];
  }
}

class Center {
  center: number[];
  displayPin: boolean;

  constructor(c, dp) {
    this.center = c;
    this.displayPin = dp;
  }
}


@Injectable()

export class SharedService {

  private _urlParams = new UrlParams();
  private _storedDates: any[] = [];
  private _bucketHoldCount: number;
  private _clock = new Clock();
  private _weather = new Weather();
  private _reconnectTimer: any;
  private _wsConnected = true;
  private _rtTimer: any;
  private _timezone: string;
  private _userAuthenticatedStatus: boolean;
  private _mapboxAPIKey: string;
  private _mapDefaultExtent: string;
//  private _userInfo: any;
  private _currentDateTime: Date;

  // Observable sources
  private _sidenavClicked = new BehaviorSubject<string>(null);
  private _radarActive = new BehaviorSubject<boolean>(DEFAULTS.RADAR_ACTIVE);
  private _radarDisabled = new Subject<boolean>();
  private _rmtActive = new BehaviorSubject<boolean>(DEFAULTS.RMT_ACTIVE);
  private _rmtDisabled = new Subject<boolean>();
  private _contoursActive = new BehaviorSubject<boolean>(DEFAULTS.CONTOURS_ACTIVE);
  private _runwaysActive = new BehaviorSubject<boolean>(DEFAULTS.RUNWAYS_ACTIVE);
  private _corridorsActive = new BehaviorSubject<boolean>(DEFAULTS.CORRIDORS_ACTIVE);
  private _otherRunwaysActive = new BehaviorSubject<boolean>(DEFAULTS.OTHER_RUNWAYS_ACTIVE);
  private _tailsActive = new BehaviorSubject<boolean>(DEFAULTS.TAILS_ACTIVE);
  private _filtersAirline = new BehaviorSubject<string[]>([]);
  private _filtersRunway = new BehaviorSubject<string[]>([]);
  private _filtersType = new BehaviorSubject<string[]>([]);
  private _infoActive = new BehaviorSubject<boolean>(DEFAULTS.INFO_ACTIVE);
  private _infoChoices = new BehaviorSubject<string[]>(DEFAULTS.INFO_CHOICE_DEFAULT);
  private _centerMapClicked = new Subject<boolean>();
  private _chartsDisabled = new BehaviorSubject<boolean>(false);
  private _gateDisabled = new BehaviorSubject<boolean>(true);
  private _corridorsDisabled = new BehaviorSubject<boolean>(true);
  // Added this observable to communicate with the layer sidenav
  // We want to notify it when we THINK the layer should be automatically updated to a new one
  // But the layer nav should have the final say
  // Pass the layer object ({id, url, startHour}
  private _autoChangeBaseLayer = new Subject<any>();

  // Map driven observables
  private _mapPointDistanceGeom = new Subject<any[]>();
  private _mapGateGeom = new Subject<any[]>();
  private _mapMeasureGeom = new Subject<any[]>();
  private _mapTogglePointDistance = new BehaviorSubject<boolean>(false);
  private _mapToggleGate = new BehaviorSubject<boolean>(false);
  private _mapToggleMeasure = new BehaviorSubject<boolean>(false);
  private _mapPublishActiveData = new Subject<any>();
  private _mapFeaturePopupData = new Subject<any[]>();
  private _mapSelectedTrackRMTLmax = new Subject<any[]>();

  // Units to use
  private _units = 'imperial';

  // Replay Type on flight toolbar
  private _replay = new BehaviorSubject<string>(FLIGHT_TEXT[DEFAULTS.REPLAY_OPTIONS_DEFAULT]);
  // Start Date on flight toolbar
  private _startDate = new Subject<number>();
  // Start Time on flight toolbar
  private _startTime = new Subject<number>();
  // Duration field on flight toolbar
  private _duration = new Subject<number>();
  // Play speed on flight toolbar
  private _playSpeed = new Subject<number>();
  // Position of slider on flight toolbar
  private _slider = new Subject<number>();
  // [Lon, Lat, Zoom] of map
  private _mapCenter = new Subject<Center>();
  // Id of base layer currently in use
  private _mapBaseLayer = new Subject<string>();
  // Flag that is set to true when shared service has finished parsing the stateful url
  private _urlParsed = new BehaviorSubject<boolean>(false);
  // reset password key if the user clicked on the rp link
  private _rpKey = new Subject<string>();

  // Websocket related observables
  private _wsMapAPIKey = new Subject<string>();
  private _wsDefAirlineCodes = new Subject<string[]>();
  private _wsExtent = new Subject<string>();
  private _wsInterval = new Subject<number>();
  private _wsMinDelay = new Subject<number>();
  private _wsBucketHoldCount = new Subject<number>();
  private _wsCalMinDate = new Subject<any>();
  private _wsFlightsAndRmts = new Subject<any>();
  private _wsRMTsOnly = new Subject<any>();
  private _wsWeather = new Subject<Weather>();
  private _wsCacheDates = new Subject<any[]>();
  private _wsDataPullInProgress = new BehaviorSubject<boolean>(false);
  private _wsRealtimeEvent = new Subject<any>();
  private _wsStaticEvent = new Subject<any>();
  private _wsMinComplaintDate = new BehaviorSubject<string>((new Date()).toISOString());
  private _websocket: WebSocketSubject<any>;

  // Flight Tracker configuration data pulls
  // List of airports
  private _ftAirports = new Subject<any>();
  // List of runways
  private _ftRunways = new Subject<any>();
  // List of default airlines
  private _ftAirlines = new Subject<any>();

  // Calculated dates representing the CURRENT state of the system
  // Current date/time for Animated, Real Time and Static modes (ie data we are CURRENTLY displaying, used by clock)
  // Can be set to 0 as a sort of reset (used to hide features on map layers etc)
  private _systemCurrentDateTime = new Subject<any>();
  private _systemClock = new BehaviorSubject<Clock>(this._clock);
  private _systemCurrentHour = new BehaviorSubject<number>(99);

  // Messaging
  private _ftMessage = new Subject<any>();

  // Authentication
  private _userAuthenticated = new Subject<boolean>();
  private _userInfo = new BehaviorSubject<any>(null);
  // Used to launch the authentication dialog from components other than the user nav
  private _openAuthenticationDialog = new Subject<boolean>();

  // Observable streams
  sidenavClicked$ = this._sidenavClicked.asObservable();
  radarActive$ = this._radarActive.asObservable();
  radarDisabled$ = this._radarDisabled.asObservable();
  rmtActive$ = this._rmtActive.asObservable();
  rmtDisabled$ = this._rmtDisabled.asObservable();
  contoursActive$ = this._contoursActive.asObservable();
  runwaysActive$ = this._runwaysActive.asObservable();
  corridorsActive$ = this._corridorsActive.asObservable();
  otherRunwaysActive$ = this._otherRunwaysActive.asObservable();
  tailsActive$ = this._tailsActive.asObservable();
  filtersAirline$ = this._filtersAirline.asObservable();
  filtersRunway$ = this._filtersRunway.asObservable();
  filtersType$ = this._filtersType.asObservable();
  infoActive$ = this._infoActive.asObservable();
  infoChoices$ = this._infoChoices.asObservable();
  centerMapClicked$ = this._centerMapClicked.asObservable();
  replay$ = this._replay.asObservable();
  startDate$ = this._startDate.asObservable();
  startTime$ = this._startTime.asObservable();
  duration$ = this._duration.asObservable();
  playSpeed$ = this._playSpeed.asObservable();
  slider$ = this._slider.asObservable();
  mapCenter$ = this._mapCenter.asObservable();
  mapBaseLayer$ = this._mapBaseLayer.asObservable();
  urlParsed$ = this._urlParsed.asObservable();
  chartsDisabled$ = this._chartsDisabled.asObservable();
  gateDisabled$ = this._gateDisabled.asObservable();
  corridorsDisabled$ = this._corridorsDisabled.asObservable();
  autoChangeBaseLayers$ = this._autoChangeBaseLayer.asObservable();

  wsMapAPIKey$ = this._wsMapAPIKey.asObservable();
  wsDefAirlineCodes$ = this._wsDefAirlineCodes.asObservable();
  wsExtent$ = this._wsExtent.asObservable();
  wsInterval$ = this._wsInterval.asObservable();
  wsMinDelay$ = this._wsMinDelay.asObservable();
  wsBucketHoldCount$ = this._wsBucketHoldCount.asObservable();
  wsCalMinDate$ = this._wsCalMinDate.asObservable();
  wsFlightsAndRmts$ = this._wsFlightsAndRmts.asObservable();
  wsRMTsOnly$ = this._wsRMTsOnly.asObservable();
  wsRealtimeEvent$ = this._wsRealtimeEvent.asObservable();
  wsStaticEvent$ = this._wsStaticEvent.asObservable();
  wsWeather$ = this._wsWeather.asObservable();
  wsCacheDates$ = this._wsCacheDates.asObservable();
  wsDataPullInProgress$ = this._wsDataPullInProgress.asObservable();
  ftAirports$ = this._ftAirports.asObservable();
  ftRunways$ = this._ftRunways.asObservable();
  ftAirlines$ = this._ftAirlines.asObservable();
  wsMinComplaintDate$ = this._wsMinComplaintDate.asObservable();

  mapTogglePointDistance$ = this._mapTogglePointDistance.asObservable();
  mapToggleGate$ = this._mapToggleGate.asObservable();
  mapToggleMeasure$ = this._mapToggleMeasure.asObservable();

  mapPublishActiveData$ = this._mapPublishActiveData.asObservable();

  mapPointDistanceGeom$ = this._mapPointDistanceGeom.asObservable();
  mapGateGeom$ = this._mapGateGeom.asObservable();
  mapMeasureGeom$ = this._mapMeasureGeom.asObservable();
  mapFeaturePopupData$ = this._mapFeaturePopupData.asObservable();
  mapSelectedTrackRMTLmax$ = this._mapSelectedTrackRMTLmax.asObservable();

  systemCurrentDateTime$ = this._systemCurrentDateTime.asObservable();
  systemClock$ = this._systemClock.asObservable();
  // This is a behavior subject so it has a default value, the filter prevents the observable from triggering on that default value
  systemCurrentHour$ = this._systemCurrentHour.asObservable().pipe(filter(val => val !== 99));

  ftMessage$ = this._ftMessage.asObservable();

  userAuthenticated$ = this._userAuthenticated.asObservable();
  userInfo$ = this._userInfo.asObservable();
  openAuthenticationDialog$ = this._openAuthenticationDialog.asObservable();
  rpKey$ = this._rpKey.asObservable();

  constructor(
    private _apiService: ApiService,
    private _idleService: IdleService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    // Parse the site url for any query parameters
    // Use the router and wait until it indicates that at least one navigation has happened
    // Prevents it from triggering twice when you have query params in your url
    this._router.events
      .pipe(first(evt => this._router.navigated))
      .subscribe(evt => {
        this._route.queryParamMap
          .pipe(first())
          .subscribe(
          paramMap => this._parseQueryParameters(paramMap)
          );
      });
    // Get the bucket hold count and store it in memory instead of just in the observable
    this.wsBucketHoldCount$.pipe(first()).subscribe(
      data => this._bucketHoldCount = data
    );
    // Connect the websocket
    this._initializeWebsocket();
    // Pull some data we need for site initialization
    this._getFTConfigInfo();
    // Subscribe to the userIsIdle subject to handle the user timing out (we only care when they actually are timed out)
    this._idleService.userIsIdle
      .pipe(filter( data => data === true))
      .subscribe(data => this._disconnectWebsocket());
  }


  private _initializeWebsocket(): void {
    /*
     * Connect the websocket subscriber and make it hot
     * Sometimes the socket will return back-to-back realtime calls with the same start dates (thus the same data)
     * The distinctUntilChanged prevents the duplicate realtime call from updating the observable and triggering the subscribers
     */
    // console.log('Attempting websocket connection');
    this._websocket = new WebSocketSubject(['wss://', API_CONST.HOSTNAME, API_CONST.WS_PATH].join(''));

    this._websocket
      .pipe(distinctUntilChanged((p, q) => p['type'] === 'realtime_delayed' && q['type'] === 'realtime_delayed' && p['start'] === q['start']))
      .subscribe(
      (data) => {
        // WebSocket successfully returns data
        //        console.log('Got data');
        if (data['type'] === 'config') {
          this._publishWSConfig(data);
        } else if (data['type'] === 'historical') {
          this._publishWSDataSample(data);
        } else if (data['type'] === 'error') {
          this.publishFTMessage(data['error'], 'api');
        } else if (data['type'] === 'realtime_delayed') {
          this._publishRealtimeDelayedEventSample(data);
        } else if (data['type'] === 'static_tracks') {
          this._publishStaticEventSample(data);
        }
        this._wsDataPullInProgress.next(false);
        if (data['type'] !== 'error') {
          this._updateWebSocketStatus(true);
          if (this._idleService.pullUserIsIdle() === true) {
            // If the user was disconnected due to inactivity and they reconnect we need to start tracking the idle timer again
            this._idleService.startTracking();
          }
        }
      },
      (err) => {
        // WebSocket errors at the server
        this._updateWebSocketStatus(false);
      },
      () => {
        // WebSocket successfully closed, should only happen if the user is timed out
        console.log('WS CLOSE');
      }
      );
  }

  /**
   * Triggered by idle.service when the user is timed out
   */
  private _disconnectWebsocket(): void {
    this._websocket.unsubscribe();
    // Now update the connection status
    this._wsConnected = false;
    // Track the disconnect in google analytics
    window['gtag']('event', 'idle_timeout', {'event_category': this.pullReplayType()});
    // Publish the message notifying the user they have been logged out due to inactivity
    // and give them an option to reconnect
    this.publishFTMessage(MSG_TEXT.MSG_TIMED_OUT, 'timeout', {label: MSG_TEXT.LABEL_RECONNECT, callback: this._initializeWebsocket.bind(this)});
  }

  /**
   * Called by the websocket when connection is lost or regained
   * Updates the _wsConnected and _reconnectTimer
   * Also sends a message either declaring success or failure
   */
  private _updateWebSocketStatus(success: boolean): void {
    // We always want to run this if success is false since the user may have retried the connection manually and failed
    // The only time we care about success = true is if it was previously false, then we want to let them know they reconnected successfully
    if (success === false || success !== this._wsConnected) {
      // Always clear the timer first
      if (!!this._reconnectTimer) {
        clearTimeout(this._reconnectTimer);
      }
      if (success) {
        this.publishFTMessage(MSG_TEXT.MSG_CONNECT_SUCCESS, '', {label: '', callback: '_retryDataCall'});
      } else {
        this.publishFTMessage(MSG_TEXT.MSG_CONNECT_LOST, 'wsconnect', {label: MSG_TEXT.LABEL_RECONNECT, callback: this._initializeWebsocket.bind(this, true)});
        this._reconnectTimer = setTimeout(() => this._initializeWebsocket(), DEFAULTS.WS_RECONNECT_TIMER);
      }
      // Now update the connection status
      this._wsConnected = success;
    }
  }

  /**
   * Sends the websocket an authentication token
   * @param token - string
   */
//   public authenticateWS(token: string): void {
//       this._websocket.next({'operation': 'authenticate', 'token': token});
//   }

  /**
   * Receives real time data from the websocket and splits it into separate services
   * Data gets sent to _wsRealtimeEvent
   * Weather gets sent to _wsWeather - only publish weather when we HAVE weather
   * Start Date get sent to publishSystemClock
   */
  private _publishRealtimeDelayedEventSample(data: any): void {
    const start = new Date(data['start']);
    const end = new Date(data['end']);
    const o = data['operations'];
    const w = data['weather'];
    const r = data['rmts'];
    const t = data['tracks'];

    this._wsRealtimeEvent.next({'date_range': [start, end], 'operations': o, 'tracks': t, 'rmts': r});

    if (w.length > 0) {
      this.publishWeather('realtime', w);
    }

    // We need to update the system clock from here since the UI does not actively track realtime dates
    this.publishSystemClock('realtime', start);
    this.publishSystemCurrentDateTime(start);

    if (!!this._rtTimer) {
      clearTimeout(this._rtTimer);
    }
    // This timer runs for a set amount of time and if it completes that means that the websocket hasn't returned real time data in a while
    // If the system is still in real time mode then it will send a ping to the websocket to make sure it's still connected
    this._rtTimer = setTimeout(() => {
      const replay = this.pullReplayType();
      if (replay === FLIGHT_TEXT.REPLAY_OPTIONS_REALTIME) {
        this.pullRealtimeDelayedSample(true);
      }
    }, DEFAULTS.REALTIME_DISCONNECT_TIMER);
  }

  /**
   * Identifies which base layer should be used for the map
   * Triggered when the system hour changes
   * Layers are used within specified time ranges
   */
  private _manageBaseLayer(): void {
    const cur_hour = this._systemCurrentHour.value;
    const layers: any[] = MAP_CONST.BASE_LAYERS.filter(o => !!o.startHour).sort((a, b) => a.startHour < b.startHour ? -1 : 1);
    let cur_layer: any;
    // Use the time to figure out which base layer to use
    // The layer startHour defined in the config is in CST so we need to convert the cur_hour to CST from local time zone
    // before we do our comparisons
    // const now = moment.utc();
    const now = new Date();
    // const offset_local = moment.tz.zone(moment.tz.guess()).utcOffset(now) / 60;
    const offset_local = now.getTimezoneOffset() / 60;
    // const offset_central = moment.tz.zone('America/Chicago').utcOffset(now) / 60;
    const offset_central = this._getTZOffset(now, 'America/Chicago') / 60;
    const adj_cur_hour = cur_hour - (offset_central - offset_local);

    layers.forEach(
      layer_obj => {
        if (adj_cur_hour >= layer_obj.startHour && (!cur_layer || cur_layer.startHour < layer_obj.startHour)) {
          cur_layer = layer_obj;
        }
      }
    );

    // If we don't find a layer object then we need to pull the last one
    cur_layer = !cur_layer ? layers[layers.length - 1] : cur_layer;
    // Only publish if there was a change in layers
    if (cur_layer.url !== this._urlParams.baselayer) {
      this._autoChangeBaseLayer.next(cur_layer);
    }
  }

  /**
   * This function replaces the utcOffset() function in moment-timezone
   * @param dt
   * @param timezone
   * @private
   */
  private _getTZOffset(dt: Date, timezone: string): number {
    const getItem = function(format) {
      format.timeZone = timezone;
      return parseInt(dt.toLocaleString('en-US', format), 10);
    };

    const adjDate = new Date(
      getItem({year: 'numeric'}),
      getItem({month: 'numeric'}) - 1, // months are zero based
      getItem({day: 'numeric'}),
      getItem({hour: 'numeric', hour12: false}),
      getItem({minute: 'numeric'})
    );
    const noSecs = new Date(dt.getTime());
    noSecs.setSeconds(0, 0);
    const diff = Math.round((adjDate.getTime() - noSecs.getTime()) / 60000);
    return dt.getTimezoneOffset() - diff;
  }

  private _publishStaticEventSample(data: any): void {
    this._wsStaticEvent.next(data);
    // console.log('Static', data);
  }

  private _publishWSDataSample(data: any): void {
    const start = new Date(data['start']);
    const end = new Date(data['end']);
    const o = data['operations'];
    const w = data['weather'];
    const r = data['rmts'];
    const t = data['tracks'];
    this._wsFlightsAndRmts.next({'date_range': [start, end], 'operations': o, 'tracks': t, 'rmts': r});

    if (w.length > 0) {
      this.publishWeather('animated', w);
    }
    // Now update the array of start dates but only store the correct number based on the client_bucket_cache
    this._storedDates.push(start);
    if (this._storedDates.length > this._bucketHoldCount) {
      this._storedDates.shift();
    }
    this._wsCacheDates.next(this._storedDates);
  }

  public publishRMTData(data: any) {
    this._wsRMTsOnly.next({'rmts': data});
  }

  public publishRMTLmaxData(data?: any) {
    this._mapSelectedTrackRMTLmax.next(data);
  }


  private _publishWSConfig(data: any) {
    // Polygon which we can use to calculate the extent of the map
    const extent = data['operator']['extent'];
    // The amount of data in our animated data pulls in minutes (ie 15 minutes of data)
    const interval = data['interval_duration'];
    // The delay between our realtime data pull and the current actual real time in seconds (ie 600 seconds = 10 minutes)
    const minDelay = data['min_delay'];
    // The number of data buckets we should store before we start clearing the cache
    const bucketHoldCount = data['client_bucket_cache'];
    // The farthest date in the past that we can pull data for, should set the min date of our calendar
    const minDate = new Date(data['start_date']);
    // Mapbox API key
    const mapAPIKey = data['mapbox_api_key'];
    // ICAO codes for the airlines with the most traffic at the airport.  Sets our defaults for our airline filter list
    const defAirlineCodes = data['airline_filters'];
    // The last date that a user can create a complaint for (basic rule is the prev month until the 6th day of the current month)
    const minComplaintDate = data['min_complaint_date'];

    this._wsExtent.next(extent);
    this._wsInterval.next(interval);
    this._wsMinDelay.next(minDelay);
    this._wsBucketHoldCount.next(bucketHoldCount);
    this._wsCalMinDate.next(minDate);
    this._wsMapAPIKey.next(mapAPIKey);
    this._wsMinComplaintDate.next(minComplaintDate);
    // Need to also set a global value that we can store for later use (specifically the create account map needs it)
    this._mapboxAPIKey = mapAPIKey;
    // Need to set a global value for the default map extent for later use (again with the create account map)
    this._mapDefaultExtent = extent;
    if (defAirlineCodes !== undefined) {
      this._wsDefAirlineCodes.next(defAirlineCodes);
    }
  }

  /**
   *  Publishes a system wide message to the _ftMessage observable (currently tracked on the Flight Toolbar which displays the message in a snackbar)
   *  msgObj = {
   *  msg : the string we want displayed in the snackbar
   *  errType : api, wsconnect, http, timeout
   *  action? : {label: string, callback: string || function} for the snackbar action (if there is one)
   */
  public publishFTMessage(msg: string, errType: string, action?: any): void {
    this._ftMessage.next({
      msg: msg,
      errType: errType,
      action: action
    });
  }

  private _getFTConfigInfo(): void {
    /*
     * Calls to Chanders API to pull configuration information for the app
     * TimeZone, Airport Details, Runways and Default Airlines
     */
    this._apiService.getTimezone().subscribe(
      data => this._timezone = data['timezone']
    );

    this._apiService.callAirportDetails().subscribe(
      data => this._ftAirports.next(data['objects'])
    );

    this._wsDefAirlineCodes
      .pipe(first())
      .subscribe(
      codes => {
        if (codes) {
          this._apiService.callAirlinesIcao(codes).subscribe(
            airlines => this._ftAirlines.next(airlines['objects'])
          );
        }
      }
      );

    this._apiService.callRunways().subscribe(
      data => this._ftRunways.next(data['objects'])
    );
  }

  /**
   * type = 'realtime' or 'animated'
   * Publish the weather information
   * Weather is an array of weather objects containing:
   * epoch_seconds: number (1514033580)
   * id: number (1848870)
   * inm_low_wind
   * observation_time: string ("2017-12-23T12:53:00+00:00")
   * pressure_in: number (30.19)
   * runup_wind
   * temp_f: number (12)
   * weather: string ("A Few Clouds")
   * wind_degrees: number (280)
   * wind_dir: string ("West")
   * wind_mph: number(6)
   * windstring: string ("West at 5.8 MPH (5 KT)")
   */

  public publishWeather(type: string, weather?: any[]) {
    const tmp_weather = weather || [];
    if (type === 'realtime' || type === 'realtime_delayed') {
      this._weather['realtime'] = tmp_weather;
    } else {
      this._weather['animated'] = tmp_weather;
    }
    this._wsWeather.next(this._weather);
  }

  public publishSidenav(data: string): void {
    this._sidenavClicked.next(data);
  }

  public publishRadar(data: boolean): void {
    /*
     * Toggles the active state of the radar button and layer
     * Also sets the appropriate url parameter
     */
    this._urlParams.radar = data;
    this._radarActive.next(data);
  }

  public publishRadarDisabled(data: boolean): void {
    /*
     * Enable/Disable the radar button
     * Should also set radar to inactive if disabled
     */
    this._radarDisabled.next(data);
    if (data === true) {
      this.publishRadar(false);
    }
  }

  public publishRmt(data: boolean): void {
    /*
     * Toggles the active state of the rmt button and layer
     * Also sets the appropriate url parameter
     */
    this._urlParams.rmt = data;
    this._rmtActive.next(data);
  }

  public publishRmtDisabled(data: boolean): void {
    /*
     * Enable/Disable the rmt button
     * Should also set rmt to inactive if disabled
     */
    this._rmtDisabled.next(data);
    if (data === true) {
      this.publishRmt(false);
    }
  }

  public publishContours(data: boolean): void {
    this._urlParams.contours = data;
    this._contoursActive.next(data);
  }

  public publishRunways(data: boolean): void {
    this._urlParams.runways = data;
    this._runwaysActive.next(data);
  }

  public publishOtherRunways(data: boolean): void {
    this._urlParams.otherrunways = data;
    this._otherRunwaysActive.next(data);
  }

  public publishCorridors(data: boolean): void {
    this._urlParams.corridors = data;
    this._corridorsActive.next(data);
  }

  public publishTails(data: boolean): void {
      this._urlParams.tails = data;
      this._tailsActive.next(data);
  }

  public publishFiltersAirline(data: string[]): void {
    this._urlParams.filters_a = data;
    this._filtersAirline.next(data);
  }

  public publishFiltersRunway(data: string[]): void {
    this._urlParams.filters_r = data;
    this._filtersRunway.next(data);
  }

  public publishFiltersType(data: string[]): void {
    this._urlParams.filters_t = data;
    this._filtersType.next(data);
  }

  /**
   * Returns an array of strings with the filter values for the filter_type passed
   * filter_type<string>: airline, runway, type
   */
  public pullFilters(filter_type: string): string[] {
    let vals: string[];
    if (filter_type === 'airline') {
      vals = this._urlParams.filters_a;
    } else if (filter_type === 'runway') {
      vals = this._urlParams.filters_r;
    } else {
      vals = this._urlParams.filters_t;
    }

    return vals || [];
  }

  public publishInfo(data: boolean): void {
    this._urlParams.info = data;
    this._infoActive.next(data);
  }

  public publishInfoChoices(data: string[]): void {
    this._urlParams.infochoices = data;
    this._infoChoices.next(data);
  }

  public publishCenterMapClicked(data: boolean): void {
    this._centerMapClicked.next(data);
  }

  public publishChartsDisabled(data: boolean): void {
    this._chartsDisabled.next(data);
  }

  public publishGateDisabled(data: boolean): void {
    /*
     * Enables/Disables the gate button
     * Should also set the gate layer to inactive if disabled
     */
    this._gateDisabled.next(data);
    if (data === true) {
      this.publishMapGate(false);
    }
  }

  public publishCorridorsDisabled(data: boolean): void {
    /*
     * Enables/Disables the corridor layer checkbox
     * Should also set the corridor layer to inactive if disabled
     */
    this._corridorsDisabled.next(data);
    if (data === true) {
      this.publishCorridors(false);
    }
  }

  public publishReplay(data: string): void {
    this._urlParams.replay = data;
    this._replay.next(data);
  }

  public publishStartDate(data: number): void {
    this._urlParams.startdate = data;
    this._startDate.next(data);
  }

  public publishStartTime(data: number): void {
    this._urlParams.starttime = data;
    this._startTime.next(data);
  }

  public publishDuration(data: number): void {
    this._urlParams.duration = data;
    this._duration.next(data);
  }

  public publishPlaySpeed(data: number): void {
    this._urlParams.playspeed = data;
    this._playSpeed.next(data);
  }

  public publishSlider(data: number): void {
    this._urlParams.slider = data;
    this._slider.next(data);
  }

  public publishActiveData(data: any): void {
    this._mapPublishActiveData.next(data);
  }

  /**
   * Update the center property only, does not trigger the service
   * Used by the map to prevent circular loop
   * Array contains lon, lat and zoom
   */
  public updateMapCenter(data: number[]): void {
    this._urlParams.center = data;
  }

  /**
   * Update the center property and trigger the service
   * Array contains lon, lat and zoom
   * displayPin? is only passed to the service and let's the consumer know if a pin should be added to the map or not (default is false)
   */
  public publishMapCenter(data: number[], displayPin?: boolean): void {
    const pin = displayPin || false;
    this.updateMapCenter(data);
    this._mapCenter.next(new Center(data, pin));
  }

  public publishMapBaseLayer(data: string): void {
    this._urlParams.baselayer = data;
    this._mapBaseLayer.next(data);
  }

  public publishCacheDates(dates: any[]): void {
    this._wsCacheDates.next(dates);
  }

  // Publish geometries for map object data.
  public publishMapPointDistanceGeom(geometries: any[]): void {
    this._mapPointDistanceGeom.next(geometries);
  }

  public publishMapGateGeom(geometries: any[]): void {
    this._mapGateGeom.next(geometries);
  }

  public publishMapMeasureGeom(geometries: any[]): void {
    this._mapMeasureGeom.next(geometries);
  }

  public publishMapMeasure(enable: boolean): void {
    this._mapToggleMeasure.next(enable);
  }

  public publishMapPointDistance(enable: boolean): void {
    this._mapTogglePointDistance.next(enable);
  }

  public publishMapGate(enable: boolean): void {
    this._mapToggleGate.next(enable);
  }

  public publishMapFeaturePopupData(data: any[]): void {
    this._mapFeaturePopupData.next(data);
  }

  public publishSystemCurrentDateTime(date: any): void {
    /*
     * Date/Time of the data that is currently being displayed
     * Updated by Flight Toolbar but consumed by Clock
     */
    this._currentDateTime = date;
    this._systemCurrentDateTime.next(date);
    if (!!date) {
      this._publishSystemCurrentHour(date.getHours());
    }
  }

  /**
   * The current hour, only updates if it changes
   */
  private _publishSystemCurrentHour(hour: number): void {
    if (hour !== this._systemCurrentHour.value) {
      this._systemCurrentHour.next(hour);
      this._manageBaseLayer();
    }
  }

  public publishSystemClock(replayMode: string, curDT: any, endDT?: any): void {
    /*
     * Publish changes to the current time so our clock stays up to date
     * Need to store separate times for each replay mode since the layer data is kept even when not in that mode
     * Static mode is the only mode with an end date so endDT is optional
     */

    if (replayMode === 'animated') {
      this._clock.animatedCurrent = curDT;
    } else if (replayMode === 'realtime' || replayMode === 'realtime_delayed') {
      this._clock.realtimeCurrent = curDT;
    } else {
      this._clock.staticStart = curDT;
      this._clock.staticEnd = endDT;
    }
    this._systemClock.next(this._clock);
  }

  public getUnits(): string {
    return this._units;
  }

  public getMapAPIKey(): string {
      return this._mapboxAPIKey;
  }

  public getMapDefaultExtent(): string {
      return this._mapDefaultExtent;
  }

  public pullUrlParams(): UrlParams {
    /*
     * Returns a UrlParams object containing values for each possible query parameter
     */
    return this._urlParams;
  }

  public pullSystemClock(): Clock {
    /*
     * Returns the system clock used to track times for Animated, Realtime and Static replays
     */
    return this._clock;
  }

  public pullInfoChoices(): string[] {
    /*
     * Returns the selected choices in the flight information menu
     */
    const choices = this._urlParams.infochoices;
    return choices;
  }

  public pullBaseLayer(): string {
    /*
     * Returns the selected base layer
     */
    const layer = this._urlParams.baselayer;
    return layer;
  }

  /**
   * Returns the selected replay type form the flight toolbar
   */
  public pullReplayType(): string {
    const replay = this._urlParams.replay;
    return replay;
  }

  public pullDataSample(start_time: any): void {
    /*
     * Pulls a range of data for animated tracks
     * Will pull data ranging from the start time to the end of the default interval
     * Start Time must be a multiple of the interval (ie multiple of 5 minutes, 15 minutes, etc)
     */
//    console.log('Data pull start time = ' + start_time);
    this._wsDataPullInProgress.next(true);
    this._websocket.next({'operation': 'data_sample', 'start_time': start_time});
  }

  public pullRealtimeDelayedSample(subscribe: boolean): void {
    /*
     * Starts or stops the realtime process
     * When in realtime mode, the websocket will continuously send data until the mode is changed
     * Since we are not actively polling for realtime data we don't need to set the _wsDataPullInProgress flag
     * subscribe = true means start, subscribe = false means stop
     */
    this._websocket.next({'operation': subscribe ? 'realtime_delayed' : 'unsubscribe_realtime'});
    // Track the realtime play in Google Analytics if subscribe == true
    if (!!subscribe) {
      window['gtag']('event', 'play', {'event_category': 'Real Time Delayed'});
    }
  }

  public pullStaticTracks(start_time: any, end_time: any): void {
    /*
     * Given a start and end time, will pull static tracks for that range
     */
    this._wsDataPullInProgress.next(true);
    this._websocket.next({'operation': 'static_tracks', 'start_time': start_time, 'end_time': end_time});
    // Google Analytics
    window['gtag']('event', 'play', {'event_category': 'Static'});
  }

  public pullTimezone(): string {
    return this._timezone;
  }

  private _parseQueryParameters(paramMap: ParamMap): void {
    /*
     * Parses stateful urls
     * Pulls query parameters from the route url and sets the appropriate observables
     */
    // radar overlay - &radar=boolean
    if (paramMap.has('radar')) {
      const radar = paramMap.get('radar').toLowerCase() === 'true';
      this.publishRadar(radar);
    }
    // rmt overlay - &rmt=boolean
    if (paramMap.has('rmt')) {
      const rmt = paramMap.get('rmt').toLowerCase() === 'true';
      this.publishRmt(rmt);
    }
    // contours overlay - &contours=boolean
    if (paramMap.has('contours')) {
      const contours = paramMap.get('contours').toLowerCase() === 'true';
      this.publishContours(contours);
    }
    // airport runways overlay - &runways=boolean
    if (paramMap.has('runways')) {
      const runways = paramMap.get('runways').toLowerCase() === 'true';
      this.publishRunways(runways);
    }
    // other runways overlay - &otherrunways=boolean
    if (paramMap.has('otherrunways')) {
      const other_runways = paramMap.get('otherrunways').toLowerCase() === 'true';
      this.publishOtherRunways(other_runways);
    }
    // corridors overlay - &corridors=boolean
    if (paramMap.has('corridors')) {
      const corridors = paramMap.get('corridors').toLowerCase() === 'true';
      this.publishCorridors(corridors);
    }
    // airplane tails overlay - &tails=boolean
    if (paramMap.has('tails')) {
        const tails = paramMap.get('tails').toLowerCase() === 'true';
        this.publishTails(tails);
    }
    // filters - &filters_a=[comma separated list of strings]
    if (paramMap.has('filters_a')) {
      const filters_a = paramMap.get('filters_a');
      // We want to avoid blank values
      if (!!filters_a) {
        this.publishFiltersAirline(filters_a.split(','));
      }
    }
    // filters - &filters_r=[comma separated list of strings]
    if (paramMap.has('filters_r')) {
      const filters_r = paramMap.get('filters_r');
      // We want to avoid blank values
      if (!!filters_r) {
        this.publishFiltersRunway(filters_r.split(','));
      }
    }
    // filters - &filters_t=[comma separated list of strings]
    if (paramMap.has('filters_t')) {
      const filters_t = paramMap.get('filters_t');
      // We want to avoid blank values
      if (!!filters_t) {
        this.publishFiltersType(filters_t.split(','));
      }
    }
    // flight information - &info=boolean
    if (paramMap.has('info')) {
      const info = paramMap.get('info').toLowerCase() === 'true';
      this.publishInfo(info);
    }
    // flight information choices - &infochoices=[comma separated list of strings]
    if (paramMap.has('infochoices')) {
      // Don't publish infochoices if it's blank...there always needs to be a default value selected
      const tempInfoChoices = paramMap.get('infochoices');
      if (!!tempInfoChoices) {
        this.publishInfoChoices(paramMap.get('infochoices').split(','));
      }
    }
    // base layer - &baselayer=string
    if (paramMap.has('baselayer')) {
      this.publishMapBaseLayer(paramMap.get('baselayer'));
    }
    // replay type - &replay=string
    if (paramMap.has('replay')) {
      this.publishReplay(paramMap.get('replay'));
    }
    // start date (static/animated replay) - &startdate=number
    if (paramMap.has('startdate')) {
      this.publishStartDate(Number(paramMap.get('startdate')));
    }
    // start time (static/animated replay) - &starttime=number
    if (paramMap.has('starttime')) {
      this.publishStartTime(Number(paramMap.get('starttime')));
    }
    // duration (static replay) - &duration=number
    if (paramMap.has('duration')) {
      this.publishDuration(Number(paramMap.get('duration')));
    }
    // play speed (animated replay) - &playspeed=number
    if (paramMap.has('playspeed')) {
      this.publishPlaySpeed(Number(paramMap.get('playspeed')));
    }
    // slider position (animated replay)  - &slider=number
    // We don't currently track this because of how often the slider updates the service
    // But this code is here in case we decide to do it in the future
    if (paramMap.has('slider')) {
      this.publishSlider(Number(paramMap.get('slider')));
    }
    // map center - &center=number,number,number (lon, lat, zoom)
    if (paramMap.has('center')) {
      const center = paramMap.get('center').split(',');
      const new_center = center.map(data => Number(data));
      this.publishMapCenter(new_center, false);
    }
    // reset password - &rp=string
    if (paramMap.has('rp')) {
        this.publishRPKey(paramMap.get('rp'));
        // Removes rp from the url
        this._router.navigate([], {queryParams: {'rp': null}, queryParamsHandling: 'merge'});
    }
    // Set our flag showing the url has been parsed
    this._urlParsed.next(true);
  }



  /**
   *
   * @param info = {
        admin: false
        authenticated: true
        email: "johnboy@abc.com"
        id: 10
        last_login: "2019-08-01T19:33:14.018392+00:00"
        last_login_failure: "2019-07-31T00:32:41.736288+00:00"
        name: "John Boy"
        password_change_date: "2019-07-31T00:36:22.886168+00:00"
   }
   */

  private _publishUserInfo(info: any): void {
    const newStatus = info.authenticated;
    // Only publish if the value has changed
    if (newStatus !== this._userAuthenticatedStatus) {
        this._userAuthenticatedStatus = newStatus;
        // Also need to update the email address and name
        this._userInfo.next(newStatus ? info : null);
        // Let's set a snackbar message
        const statusMsg = (newStatus ? 'Login' : 'Logout') + ' successful';
//        this.publishFTMessage(statusMsg, null);
        this._userAuthenticated.next(newStatus);
    }
  }

  // If you want to get the user info as a one off instead of subscribing
  public getUserInfo(): string {
    return this._userInfo.getValue();
  }

  /*
   * Return the user status that we have recorded instead of making an api call or subscribing
   * When you just want a one-off value without having to subscribe to anything
   */
  public getUserStatus(): boolean {
    return this._userAuthenticatedStatus;
  }

  /*
   * Calls the userStatus API and then updates the userAuthenticated service and updates the user info
   * Don't monitor this, just call it and listen to userAuthenticated or userInfo
   */
  public pollUserStatus(): void {
    this._apiService.getUserStatus().subscribe(
      data => {
        this._publishUserInfo(data);
      }
    );
  }

  /**
   * Notifies the system that a rest password link was opened
   * @param rpKey - string that's passed in the url when the user clicks on the link in the reset pasword email
   */
  public publishRPKey(rpKey: string): void {
      this._rpKey.next(rpKey);
  }

  /**
   * Used to notify the user sidenav that it needs to authenticate the user
   * Ex: User tries to enter a complaint but is not logged in, call this to open the authentication dialog
   * Sends TRUE always
   */
  public publishOpenAuthenticationDialog(): void {
      this._openAuthenticationDialog.next(true);
  }


}
