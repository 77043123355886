import { Component, OnInit } from '@angular/core';
import { help_component as HELP_TEXT } from '../env-translate';
import { help_component as HELP_CONST } from '../env-constants';


@Component({
  selector: 'app-help-sidenav',
  templateUrl: './help-sidenav.component.html',
  styleUrls: ['./help-sidenav.scss']
})

export class HelpSidenavComponent implements OnInit {

  constructor() { }

  public LABEL_HEADER = HELP_TEXT.LABEL_HEADER;
  public introText?: string = HELP_TEXT.INTRO_TEXT;
  public helpTabs: object[];

  ngOnInit() {
    this._initHelpTabs();
  }

  private _initHelpTabs() {
    const tabsTemplate = HELP_CONST.TABS;

    for (let x = 0, y = tabsTemplate.length; x < y; x++ ) {
      const tab = tabsTemplate[x];
      tab.label = HELP_TEXT[tab.label];
      for (let a = 0, b = tab.content.length; a < b; a++) {
        const tmp = tab.content[a];
        tmp['text'] = [];
        tmp.textRefIds.map(
          textRefId => tmp['text'].push(HELP_TEXT[textRefId])
        );
      }
    }

    this.helpTabs = tabsTemplate;
  }

}
