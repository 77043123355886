import {flight_adflag_colors as FLIGHT_COLORS} from '../../env-constants';
import {BaseTimeEnabledLayerService} from './base-time-enabled-layer.service';

export class BaseOperationLayerService extends BaseTimeEnabledLayerService {
  protected _filters: any = {};
  protected style: any;
  protected colors = FLIGHT_COLORS;

  public toggleSelectedOperation(opnum: number): number[] {
    const index = this._selectedOperations.indexOf(opnum);
    if (index === -1) {
      this._selectedOperations.push(opnum);
    } else {
      this._selectedOperations.splice(index, 1);
    }
    this._markLayersChanged();
    return this._selectedOperations;
  }

  public isSelectedOperation(opnum: number): boolean {
    const index = this._selectedOperations.indexOf(opnum);
    if (index === -1) {
      return false;
    }
    return true;
  }

  public addSelectedOperation(opnum: number): number[] {
    const index = this._selectedOperations.indexOf(opnum);
    if (index === -1) {
      this._selectedOperations.push(opnum);
      this._markLayersChanged();
    }
    return this._selectedOperations;
  }

  public removeSelectedOperation(opnum: number): number[] {
    const index = this._selectedOperations.indexOf(opnum);
    if (index > -1) {
      this._selectedOperations.splice(index, 1);
      this._markLayersChanged();
    }
    return this._selectedOperations;
  }

  public clearSelectedOperations() {
    this._selectedOperations.length = 0;
    this._markLayersChanged();
  }


  public setFilters(filter: any): void {
    this._filters = filter;
  }

  public setAirlineFilter(filters: any[]): void {
    this.layer.set('airline_filter', filters);
    this._markLayersChanged();
  }




  /**
   * So the filters for D and A should include D/A flights, so
   * we handle that here as well.
   */
  public setADFlagFilter(filters: any[]): void {
    const filter_strings = [];
    if ('A' in filters || 'D' in filters) {
      filter_strings.push('D/A');
    }
    filters.forEach(data => {
      filter_strings.push(data);
    });

    this.layer.set('adflag_filter', filter_strings);
    this._markLayersChanged();
  }

  /**
   * Combine the runway and airport here to make matching easier.
   */
  public setRunwayFilter(filters: any[]): void {
    const filter_strings = [];
    filters.forEach(data => {
      filter_strings.push(data.join('/'));
    });
    this.layer.set('runway_filter', filter_strings);
    this._markLayersChanged();
  }

  /**
   * Takes an array of operations and filters them using _filterFeature
   */
  public filterData(data: any[]): any[] {
    const filtered_data = data.filter(op => {
      return this._filterFeature(op);
    });

    return filtered_data;
  }

  /**
   * Apply filters based on a feature
   */
  protected _filterFeature(operation_details: any): boolean {
    const adflag_filter = this.layer.get('adflag_filter') || [];
    const airline_filter = this.layer.get('airline_filter') || [];
    const runway_filter = this.layer.get('runway_filter') || [];
    if ((adflag_filter.length === 0 || adflag_filter.indexOf(operation_details.adflag) > -1) &&
      (airline_filter.length === 0 || airline_filter.indexOf(operation_details.airline) > -1) &&
      (runway_filter.length === 0 || this._filterRunways(operation_details, runway_filter))) {
      return true;
    }
    return false;

  }

  /**
   * Get the color to use based on the adflag value.
   */
  protected _styleADFlag(adflag: string): string {
    const colorMap = this.colors[adflag || 'O'];
    return this._baseLayerColorConversion(colorMap);
  }


  /**
       * Apply the runway filter operation.  Note that we could have mismatched
       * sets in some cases (like MSP/21D flight with 12L/22) so we
       * need to be careful to split them if needed and then check to see
       * whether the match is correct
       *
       * In the case above, a filter for MSP/22 shouldn't match, since the
       * runway used was MSP/12L - meaning a simple "in" check would fail.
       */
  protected _filterRunways(operation: any, runway_filter: any[]): boolean {
    const ap_rwys = [];
    if (/\//.test(operation.airport)) {
      const aps = operation.airport.split('/');
      const rways = operation.runway.split('/');
      ap_rwys.push([aps[0], rways[0]].join('/'));
      ap_rwys.push([aps[1], rways[1]].join('/'));
    } else {
      ap_rwys.push([operation.airport, operation.runway].join('/'));
    }
    let found = false;
    ap_rwys.some(rwy => {
      // We currently have an issue where the data could contain a leading 0 for some runway numbers
      // IE MSP/4 can also be stored in the data as MSP/04
      // We need to remove any leading 0's from the runway number before we apply our filter to it
      rwy = rwy.indexOf('/0') > -1 ? rwy.replace('/0', '/') : rwy;
      found = (runway_filter.indexOf(rwy) > -1);
      // For Realtime operations the runway is often undefined.  Handle this by
      // matching operations with no defined runway with just the airport (any runway)
      // this way we show the flight with the airport, but don't filter by the runway
      // since we don't know what it is.
      if (rwy.endsWith('/')) {
        runway_filter.some((rec) => {
          found = rec.startsWith(rwy);
          return found;
        });
      }
      return found;
    });
    return found;
  }


}
