import {SharedService} from '../../shared.service';
import {ApiService} from '../../api.service';
import {BaseAnimatedLayerService} from './base-animated-layer.service';

export class RealtimeLayerService extends BaseAnimatedLayerService {
  protected _nomsLayerLabel = 'realtime';

  constructor(_map: any, _url: string, _bucketHoldCount: number,
    protected _sharedService: SharedService,
    protected _apiService: ApiService) {
    super(_map, _url, _bucketHoldCount, _sharedService, _apiService);
    this._bucketHoldCount = this._tailTimeSeconds;
  }

  public updateBucketHoldCount(bucket_hold_count: number): void {
    // Ignore for realtime always use the tail count
  }
}
