import {BaseLayerService} from './base-layer.service';

export class BaseTimeEnabledLayerService extends BaseLayerService {

  protected layer: any;
  public time_enabled = true;
  protected _currentTime: number;



  //     constructor(map: any, url: string, bucket_count: number) {
  //        super(map, url, bucket_count);
  //    }


  public updateBucketHoldCount(bucket_hold_count: number): void {
    this._bucketHoldCount = bucket_hold_count;
  }

  // A placeholder for layers that might want to publish counts.
  protected publishOpsCounts(current_time: number) {}

  public updateDisplayTime(epoch_seconds: number): void {
    this._currentTime = epoch_seconds;
    this.layer.set('current_time', epoch_seconds);
    this.publishOpsCounts(epoch_seconds);
    this._markLayersChanged();
  }



  protected _markLayersChanged(): void {
    /**
     * Mark the layer group as well as all child layers as changed.
     */
    this.layer.changed();
    if (this.layer.getLayers) {
      this.layer.getLayers().forEach(layer => {
        layer.changed();
      });
    }
  }

}
