import {Component, OnInit} from '@angular/core';
import {info_sidenav as INFO_TEXT} from '../env-translate';
import {site_defaults as DEFAULTS, attribute_names as A_NAMES} from '../env-constants';
import {SharedService} from '../shared.service';
import { first } from 'rxjs/operators';

class InfoChoice {
  label: string;
  value: string;
  selected: boolean;
  constructor(l: string, v: string, s?: boolean) {
    this.label = l;
    this.value = v;
    this.selected = s || false;
  }
}

@Component({
  selector: 'app-info-sidenav',
  templateUrl: './info-sidenav.component.html',
  styleUrls: []
})

export class InfoSidenavComponent implements OnInit {

  public LABEL_HEADER = INFO_TEXT.LABEL_HEADER;
  public LABEL_TOGGLE = INFO_TEXT.LABEL_TOGGLE;
  public flight_info_active: boolean = DEFAULTS.INFO_ACTIVE;
  public info_choices: InfoChoice[] = [];
  private _defaultChoiceIndex: number;

  constructor(private _sharedService: SharedService) {
    // Just need a one time listener to see if we have infoActive in the stateful url
    this._sharedService.urlParsed$
      .pipe(first(data => data === true))
      .subscribe(
      data => {
        const urlParams = this._sharedService.pullUrlParams();
        if (urlParams.info !== undefined) {
          this.flight_info_active = urlParams.info;
        }
        // Create the checkboxes for each info choice
        this._createInfoChoices(urlParams.infochoices || DEFAULTS.INFO_CHOICE_DEFAULT);
      }
      );
  }

  ngOnInit() {
  }

  private _createInfoChoices(selectedChoices: string[]): void {
    /*
     * Loop through the attribue_names object and get the key and name for each child object so we can create
     * our check boxes.  Only create check boxes for attributes where menuoption = true
     */
    let key: string,
      choice: InfoChoice,
      defChoice: InfoChoice;

    for (key in A_NAMES) {
      if (A_NAMES.hasOwnProperty(key)) {
        const obj = A_NAMES[key];
        if (obj.menuoption) {
          choice = new InfoChoice(obj.name, key, selectedChoices.indexOf(key) > -1);
          this.info_choices.push(choice);
          if (key === DEFAULTS.INFO_CHOICE_DEFAULT[0]) {
            defChoice = choice;
          }
        }
      }
    }
    // Now sort the choices alphabetically
    this.info_choices.sort(
      (a, b): number => a.label < b.label ? -1 : 1
    );
    // Get the index of the first default value
    this._defaultChoiceIndex = this.info_choices.indexOf(defChoice);
  }

  public onChoiceChange(e: any) {
    /*
     * Triggered when a flight information choice is checked/unchecked
     * This event is actually tied to the selection list, NOT the list option (important distinction)
     * e = {option: any (option that has been changed), source: any (the selection list object)}
     */
    // Grab the list of selected options from the selection list, get their values and update the shared service
    const selected_options = e['source']['selectedOptions']['selected'];
    let sel_option_values: string[] = [];
    const options = e['source']['options']['_results'];
    let option: any;

    if (!selected_options.length) {
      // If nothing is selected then we need to find the default option and select it
      options[this._defaultChoiceIndex].toggle(); // This physically toggles the checkbox
      sel_option_values = DEFAULTS.INFO_CHOICE_DEFAULT.slice();
    } else {
      for (option of selected_options) {
        sel_option_values.push(option['value']);
      }
    }
    this._sharedService.publishInfoChoices(sel_option_values);
  }

  public onToggleChange(e: any) {
    /*
     * Triggered when the mat-slide-toggle is changed
     * Activates/Deactivates the flight information display over each plane
     * e = {checked: boolean, source: any (toggle object)}
     */
    this.flight_info_active = e['checked'];
    this._sharedService.publishInfo(this.flight_info_active);
  }

}
