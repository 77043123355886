import {Injectable} from '@angular/core';
import {site_defaults as DEFAULTS} from './env-constants';
import {Observable, Subject, Subscription, fromEvent, merge} from 'rxjs';
import {timeout} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class IdleService {

  public userIsIdle = new Subject<boolean>();

  private _userIsIdle: boolean;
  private SESSION_TIMEOUT: number = DEFAULTS.SESSION_TIMEOUT_IN_MINUTES * 60 * 1000;
  private _touchListener$: Observable<any>;
  private _resizeListener$: Observable<any>;
  private _mouseListener$: Observable<any>;
  private _keyboardListener$: Observable<any>;
  private _eventListener$: Observable<any>;

  private _idleTracker: Subscription;

  constructor() {

    this._touchListener$ = fromEvent(window, 'touchstart');
    this._resizeListener$ = fromEvent(window, 'resize');
    this._mouseListener$ = fromEvent(window, 'mousemove');
    this._keyboardListener$ = fromEvent(document, 'keydown');
    this._eventListener$ = merge(this._touchListener$, this._resizeListener$, this._mouseListener$, this._keyboardListener$);
    this.startTracking();

  }

  /**
   * Start the event tracking to monitor user activity
   */
  public startTracking(): void {
    this._updateIdleStatus(false);
    this._idleTracker = this._eventListener$
      .pipe(timeout(this.SESSION_TIMEOUT))
      .subscribe(
      value => {},
      err => this._triggerTimeout()
      );
  }

  public pullUserIsIdle(): boolean {
    return this._userIsIdle;
  }

  /**
   * User has timed out so set the userIsIdle flag to true
   */
  private _triggerTimeout(): void {
    // console.log('timed out');
    this._updateIdleStatus(true);
  }

  private _updateIdleStatus(status: boolean): void {
    this._userIsIdle = status;
    this.userIsIdle.next(this._userIsIdle);
  }
}
