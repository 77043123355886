import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../shared.service';
import { menu_icons as ICONS_TEXT, main_sidenav as MAIN_TEXT } from '../env-translate';
import { site_defaults as DEFAULTS } from '../env-constants';

class MenuItem {
    id: string;
    label: string;
    icon?: string;
    disabled: boolean;
    active: boolean;
    toggle: boolean;
}

class MenuSection {
    items: MenuItem[];
    supportMini: boolean;
}

@Component({
    selector: 'app-main-sidenav',
    templateUrl: './main-sidenav.component.html',
    styleUrls: []
})

export class MainSidenavComponent implements OnInit {
    @Input() mini?: boolean;

    // public LABEL_HEADER = MAIN_TEXT.LABEL_HEADER;
    public LABEL_HEADER = '';
    public MENU: MenuSection[];
    public selectedFilterCount = 0;
    private _filterCountAirline = 0;
    private _filterCountRunway = 0;
    private _filterCountType = 0;


    constructor(private _sharedService: SharedService) { }

    ngOnInit() {
        const LAYERS = {
            id: 'layers',
            label: ICONS_TEXT.LAYERS,
            icon: 'map',
            active: false,
            disabled: false,
            toggle: false,
        };
        const FILTERS = {
            id: 'filters',
            label: ICONS_TEXT.FILTER_FLIGHTS,
            icon: 'filter-outline',
            active: false,
            disabled: false,
            toggle: false,
        };
        const FLIGHT_INFO = {
            id: 'info',
            label: ICONS_TEXT.FLIGHT_INFORMATION,
            icon: 'information-variant',
            active: false,
            disabled: false,
            toggle: false,
        };
        const CENTER = {
            id: 'center',
            label: ICONS_TEXT.CENTER,
            icon: 'image-filter-center-focus-weak',
            active: false,
            disabled: false,
            toggle: true,
        };

        const PIN = {
            id: 'pin',
            label: ICONS_TEXT.PIN,
            icon: 'pin',
            active: false,
            disabled: false,
            toggle: true,
        };
        const GATE = {
            id: 'gate',
            label: ICONS_TEXT.GATE,
            icon: 'vector-line',
            active: false,
            disabled: false,
            toggle: true,
        };
        const MEASURE = {
            id: 'measure',
            label: ICONS_TEXT.MEASURE,
            icon: 'ruler',
            active: false,
            disabled: false,
            toggle: true,
        };
        const CURRENT_LOCATION = {
            id: 'current_location',
            label: ICONS_TEXT.CURRENT_LOCATION,
            icon: 'crosshairs-gps',
            active: false,
            disabled: false,
            toggle: false,
        };

        const COPY_URL = {
            id: 'copy_url',
            label: ICONS_TEXT.COPY_URL,
            icon: 'link-variant',
            active: false,
            disabled: false,
            toggle: false,
        };

        const CHARTS = {
            id: 'charts',
            label: ICONS_TEXT.CHARTS,
            icon: 'chart-histogram',
            active: false,
            disabled: false,
            toggle: true,
        };
        const LEGEND = {
            id: 'legend',
            label: ICONS_TEXT.LEGEND,
            icon: 'shape-outline',
            active: false,
            disabled: false,
            toggle: false,
        };
        const HELP = {
            id: 'help',
            label: ICONS_TEXT.HELP,
            icon: 'help',
            active: false,
            disabled: false,
            toggle: false,
        };
        const CONTACT_US = {
            id: 'contact_us',
            label: ICONS_TEXT.CONTACT_US,
            icon: 'email',
            active: false,
            disabled: false,
            toggle: false,
        };
        const COMPLAINT = {
            id: 'complaint',
            label: ICONS_TEXT.COMPLAINT_FORM,
            icon: 'message-text',
            active: false,
            disabled: false,
            toggle: false,
        };

        const MACNOISE = {
            id: 'macnoise_link',
            label: ICONS_TEXT.MAC_NOISE_SITE,
            active: false,
            disabled: false,
            toggle: false,
        };

        const USERACCOUNT = {
            id: 'user_account',
            label: ICONS_TEXT.ACCOUNT_SETTINGS,
            icon: 'account-settings',
            active: false,
            disabled: false,
            toggle: false,
        };

        const OVERLAY_SECTION: MenuSection = {
            supportMini: true,
            items: [LAYERS, FILTERS, FLIGHT_INFO]
        };

        const LOCATION_SECTION: MenuSection = {
            supportMini: true,
            items: [CURRENT_LOCATION, PIN, CENTER]
        };

        const TOOLS_SECTION: MenuSection = {
            supportMini: true,
            items: [GATE, MEASURE]
        };

        const INFO_SECTION: MenuSection = {
            supportMini: true,
            items: [COPY_URL, CHARTS, LEGEND, HELP]
        };

        const USER_SECTION: MenuSection = {
            supportMini: true,
            items: [CONTACT_US, COMPLAINT, USERACCOUNT]
        }

        const LINKS_SECTION: MenuSection = {
            supportMini: false,
            items: [MACNOISE]
        };

        this.MENU = [OVERLAY_SECTION, LOCATION_SECTION, TOOLS_SECTION, INFO_SECTION, USER_SECTION, LINKS_SECTION];

        this._sharedService.chartsDisabled$.subscribe(
            data => CHARTS.disabled = data
        );

        this._sharedService.gateDisabled$.subscribe(
            data => GATE.disabled = data
        );

        this._sharedService.mapToggleGate$.subscribe(
            data => {
                GATE.active = data;
                if (data === true) {
                    // Turn off PIN if Gate is activated
                    this._sharedService.publishMapPointDistance(false);
                    // Turn off MEASURE if Gate is activated
                    this._sharedService.publishMapMeasure(false);
                }
            }
        );

        this._sharedService.mapTogglePointDistance$.subscribe(
            data => {
                PIN.active = data;
                if (data === true) {
                    // Turn off GATE if PIN is activated
                    this._sharedService.publishMapGate(false);
                    // Turn off MEASURE if Gate is activated
                    this._sharedService.publishMapMeasure(false);
                }
            }
        );

        this._sharedService.mapToggleMeasure$.subscribe(
            data => {
                MEASURE.active = data;
                if (data === true) {
                    // Turn off GATE if PIN is activated
                    this._sharedService.publishMapGate(false);
                    // Turn off PIN if Gate is activated
                    this._sharedService.publishMapPointDistance(false);
                }
            }
        );

        this._sharedService.filtersAirline$.subscribe(
            data => {
                this._filterCountAirline = data.length;
                this._updateSelFilterCount();
            }
        );

        this._sharedService.filtersRunway$.subscribe(
            data => {
                this._filterCountRunway = data.length;
                this._updateSelFilterCount();
            }
        );

        this._sharedService.filtersType$.subscribe(
            data => {
                this._filterCountType = data.length;
                this._updateSelFilterCount();
            }
        );
    }


    public callAction(item: MenuItem): void {
        /*
         * Called when a menu button is clicked
         * Perform an action based on the buttons id
         * Disabled buttons won't trigger (buttons are disabled in the HTML)
         * If toggle = true then we need to set the active state of the button to the new state
         */
        const newActiveState = item.toggle ? !item.active : item.active;
        const sidenavs = ['layers', 'filters', 'help', 'charts', 'info', 'legend', 'user_account'];
        const clickThenClose = ['current_location', 'center', 'gate', 'pin', 'measure'];

        // Google Analytics
        window['gtag']('event', item.id, { 'event_category': 'General' });

        if (sidenavs.indexOf(item.id) > -1) {
            // Menu items that open side navs
            this._sharedService.publishSidenav(item.id);
        } else if (item.id === 'complaint') {
            // If the user is not logged in then we need to open the authentication dialog
            if (!this._sharedService.getUserStatus()) {
                this._sharedService.publishOpenAuthenticationDialog();
            } else {
                // Open the sidenav
                this._sharedService.publishSidenav(item.id);
            }
        } else if (item.id === 'current_location') {
            this._findCurrentLocation();
        } else if (item.id === 'print') {
            window.print();
        } else if (item.id === 'copy_url') {
            this._createStatefulUrl();
        } else if (item.id === 'center') {
            this._sharedService.publishCenterMapClicked(true);
        } else if (item.id === 'gate') {
            this._sharedService.publishMapGate(newActiveState);
        } else if (item.id === 'pin') {
            this._sharedService.publishMapPointDistance(newActiveState);
        } else if (item.id === 'measure') {
            this._sharedService.publishMapMeasure(newActiveState);
        } else if (item.id === 'contact_us') {
            window.open(DEFAULTS.CONTACT_US_HREF);
        } else if (item.id === 'macnoise_link') {
            window.open(DEFAULTS.MACNOISE_HREF);
        } else {
            alert(item.label);
        }

        if (clickThenClose.indexOf(item.id) > -1) {
            this._closeLargeMainMenu();
        }
    }

    private _findCurrentLocation(): void {
        /*
         * Gets the users current geolocation from their web browser and displays it on the map
         */
        navigator.geolocation.getCurrentPosition(
            pos => {
                const coords = pos['coords'];
                const lat = coords['latitude'];
                const lon = coords['longitude'];
                const zoom = 18;
                this._sharedService.publishMapCenter([lon, lat, zoom], true);
            }
        );
    }

    private _createStatefulUrl(): void {
        /*
         * Builds the stateful url
         */
        const keyVals = this._sharedService.pullUrlParams();
        const baseUrl = location.href.split('?')[0];
        let qryParams = '';
        let vals;
        let tmpVal;
        let isArray = false;

        for (const key of Object.keys(keyVals)) {
            vals = keyVals[key];
            isArray = Array.isArray(vals);
            tmpVal = isArray ? vals.join(',') : vals;
            // We don't want to add parameters for blank or empty values
            if (!!tmpVal) {
                qryParams += '&' + key + '=' + tmpVal;
            }
        }

        copyText(baseUrl + '?' + encodeURI(qryParams));
        this._sharedService.publishFTMessage(MAIN_TEXT.SNACKBAR_MSG, '');

        function copyText(text: string) {
            // Create the textarea input to hold our text.
            const element = document.createElement('textarea');
            element.value = text;
            // Add it to the document so that it can be focused.
            document.body.appendChild(element);
            // Focus on the element so that it can be copied.
            element.focus();
            element.setSelectionRange(0, element.value.length);
            // Execute the copy command.
            document.execCommand('copy');
            // Remove the element to keep the document clear.
            document.body.removeChild(element);
        }
    }

    private _updateSelFilterCount() {
        this.selectedFilterCount = this._filterCountAirline + this._filterCountRunway + this._filterCountType;
    }

    /**
     * If the main menu is opened in max mode we should shrink it back down to mini mode
     * This is necessary for functions like Drop a Pin, Gate and Measure
     * Can only be used for one click functions like Center Map and Find my Current Location
     */
    private _closeLargeMainMenu(): void {
        if (!this.mini) {
            // Simulate user clicking the main menu close button
            this._sharedService.publishSidenav('main');
        }
    }

}
