import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import VectorTile from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import {BaseLayerService} from './base-layer.service';


export class RunwayLayerService extends BaseLayerService {
  private style: any;
  protected layer: any;
  public reload_on_change = true;
  public nomsLayerLabel = 'runways';


  public getLayer() {
    /**
     * We only create one layer for the service, so handle that
     * here.
     */
    if (this.layer === undefined) {
      // Create a new layer.
      this.layer = new VectorTile({
        declutter: true,
        source: new VectorTileSource({
          format: new MVT({'layers': ['Runways']}),
          url: this._url
        }),
        updateWhileAnimating: true,
        // noms_layer_label: this.nomsLayerLabel
      });
      this.style = this.getStyle(this.layer);
      this.layer.setStyle(this.style);
    }
    return this.layer;
  }



  public setRunwayFilter(filters: any[]): void {
    const filter_strings = [];
    filters.forEach(data => {
      filter_strings.push(data.join('/'));
    });
    this.layer.set('runway_filter', filter_strings);
    this._markLayersChanged();

  }

  protected _markLayersChanged(): void {
    /**
     * Mark the layer group as well as all child layers as changed.
     */
    this.layer.changed();
    if (this.layer.getLayers) {
      this.layer.getLayers().forEach(layer => {
        layer.changed();
      });
    }
  }


  public handleSelectInteraction(evt) {
    // Nothing here yet.
    // console.log('Need to get this implemented!');
  }

  /**
   * Given a layer, return a style for that layer
   */
  public getStyle(layer): any {
    const stroke = new Stroke({
      color: '#6699cc',
      width: 2
    });
    const lineStyle = new Style({stroke: stroke});
    return (feature, resolution) => {
      // A runway is displayed if:
      //  1. There are no runway filters
      //  2. The runway has been added as a filter
      //  3. The Unknown filter has been added for the runways airport
      const runway_filters = layer.get('runway_filter') || [];
      if (feature.get('other') === false &&
        (runway_filters.length === 0 ||
        runway_filters.indexOf([feature.get('local_code'), feature.get('runway_code')].join('/')) > -1 ||
        runway_filters.indexOf([feature.get('local_code'), ''].join('/')) > -1)
      ) {
        return lineStyle;
      }
      return [];
    };
  }
}
