import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../shared.service';
import { header_component as HDR_TEXT } from '../env-translate';
import { header_component as HDR_CONST } from '../env-constants';

@Component({
  selector: 'app-sidenav-header',
  template: `
    <mat-toolbar [color]="color" class="sidebar-header">
      <IMG id="logo" alt="{{ALT_TEXT_LOGO}}" src="{{URL_LOGO}}" />
      <h2 class="title">{{title}}</h2>
      <button mat-icon-button attr.aria-label="{{ARIA_LABEL_CLOSE_BUTTON}}" (click)="sendId()">
        <mat-icon fontSet="mdi" [fontIcon]="'mdi-' + icon" style="font-size: 24px;"></mat-icon>
      </button>
    </mat-toolbar>
  `,
  styles: [
    '#logo { width:100px; height: 100px; }',
    '.sidebar-header { height: 130px; }',
    '.sidebar-header .title { flex: 1 1 auto; padding-left: 16px; margin: 0;}'
  ]
})

export class SidenavHeaderComponent implements OnInit {
  @Input() color: string;
  @Input() title: string;
  @Input() icon: string;
  private _sidenavId: string;

  public ALT_TEXT_LOGO = HDR_TEXT.ALT_TEXT_LOGO;
  public ARIA_LABEL_CLOSE_BUTTON = HDR_TEXT.ARIA_LABEL_CLOSE_BUTTON;
  public URL_LOGO = HDR_CONST.URL_LOGO;

  constructor(private _sharedService: SharedService) { }

  ngOnInit() {
    // Subscribe to the sidenavClicked Observable so we know which sidenavId we need
    this._sharedService.sidenavClicked$.subscribe(
      data => {
        this._sidenavId = data;
      }
    );
  }

  public sendId(): void {
    /*
     * Sends the menuid back to the observable in the service when the user clicks the close icon
     */
    this._sharedService.publishSidenav(this._sidenavId);
  }

}
