/**
 * Eventually, the plan is to have different environment files and manage
 * settings there, but for now we use this file to set the server
 * where the RESTful API resides.  This should be copied to local_settings.ts
 * and modified as needed.  The local_settings.ts is deployment specific,
 * so that file is ignored in git, and this file is stored.
 */
import { environment } from '../environments/environment';
// export const HOSTNAME = 'api-qa.macnoms.com';
export const HOSTNAME = environment.api_server;
