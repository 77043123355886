import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MaterialModule} from './material.module';

import {AppComponent} from './app.component';
import {SidenavHeaderComponent} from './sidenav-header/sidenav-header.component';
import {MainSidenavComponent} from './main-sidenav/main-sidenav.component';
import {HelpSidenavComponent} from './help-sidenav/help-sidenav.component';
import {LayerSidenavComponent} from './layer-sidenav/layer-sidenav.component';
import {FilterSidenavComponent} from './filter-sidenav/filter-sidenav.component';
import {ChartSidenavComponent} from './chart-sidenav/chart-sidenav.component';
import {InfoSidenavComponent} from './info-sidenav/info-sidenav.component';
import {LegendSidenavComponent} from './legend-sidenav/legend-sidenav.component';
import {UserSidenavComponent} from './user-sidenav/user-sidenav.component';
import {ComplaintSidenavComponent} from './complaint-sidenav/complaint-sidenav.component';
import {SearchToolbarComponent} from './search-toolbar/search-toolbar.component';
import {FlightToolbarComponent} from './flight-toolbar/flight-toolbar.component';
import {MapComponent} from './map/map.component';
import {WeatherInfoDialogComponent} from './weather-info-dialog/weather-info-dialog.component';
import {GateInfoDialogComponent} from './gate-info-dialog/gate-info-dialog.component';
import {FlightCounterComponent} from './flight-counter/flight-counter.component';
import {FeatureOverlayComponent} from './feature-overlay/feature-overlay.component';
import {FeatureBottomsheetComponent} from './feature-bottomsheet/feature-bottomsheet.component';
import {AuthenticationDialogComponent} from './authentication-dialog/authentication-dialog.component';
import {AddressHelpDialogComponent} from './address-help-dialog/address-help-dialog.component';
import {CreateAccountDialogComponent} from './create-account-dialog/create-account-dialog.component';
import {WithCredentialsInterceptor} from './with-credentials-interceptor';

import {ApiService} from './api.service';
import {SharedService} from './shared.service';
import {IdleService} from './idle.service';

// The timepicker module
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { PlotlyViaWindowModule } from 'angular-plotly.js';

const routes: Routes = [
  {path: '', component: AppComponent}
];

@NgModule({
    declarations: [
        AppComponent,
        SidenavHeaderComponent,
        MainSidenavComponent,
        HelpSidenavComponent,
        LayerSidenavComponent,
        FilterSidenavComponent,
        ChartSidenavComponent,
        InfoSidenavComponent,
        LegendSidenavComponent,
        UserSidenavComponent,
        ComplaintSidenavComponent,
        SearchToolbarComponent,
        FlightToolbarComponent,
        MapComponent,
        WeatherInfoDialogComponent,
        GateInfoDialogComponent,
        FlightCounterComponent,
        FeatureOverlayComponent,
        FeatureBottomsheetComponent,
        AuthenticationDialogComponent,
        CreateAccountDialogComponent,
        AddressHelpDialogComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MaterialModule,
        RouterModule.forRoot(routes),
        NgxMaterialTimepickerModule,
        PlotlyViaWindowModule,
    ],
    providers: [
        ApiService,
        SharedService,
        IdleService,
        { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true },
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule {}
