import {Component, OnInit, EventEmitter} from '@angular/core';
import {ApiService} from '../api.service';
import {SharedService} from '../shared.service';
import {address_help as HELP_TEXT} from '../env-translate';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-address-help-dialog',
  templateUrl: './address-help-dialog.component.html',
  styleUrls: []
})
export class AddressHelpDialogComponent implements OnInit {
  form: FormGroup;
  public openAuthDialog = new EventEmitter<string>();
  public HELP_TEXT: any = HELP_TEXT;
  public sending = false;


  constructor(
    private _fb: FormBuilder,
    private _apiService: ApiService,
    private _sharedService: SharedService,
    private _dialogRef: MatDialogRef<AddressHelpDialogComponent>
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      'email': ['', [Validators.required, Validators.email]],
      'address': ['', Validators.required]
    });

  }

  /**
   * Getter for the email form control
   */
  get email() {
    return this.form.get('email');
  }

  get address() {
    return this.form.get('address');
  }

  /**
   * Submits the form values to the API and processes any issues that are returned
   * If no issues then close the dialog
   */
  public submitForm(): any {
    // Set the progress bar to display
    this.sending = true;
    // Add the call to the API here
    this._apiService.requestHelp({
      email: this.email.value,
      address: this.address.value
    }).subscribe(
      () => {
        this._sharedService.publishFTMessage(HELP_TEXT.MSG_SUCCESS, null);
        this._dialogRef.close(true);
      },
      () => {
        this._sharedService.publishFTMessage(HELP_TEXT.MSG_ERROR, null);
        // Turn off the progress bar
        this.sending = false;
      }
    );
  }

}


