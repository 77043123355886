import { Component, OnInit } from '@angular/core';
import { legend_sidenav as LEGEND_TEXT, filter_sidenav as AD_TEXT, aircraft_opertype as AIRCRAFT_TEXT } from '../env-translate';
import { gate_chart_adflag_colors as COLORS, aircraft_icons as ICONS } from '../env-constants';

@Component({
  selector: 'app-legend-sidenav',
  templateUrl: './legend-sidenav.component.html',
  styleUrls: ['./legend-sidenav.component.css']
})
export class LegendSidenavComponent implements OnInit {

  public TEXT = LEGEND_TEXT;
  public tab1Content: any[];
  public tab2Content: any[];
  constructor() { }

  ngOnInit() {
    this._createTab1Content();
    this._createTab2Content();
  }

  /**
   * Generate the content for the first tab (colors)
   * {label: string, color: string}[]
   */
  private _createTab1Content(): void {
    this.tab1Content = [
      {label: AD_TEXT.LABEL_ARRIVALS, color: COLORS['A'] },
      {label: AD_TEXT.LABEL_DEPARTURES, color: COLORS['D'] },
      {label: AD_TEXT.LABEL_ARRIVING_AND_DEPARTING, color: COLORS['A/D'] },
      {label: AD_TEXT.LABEL_UNKNOWN, color: COLORS['O'] }
    ];
  }

  /**
   * Generate the content for the second tab (icons)
   * {label: string, icon: string}
   */
  private _createTab2Content(): void {
    this.tab2Content = [
      {label: AIRCRAFT_TEXT['C'] + ' - ' + LEGEND_TEXT.TEXT_NARROWBODY, icon: ICONS['C']['NARROWBODY']},
      {label: AIRCRAFT_TEXT['C'] + ' - ' + LEGEND_TEXT.TEXT_RJ, icon: ICONS['C']['RJ']},
      {label: AIRCRAFT_TEXT['C'] + ' - ' + LEGEND_TEXT.TEXT_WIDEBODY, icon: ICONS['C']['WIDEBODY']},
      {label: AIRCRAFT_TEXT['H'], icon: ICONS['H']},
      {label: AIRCRAFT_TEXT['J'], icon: ICONS['J']},
      {label: AIRCRAFT_TEXT['M'], icon: ICONS['M']},
      {label: AIRCRAFT_TEXT['P'], icon: ICONS['P']},
      {label: AIRCRAFT_TEXT['T'], icon: ICONS['T']},
      {label: AIRCRAFT_TEXT['B'], icon: ICONS['B']},
      {label: AIRCRAFT_TEXT['U'], icon: ICONS['*']},
    ];
  }
}
