import {Component, OnInit} from '@angular/core';
import {SharedService} from '../shared.service';
import {flight_counter as FC_TEXT, flight_toolbar as TOOLBAR_TEXT} from '../env-translate';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-flight-counter',
  templateUrl: './flight-counter.component.html',
  styleUrls: ['./flight-counter.component.scss']
})

export class FlightCounterComponent implements OnInit {

  public mapActiveData: any;
  public replayType: string;
  public REPLAY_STATIC: string = TOOLBAR_TEXT.REPLAY_OPTIONS_STATIC;
  public LABEL_ACTIVE: string = FC_TEXT.LABEL_ACTIVE;
  public LABEL_DISPLAYED: string = FC_TEXT.LABEL_DISPLAYED;
  public LABEL_NO_DATA: string = FC_TEXT.LABEL_NO_DATA;

  constructor(private _sharedService: SharedService) {
    this._sharedService.replay$.subscribe(
      data => this.replayType = data
    );

    /*
     * Only change the mapActiveData if either the active or displayed values are different than their previous values
     */
    this._sharedService.mapPublishActiveData$
      .pipe(distinctUntilChanged(null, data => [data.active, '-', data.displayed].join()))
      .subscribe(
      data => {
        // returns an object {active: number, displayed: number }
        this.mapActiveData = data;
      }
      );
  }

  ngOnInit() {
  }

}
