import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { ApiService } from '../api.service';
import { user_sidenav as LABEL_TEXT, authentication as AUTH_TEXT } from '../env-translate';
import {AuthenticationDialogComponent} from '../authentication-dialog/authentication-dialog.component';
import {CreateAccountDialogComponent} from '../create-account-dialog/create-account-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-user-sidenav',
  templateUrl: './user-sidenav.component.html',
  styleUrls: ['./user-sidenav.component.css']
})
export class UserSidenavComponent implements OnInit {

    public LABEL_TEXT = LABEL_TEXT;
    public user_authenticated = false;
    private _openComplaintSidenav = false;

    constructor(
        private _sharedService: SharedService,
        private _apiService: ApiService,
        private _dialog: MatDialog,
    ) { }

    ngOnInit() {
        this._callServices();
    }

    private _callServices(): void {
        // Subscribe to the user authenticated service so we're up to date with the users authentication status
        this._sharedService.userAuthenticated$.subscribe(
            data => this.user_authenticated = data
        );
        // Listen for the password reset service
        this._sharedService.rpKey$.subscribe(
            rpKey => this._createFirstDialog(rpKey)
        );
        // Listen for requests to open the authentication dialog from other components
        this._sharedService.openAuthenticationDialog$.subscribe(
            () => {
                // We want the complaint sidenav to open if they successfully log in
                this._openComplaintSidenav = true;
                this.changeAuthentication();
            }
        );
    }

    public createAccount(): void {
        this.openCreateAccountDialog();
    }

    public changePassword(): void {
        this.openChildDialog('change');
    }

    public requestPasswordChange(): void {
        this.openChildDialog('request');
    }

    public changeAuthentication(): void {
        if (this.user_authenticated) {
            // Google Analytics
            window['gtag']('event', 'user_account', { 'event_category': 'Logout' });
            this._apiService.logoutUser().subscribe(
                () => {
                    // This passes back a value but we don't really care, just poll the status
                    this._sharedService.pollUserStatus();
                    this._sharedService.publishFTMessage(AUTH_TEXT.SUCCESS_MSGS['LOGOUT'], null);
                }
            );
        } else {
            this._createFirstDialog();
        }
    }

  /**
   * Create the login dialog (or reset password dialog) for the user
   */
  private _createFirstDialog(rpKey?: string): void {
    // If an rpKey was passed, but it's blank, then the users token expired and we need to notify them
    // To do this we will open a slightly modified version of the request dialog
    const pType = typeof rpKey !== 'undefined' ? (rpKey !== '' ? 'reset' : 'request') : 'login';
    const dialogRef = this._dialog.open(AuthenticationDialogComponent, {
      width: '400px',
      data: {'type': pType, 'token': rpKey}
    });

    // Allows us to open child authorization dialogs from this one (ie change password from login, etc)
    dialogRef.componentInstance.openAuthDialog.subscribe(
      type => {
          if (type === 'create') {
            this.openCreateAccountDialog();
          } else {
            this.openChildDialog(type)
          }
      }
    );

    // Listen to the close event and if this was a reset dialog (or a request, see above),
    // close it and reopen as a login dialog
    dialogRef.afterClosed().subscribe(result => {
        if (this._openComplaintSidenav && this.user_authenticated) {
            this._sharedService.publishSidenav('complaint');
            this._openComplaintSidenav = false;
        }
        dialogRef.componentInstance.openAuthDialog.unsubscribe();
    });
  }

  /**
   * Opens child dialogs that lay on top of already existing login/reset dialogs
   * Child dialogs can close if the user clicks outside the dialog
   * types = change, request, login, reset (mainly change and request though)
   */
  public openChildDialog(pageType: string): void {
    this._dialog.open(AuthenticationDialogComponent, {
      width: '400px',
      disableClose: false,
      data: {'type': pageType}
    });
  }

  public openCreateAccountDialog(): void {
    this._dialog.open(CreateAccountDialogComponent, {
      width: '420px',
      height: '600px',
      panelClass: 'create-account-overlay',
      disableClose: false
    });
  }

}
