import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import VectorTile from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import {BaseLayerService} from './base-layer.service';

export class CorridorLayerService extends BaseLayerService {
  private style: any;
  protected layer: any;



  public getLayer() {
    /**
     * We only create one layer for the service, so handle that
     * here.
     */
    if (this.layer === undefined) {
      // Create a new layer.
      this.layer = new VectorTile({
        declutter: true,
        source: new VectorTileSource({
          format: new MVT({'layers': ['Corridors']}),
          url: this._url
        }),
        visible: true
      });
      this.style = this.getStyle();
      this.layer.setStyle(this.style);
    }
    return this.layer;
  }

  /**
   * Given a layer, return a style for that layer
   */
  public getStyle(): any {
    const stroke = new Stroke({
      color: '#ff66ff',
      width: 1
    });
    const lineStyle = new Style({stroke: stroke});
    return lineStyle;
  }

}
