import {Component, OnInit} from '@angular/core';
import {feature_overlay as FTR_TEXT} from '../env-translate';
import {SharedService} from '../shared.service';


class Point {
  arrDestSummary: string;
  altitude: number;
  heading: number;
  speed: number;
  time: Date;
  actype: string;
  airline: string;
  flightid: string;
  nnumber: string;
  adflag: string;
}


@Component({
  selector: 'app-feature-overlay',
  templateUrl: './feature-overlay.component.html',
  styleUrls: ['./feature-overlay.component.css']
})

export class FeatureOverlayComponent implements OnInit {

  public points: Point[] = [];
  public LABEL_TIME = FTR_TEXT.LABEL_TIME;
  public LABEL_ALTITUDE = FTR_TEXT.LABEL_ALTITUDE;
  public LABEL_HEADING = FTR_TEXT.LABEL_HEADING;
  public LABEL_SPEED = FTR_TEXT.LABEL_SPEED;

  constructor(private _sharedService: SharedService) {}

  ngOnInit() {
    this._sharedService.mapFeaturePopupData$.subscribe(
      data => this._processData(data)
    );
  }

  private _processData(data: any[]): void {
    /*
     * Take the data object for the static point and manipulate it to fit our interface
     * All data points should be for the same flight so sort them by time
     *
     */
    const newPoints = [];

    data
      .sort((a, b) => {
        const timeA = a['ftr_data']['epoch_seconds'],
              timeB = b['ftr_data']['epoch_seconds'];
        return timeA < timeB ? -1 : (timeA === timeB ? 0 : 1);
      })
      .map(
      dp => {
        const ftrData = dp.ftr_data,
          opData = dp.op_data,
          point = new Point();
        point.arrDestSummary = this._calcSummary(opData);
        point.altitude = ftrData['altitude_ft'];
        point.heading = Math.round(ftrData['heading'] / 5) * 5;
        point.speed = ftrData['mi/hr'];
        point.time = new Date(ftrData['epoch_seconds'] * 1000);
        point.actype = opData['actype'] || 'UKN';
        point.airline = opData['airline'];
        point.flightid = opData['flight_id'] || 'UKN';
        point.nnumber = opData['nnumber'];
        point.adflag = opData['adflag'];

        newPoints.push(point);
      }
      );

    this.points = newPoints;
  }

  private _calcSummary(opData: any): string {
    let dep_apt, arr_apt;
    if (opData.adflag === 'A') {
      arr_apt = opData.airport;
      dep_apt = opData.otherport;
    } else if (opData.adflag === 'D') {
      dep_apt = opData.airport;
      arr_apt = opData.otherport;
    } else if (opData.adflag === 'D/A') {
      const data = opData.airport.split(/\//);
      dep_apt = data[0];
      arr_apt = data[1];
    }
    return [dep_apt || 'UKN', '→', arr_apt || 'UKN'].join('');
  }
}
