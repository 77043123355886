import {BaseDrawingLayerService} from './base-drawing-layer.service';

/**
 * A special service that we use for drawing a point on the map. Supports the drawing of a SINGLE point
 */
export class PointDistanceDrawingService extends BaseDrawingLayerService {

  public nomsLayerLabel = 'point_distance';
  protected observable_name = 'publishMapPointDistanceGeom';

}
