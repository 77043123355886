export const header_component = {
  ALT_TEXT_LOGO: 'MAC Logo',
  ARIA_LABEL_CLOSE_BUTTON: 'Collapse Menu Button',
};

export const menu_icons = {
  LAYERS: 'Layers/Overlays',
  CURRENT_LOCATION: 'Find My Current Location',
  FILTER_FLIGHTS: 'Filter Flights',
  FLIGHT_INFORMATION: 'Flight Information',
  LEGEND: 'Legend',
  MY_HOME: 'My Home',
  PRINT: 'Print',
  COPY_URL: 'Copy Link to Clipboard',
  HELP: 'Help',
  CONTACT_US: 'Contact Us',
  COMPLAINT_FORM: 'Complaint Form',
  MAC_NOISE_SITE: 'macnoise.com',
  CHARTS: 'Aircraft Operations Charts',
  PIN: 'Drop a Pin',
  MEASURE: 'Measure',
  GATE: 'Gate',
  CENTER: 'Center Map',
  ACCOUNT_SETTINGS: 'User Account'
};

export const search_toolbar = {
  ARIA_LABEL_MENU_BUTTON: 'Main Menu Button',
  ARIA_LABEL_ADDRESS: 'Find an Address',
  ARIA_LABEL_WEATHER_BUTTON: 'Open Weather Dialog',
  PLACEHOLDER_ADDRESS: 'Find an Address',
  LABEL_CLEAR_BUTTON: 'Clear Address Search'
};

export const mapbox_attrib = '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>';

export const flight_toolbar = {
  REPLAY_OPTIONS_ANIMATED: 'Animated',
  REPLAY_OPTIONS_REALTIME: 'Real Time',
  REPLAY_OPTIONS_STATIC: 'Static',
  DELAY_PSA_START: '(All times in ',
  DELAY_PSA_END: ' and delayed by',
  MINUTE: 'Minute',
  MINUTES: 'Minutes',
  HOUR: 'Hour',
  HOURS: 'Hours',
  DAY: 'Day',
  DAYS: 'Days',
  MONTH: 'Month',
  MONTHS: 'Months',
  PLACEHOLDER_START_TIME: 'Start Time',
  PLACEHOLDER_START_DATE: 'Start Date',
  PLACEHOLDER_DURATION: 'Duration',
  PLACEHOLDER_PLAY_SPEED: 'Play Speed',
  PLACEHOLDER_TRACKS: 'Replay Type',
  LABEL_STATIC_REFRESH_BUTTON: 'Refresh Static Tracks',
  LABEL_STATIC_REFRESH_BUTTON_LOADING: 'Refreshing...',
  LABEL_ANIMATED_PLAY: 'Play',
  LABEL_ANIMATED_PAUSE: 'Pause',
  LABEL_ANIMATED_LOADING: 'Loading...',
  LABEL_ANIMATED_SMOOTH: 'Smooth Playback Enabled',
  LABEL_ANIMATED_ROUGH: 'Smooth Playback Disabled'
};

/**
 * Constants for the messaging snackbar that Flight Tracker uses for displaying messages to the user
 */
export const message_snackbar = {
  MSG_CONNECT_SUCCESS: 'Server connection successful',
  MSG_CONNECT_LOST: 'Connection to server lost, will try again in 30 seconds',
  MSG_TIMED_OUT: 'You have been disconnected due to inactivity',
  MSG_STATIC_OUT_OF_RANGE: 'Start Time + Duration cannot exceed the current time minus ${delay} minutes',
  LABEL_RECONNECT: 'Reconnect',
};

export const map_component = {
  LABEL_BASE_LAYERS: ['Dark',
    'Streets',
    'Satellite Streets',
    'Light'],
  NO_OPERATIONS_MSG: 'There are currently no active operations.',
  OPERATIONS_COUNT_MSG: 'Showing {count} of {total} operations',
};

export const feature_bottomsheet = {
  LABEL_CANCEL: 'Cancel',
  LABEL_GATE: 'Click to Draw Gate',
  LABEL_PIN: 'Click to Draw Pin',
  LABEL_MEASURE: 'Click to Start Measuring',
  LABEL_CHART: 'View Gate Analysis Chart',
  LABEL_MINIMIZE_GATE: 'Hide Gate Options',
  LABEL_EXPAND_GATE: 'Display Gate Options',
  LABEL_MINIMIZE_PIN: 'Hide Pin Options',
  LABEL_EXPAND_PIN: 'Display Pin Options',
  LABEL_MINIMIZE_MEASURE: 'Hide Measure Options',
  LABEL_EXPAND_MEASURE: 'Display Measure Options'
};

export const layer_sidenav = {
  LABEL_HEADER: menu_icons.LAYERS,
  NEXRAD: 'Weather Radar',
  REMOTE_MONITORING_TOWERS: 'Remote Monitoring Towers',
  DNL_CONTOURS: 'Actual Noise Contours',
  RUNWAYS: 'MAC Runways',
  OTHER_RUNWAYS: 'Other Airport Runways',
  TAILS: 'Aircraft Tails',
  CORRIDORS: 'Abatement Corridors'
};

export const main_sidenav = {
  LABEL_HEADER: 'Environment',
  SNACKBAR_MSG: 'Link Copied to Clipboard',
};

export const user_sidenav = {
    LABEL_HEADER: 'User Account',
    CREATE_ACCOUNT: 'Create Account',
    CHANGE_PASSWORD: 'Change Password',
    FORGOT_PASSWORD: 'Request Password Reset',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
};

export const weather_app = {
  MILES: 'Miles',
  MPH: 'MPH',
  LABEL_WEATHER: 'Weather',
  LABEL_WIND_SPEED: 'Wind Speed',
  LABEL_TEMP: 'Temperature',
  LABEL_PRESSURE: 'Pressure',
  LABEL_LAST_UPDATED: 'Last Updated',
  BUTTON_CLOSE: 'Close',
  UNITS_TEMP_F: 'F',
  UNITS_TEMP_C: 'C',
  UNITS_PRESSURE: 'psi'
};

export const help_component = {
  LABEL_HEADER: menu_icons.HELP,
  INTRO_TEXT: 'The Metropolitan Airports Commission collects flight tracking data and publishes them for public use and viewing.  The data provided are for information purposes only. Using this tool will allow you to view aircraft arrival and departure activity within a 40-mile radius of Minneapolis-St. Paul International Airport (MSP).',
  LABEL_TAB_1: 'VIEWING',
  LABEL_TAB_2: 'MENU',
  LABEL_TAB_3: 'OTHER',
  HEADER_1_1: 'Viewing Options',
  HEADER_1_2: 'Mapping an Address',
  HEADER_1_3: 'Specifying a Date and Time',
  HEADER_1_4: 'Weather',
  HEADER_1_5: 'RMT Display',
  HEADER_1_6: 'Static Mode Flight Display',
  HEADER_2_1: 'Menu Options',
  HEADER_3_1: 'Other Helpful Information',
  TEXT_1_1: 'There are three options for viewing flight track information. Choose Animated, Real Time or Static from the Replay Type section of the website. You may select one type of flight track information at a time. A description of each display is provided below.',
  TEXT_1_2: 'At the top of the map, a "Find an Address" bar is provided so that you may locate a particular address or point on the map. In this bar, type the address number then street name. As you type, addresses will begin to appear in the drop down box. Click or tap on the address you wish to use.',
  TEXT_1_3_1: 'When you are interested in viewing aircraft activity for a specific date and time, click on the Start Date calendar. The electronic calendar will open and display the current month and year. You may select any month of interest by clicking the arrows on the top of the calendar, and then clicking on the date of the activity you would like to view.',
  TEXT_1_3_2: 'You may select the Start Time of the activity you would like to view when you click on the down arrow next to the Start Time. In Animated Mode, you can choose a speed of playback. The replay will start after clicking or tapping the Play triangle. In Static Mode, you can choose the duration of time. The tracks will display after clicking or tapping the Refresh circle.',
  TEXT_1_4: 'In Animated and Real Time, MSP weather data is available in the top right corner of the website. The wind direction and speed is displayed on the screen. By clicking the arrow to the right of this data, an information screen will also show additional information about the weather for the time selected.',
  TEXT_1_5: 'The system of 39 Remote Monitoring Towers can be displayed using the Layers / Overlay feature. In Animated mode, RMT locations will light up if an event has been recorded for the time displayed. The MAC classifies an event when the sound pressure level (SPL) reaches 65 dBA and records an event when the SPL remains at or above 63 dBA for at least eight seconds. Not all events recorded or displayed are the result of aircraft overflights.',
  TEXT_1_6: 'In Static Mode, many details about individual flights are available. After you select a date and time and refresh the map to display tracks, clicking a flight track will provide more information. Each track has individual data points that contain information about date and time of the point, altitude, heading and speed at that point. Hover over the data points to display this information. For MSP operations, if the flight triggered an event at one of the RMTs, the RMT will change color and the date and time and maximum dBA level will be displayed above the RMT location.',
  TEXT_2_1: 'Flight Tracker has a variety of tools and customization options to provide our customers with a more useful viewing experience.',
  TEXT_3_1: 'The top right hand section of the map will display the number of flights that meet your date and time selection. If there are filters enabled, it will show the number that meet the date and time selection and the number that have not been excluded by the filters selected.',
  TEXT_3_2: 'The bottom of the map will display the current scale of the map. The map can be zoomed or panned using traditional methods. ',
  TEXT_3_3: 'Not all features of this tool will be available on a smaller screen or device. If such features do not exist and you wish to access them, please use a device with a larger screen.',
  TEXT_3_4: 'If you need additional assistance with the MAC Flight Tracker, please contact the Community Relations Office at 612-726-9411, option 2.',
  LIST_1_1: 'Animated replays flights in animated motion for dates and times you choose.',
  LIST_1_2: 'Real Time is a continuous stream of flight activity displayed in animated motion. The animated flight activity shows real time flights with a 20 minute delay.',
  LIST_1_3: 'Static displays tracks for flights that occurred earlier today or on previous dates.',
  MENUITEM_2_1: 'Select the Base Layer of the map in this section. There are also other map layers that will be useful. Actual Noise Contours display the most recently published MSP 60 dB and 63 dB DNL Noise Contours. MAC Runways displays runways for all seven MAC airports (light blue). Other Airport Runways displays runways at airports in the MACNOMS flight area not operated by MAC (purple). Weather Radar displays Nexrad base reflectivity data for the date / time selected. Remote Monitoring Towers displays the location and sound event data for the system of 39 monitoring towers around MSP.',
  MENUITEM_2_2: 'This feature allows customers to more precisely select the aircraft on the screen. You can filter flights by operation type, specific MAC runway or MSP airline. There is also a Clear All Filters feature.',
  MENUITEM_2_3: 'Many attributes of each flight can be displayed on the screen in Real Time and Animated modes. The user controls how the information is displayed by the order in which selections are added or removed.',
  MENUITEM_2_4: 'Clicking or tapping this button will return the map to its starting point.',
  MENUITEM_2_5: 'Customers can use this tool to highlight a specific area of the metro. This has the same functionality as entering an address. When a pin is added or an address is entered the 2D Distance from Pin in the Flight Information section will calculate the distance from an aircraft to the Pin in Real Time and Animated modes.',
  MENUITEM_2_6: 'The Gate tool is available in Static mode. Customers can draw custom gates to see the altitude of aircraft as it crossed the gate. Click or tap a starting point and double click or tap a gate end point to display the Gate Analysis chart. This chart will plot the altitude of aircraft at the gate and the lateral distance from the Gate starting point. Hovering on individual points will provide more attributes of the flight. The chart itself is customizable by zooming or sliding axes. Return to chart starting point by double clicking or tapping the chart.',
  MENUITEM_2_7: 'Customers can measure the distance between points by clicking or tapping starting and ending points. The measurement tool will continue to update until double click.',
  MENUITEM_2_8: 'Current location can also be found using this tool in the menu. By clicking or tapping this button the map will center to the current location of the device.',
  MENUITEM_2_9: 'This is the primary method for customers to share their current Flight Tracker view with others. Flight Tracker will generate a link with current map view, map layers, mode, date, time and filters.',
  MENUITEM_2_10: 'In Animated and Real Time modes this button will display a chart that details the MSP hourly operations and configuration for the day selected. A second chart will display the counts of operations by aircraft group.',
  MENUITEM_2_11: 'The Legend is available to view the track color of flight tracks and the plane icon for aircraft groups.',
  MENUITEM_2_12: '',
  MENUITEM_2_13: 'This button provides a link to the MAC Community Relations Office Contact Us online submission form. Please use this for questions about Flight Tracker or to provide feedback.',
  MENUITEM_2_14: 'In order to file a complaint to the MAC about flight activity within the Twin Cities airspace using this form, you must register for an account (see below) and have a valid street address within one of these seven counties: Anoka, Carver, Dakota, Hennepin, Ramsey, Scott or Washington. To file a flight activity complaint, click on the icon that looks like a message bubble near the bottom of the menu options bar. Complaints can be filed without registering for an account by calling 612-726-9411.',
  MENUITEM_2_15: 'User Account allows you to log into your account or reset your account password. You may also create an account through this feature if you do not have an account. In order to file a complaint to the MAC about flight activity within the Twin Cities airspace, you must have an account and a valid street address within one of these seven counties: Anoka, Carver, Dakota, Hennepin, Ramsey, Scott or Washington. To create an account or to log into your account click on the icon that looks like a person on the bottom of the menu options bar.',
};

export const chart_sidenav = {
  LABEL_HEADER: 'Charts',
  LABEL_AIRPORT_HEADER: 'Daily Operations',
  TITLE_OPS_CHART: 'Operations per Hour',
  TITLE_AIRCRAFT_CHART: 'Aircraft per Day',
  TEXT_OPERATION: 'Operations',
  TEXT_HOUR: 'Hour',
  TEXT_TYPE: 'Type',
  SUMMARY: 'The Runway Use System (RUS) prioritizes arrival and departure runways to promote flight activity over less-populated residential areas as much as possible.',
  LINK_TEXT: 'MSP Runway Use System Overview',
  LABEL_NO_DATA: 'No Operations Data Available'
};

export const legend_sidenav = {
  LABEL_HEADER: 'Legend',
  INTRO_TEXT: '',
  LABEL_TAB_1: 'Colors',
  LABEL_TAB_2: 'Icons',
  LABEL_TAB_1_COL_1: 'Color',
  LABEL_TAB_1_COL_2: 'Operation',
  LABEL_TAB_2_COL_1: 'Icon',
  LABEL_TAB_2_COL_2: 'Aircraft Type',
  TEXT_WIDEBODY: 'Widebody',
  TEXT_NARROWBODY: 'Narrowbody',
  TEXT_RJ: 'Regional Jet',
};

export const flight_counter = {
  LABEL_ACTIVE: 'Active',
  LABEL_DISPLAYED: 'Displayed',
  LABEL_NO_DATA: 'No Flight Data'
};

export const info_sidenav = {
  // LABEL_HEADER: menu_icons.FLIGHT_INFORMATION,
  LABEL_HEADER: 'Information',
  LABEL_TOGGLE: 'Enable information for all flights'
};

export const filter_sidenav = {
  ARIA_LABEL_SEARCH: 'Airline Search',
  LABEL_HEADER: menu_icons.FILTER_FLIGHTS,
  LABEL_TAB_1: 'TYPES',
  LABEL_TAB_2: 'RUNWAYS',
  LABEL_TAB_3: 'AIRLINES',
  LABEL_TAB_SEARCH: 'SEARCH',
  LABEL_ARRIVALS: 'Arriving',
  LABEL_DEPARTURES: 'Departing',
  LABEL_UNKNOWN: 'Non MAC',
  LABEL_ARRIVING_AND_DEPARTING: 'Departing/Arriving',
  PLACEHOLDER_SEARCH: 'Search for Airlines'
};

export const gate_dialog = {
  BUTTON_CLOSE: 'Close',
  TITLE_CHART: 'Gate Analysis',
  LABEL_X_AXIS: 'Distance from Gate Starting Point (ft)',
  LABEL_Y_AXIS: 'Altitude (ft)',
  TEXT_FLIGHT: 'Flight: ',
  TEXT_DISTANCE: 'Distance (ft): ',
  TEXT_ALTITUDE: 'Altitude (ft): ',
  TEXT_AIRCRAFT: 'Aircraft: ',
  TEXT_GATE_TIME: 'Time: ',
  TEXT_ROUTE: 'Route: '
};

export const feature_overlay = {
  LABEL_TIME: 'TIME',
  LABEL_ALTITUDE: 'ALT (ft)',
  LABEL_HEADING: 'HDG (deg)',
  LABEL_SPEED: 'SPD (mph)'
};


/**
 * Constants for unit abbreviations that we display in places.
 */
export const unit_abbreviations = {
  MILES_PER_HOUR: 'mph',
  KILOMETERS_PER_HOUR: 'kph',
  FEET: 'ft',
  MILES: 'mi',
  DEGREES: '°',
  METERS: 'm',
};


/**
 * Constants for the names of various attributes (human readable) that we display
 * in the UI.
 */
export const attribute_names = {
  ALTITUDE: 'Altitude',
  SPEED: 'Speed',
  HEADING: 'Heading',
  ADFLAG: 'Arrival/Departure Flag',
  DESTINATION: 'Destination',
  AIRPORT: 'Local Airport',
  NNUMBER: 'N-Number',
  AIRCRAFT_TYPE: 'Aircraft Type',
  AIRCRAFT_CATEGORY: 'Category',
  FLIGHT_ID: 'Flight Number',
  SUMMARY: 'Summary',
  ORIGIN: 'Origin',
  POINT_DISTANCE_2D: '2D Distance from Pin',
  POINT_DISTANCE_3D: '3D Distance from Pin',
  RUNWAY: 'Runway',
};

/*
 * Constants for the operator types for aircrafts
 */

export const aircraft_opertype = {
  M: 'Military',
  H: 'Helicopter',
  P: 'Piston',
  U: 'Uncategorized',
  J: 'Jet',
  T: 'Turbo',
  C: 'Carrier Jet',
  B: 'Blimp'
};

export const runway_use_codes = {
  'NULL': 'Unknown',
  'U': 'Unusual',
  'O': 'Opposite Direction',
  'N': 'North Flow',
  'N*': 'Straight North Flow',
  'MA': 'Mixed Flow A',
  'S': 'South Flow',
  'S*': 'Straight South Flow',
  'MB': 'Mixed Flow B',
  'NA': 'Not Available'
};

export const authentication = {
  TITLE_RESET: 'Reset your password',
  TITLE_REQUEST_EXPIRED: 'Password reset request expired',
  TITLE_REQUEST: 'Request password reset',
  TITLE_CHANGE: 'Change your password',
  TITLE_LOGIN: 'Please login to continue',
  TITLE_ADD_USER: 'Add New User',
  BUTTON_CANCEL: 'CANCEL',
  BUTTON_LOGIN: 'LOGIN',
  BUTTON_SUBMIT: 'SUBMIT',
  BUTTON_REQUEST: 'REQUEST NEW LINK',
  BUTTON_SEND_EMAIL: 'SEND EMAIL',
  LINK_NEW_ACCOUNT: 'Create Account',
  LINK_SIGNUP: 'Signup',
  LINK_RESET: 'Request Password Reset',
  LINK_CHANGE: 'Change Password',
  RESET_REQUEST_MESSAGE: 'An email will be sent to the address provided.  Please follow the instructions to reset your password.',
  RESET_REQUEST_INVALID_MESSAGE: ('The link that brought you to this page has expired.' +
    '  Click on the "Request New Link" button to receive a new email or click "Cancel" to' +
    ' return to the login screen'),
  PASSWORD_FIELD_LABEL: 'Password',
  NEW_PASSWORD_FIELD_LABEL: 'New Password',
  REENTER_NEW_PASSWORD_FIELD_LABEL: 'Re-enter New Password',
  EMAIL_FIELD_LABEL: 'Email Address',
  FIRST_NAME_FIELD_LABEL: 'First Name',
  LAST_NAME_FIELD_LABEL: 'Last Name',
  ERROR_MSGS: {
    REQUIRED: 'You must enter a value',
    MIN_SIZE: 'Your password must contain at least 8 characters',
    PASSWORD_MATCH: 'Your new passwords must be the same',
    EMAIL_MATCH: 'Your email addresses must match',
    VALID_EMAIL: 'Email address is not valid',
    VALID_PASSWORD: 'Passwords must contain 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character (-_!@#$%^&*)',
  },
  SUCCESS_MSGS: {
    LOGIN: 'Login successful',
    LOGOUT: 'Logout successful',
    REQUEST: 'Request password reset email sent',
    RESET: 'Password reset successful',
    CHANGE: 'Change password successful'
  }
};

export const create_account = {
    TITLE: 'Create Account',
    BUTTON_CANCEL: 'CANCEL',
    BUTTON_NEXT: 'NEXT',
    BUTTON_SUBMIT: 'SUBMIT',
    AGREE_MSG: 'I agree to the terms outlined above',
    LABEL_CLEAR_BUTTON: 'Clear Address Search',
    STEP_1: 'Step 1',
    STEP_2: 'Step 2',
    STEP_3: 'Step 3',
    STEP_4: 'Step 4',
    ADDRESS_FIELD_LABEL: 'Street Address',
    ADDRESS2_FIELD_LABEL: 'Apt / Suite / Other',
    FIRST_NAME_FIELD_LABEL: 'First Name',
    LAST_NAME_FIELD_LABEL: 'Last Name',
    PHONE_FIELD_LABEL: 'Phone Number',
    EMAIL_FIELD_LABEL: 'Email Address',
    CONFIRM_EMAIL_FIELD_LABEL: 'Confirm Email Address',
    ADDRESS_PLACEHOLDER: 'Find an Address',
    ADDRESS_LINK: 'I can\'t find my address',
    API_ERROR_MSG: 'An error has occured.',
    PRIVACY_MSG_2: 'You must check that you agree in order to continue.',
    ADDRESS_MSG: 'To create an account you must provide a valid address within the seven counties of the Minneapolis/St. Paul Metropolitan Area.',
    PRIVACY_MSG: 'Some information we ask you to provide may be considered private data under Minnesota law, and we may share it to the extent permitted or required by law or court order. Any data you chose to provide will be used to track aircraft noise complaints. You are not required to provide private information, but without it we will be unable to accept a complaint within this system.'
};

export const address_help = {
  TITLE: 'Contact MAC for Assisstance',
  MESSAGE: 'If you are unable to locate your address, please complete the form below so MAC can assist you in creating an account.',
  EMAIL_LABEL: 'Email Address',
  ADDRESS_LABEL: 'Street Address',
  ERROR_EMAIL: 'Email Address is required',
  ERROR_EMAIL_VALID: 'A valid Email Address is required',
  ERROR_ADDRESS: 'Street Address is required',
  MSG_ERROR: 'An error has occurred, please try again later.',
  MSG_SUCCESS: 'MAC Staff have been notified and will respond within 3 business days.  Thank you for your patience.',
  BUTTON_CANCEL: 'CANCEL',
  BUTTON_SUBMIT: 'SUBMIT',
};


export const noise_codes = {
    'early_late': 'Early/Late (Sleep/Rest Disturbance)',
    'excessive_noise': 'Excessive Noise',
    'frequency': 'Frequency (Too Many Overflights)',
    'ground_noise': 'Noises from Aircraft on the Ground',
    'helicopter': 'Helicopter Overflight/Noise',
    'low': 'Altitude too Low',
    'run_up': 'Run-up (Engine Testing)',
    'structural_disturbance': 'Structural Disturbance (Vibrations)',
    'other': 'Other Aircraft Noise-Related Issues'
};

export const complaint_sidenav = {
    LABEL_HEADER: 'Complaint Form',
    TITLE_MAIN: 'File a Noise Complaint',
    TITLE_ADDRESS: 'Address:',
    TITLE_DISTURBANCE: 'The disturbance occurred on:',
    TITLE_NOISE: 'Please select one or more aircraft noise descriptors from the list below:*',
    TITLE_FLIGHT_INFO: 'Flight Information:',
    LABEL_DATE: 'Date',
    LABEL_TIME: 'Time',
    LABEL_AIRPORT: 'Airport',
    LABEL_AD: 'Arrival or Departure',
    PLACEHOLDER_AIRPORT: 'Select an airport',
    PLACEHOLDER_DATE: 'Choose a date',
    PLACEHOLDER_TIME: 'Choose a time',
    BUTTON_SUBMIT: 'SUBMIT',
    LABEL_UNKNOWN: 'Unknown',
    ERROR_AIRPORT: 'Airport is required',
    ERROR_DATE: 'Date is required',
    ERROR_DATE_MAX: 'Date cannot be in the future',
    ERROR_DATE_MIN: 'Date cannot be earlier than ',
    ERROR_DATE_VALID: 'You must enter a valid date',
    ERROR_TIME: 'Time is required',
    ERROR_TIME_MAX: 'Time cannot be in the future',
    OPTION_NONE: 'None of these',
    OPTION_ARRIVAL: 'Arrival',
    OPTION_DEPARTURE: 'Departure',
    OPTION_UNKNOWN: 'Unknown',
    MSG_SUCCESS: 'Your complaint has been submitted.',
    MSG_ERROR: 'The system encountered an error, please try again later.',
    SUCCESS_QUESTION: 'What happens now?',
    SUCCESS_MSG_1: 'Complaints are used by the MAC for analysis purposes only. If you would like to speak to someone about your complaint or have other questions about flight activity, contact the MAC Community Relations Office by calling 612-726-9411 or emailing ',
    SUCCESS_MSG_2: 'Click the links below for additional information:',
    EMAIL_LINK: 'info@macnoise.com',
};

export const complaint_helpful_links = [
  {
    label: 'Aircraft Noise at MAC Airports',
    url: 'https://metroairports.org/community-connection/aircraft-noise'
  }, {
    label: 'Interactive Reports',
    url: 'https://customers.macnoms.com/reports/'
  } , {
    label: 'MAC\'s Residential Noise Mitigation',
    url: 'https://metroairports.org/noise-mitigation-program'
  }, {
    label: 'Frequently Asked Questions',
    url: 'https://metroairports.org/aircraft-noise-faqs'
  }
];
