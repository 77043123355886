import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {SharedService} from '../shared.service';
import {ApiService} from '../api.service';
import {complaint_sidenav as COMPLAINT_TEXT, noise_codes as NOISE_CODES, complaint_helpful_links as OTHER_LINKS} from '../env-translate';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';
import { finalize } from 'rxjs/operators';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-complaint-sidenav',
  templateUrl: './complaint-sidenav.component.html',
  styleUrls: ['./complaint-sidenav.component.css'],
  animations: [
      trigger('submitted', [
        state('show', style({
            zIndex: 1,
            opacity: 1,
            left: '0px',
            position: 'relative'
        })),
        state('hide', style({
            zIndex: 0,
            opacity: 0,
            left: '330px',
            height: 'initial'
        })),
        transition('show => hide', [
            animate('0.25s')
        ]),
        transition('hide => show', [
            animate('0.5s')
        ])
      ])
  ]
})

export class ComplaintSidenavComponent implements OnInit {
  @ViewChild(MatSelectionList, {static: false}) noiseList: MatSelectionList;
  public COMPLAINT_TEXT: any = COMPLAINT_TEXT;
  public OTHER_LINKS: any = OTHER_LINKS;
  private _NOISE_CODES: any = NOISE_CODES;
  public form: FormGroup;
  public complaints: any[];

  private _userInfo: any;
  public location: any;
  public airports: any;
  public maxDate = new Date();
  public minDate = new Date();
  public failedValidation = false;
  public isSaving = false;
  public isSubmitted = false;

  constructor(private _sharedService: SharedService, private _fb: FormBuilder, private _apiService: ApiService) {}

  ngOnInit() {
    this.form = this._fb.group({
        'user_id': [''],
        'user_location_id': [''],
        'icao_code': ['', Validators.required],
        'adflag': [''],
        'c_date': [new Date(), Validators.required],
        'c_time': [(new Date()).getHours() + ':00', Validators.required],
        'reasons': [[], Validators.required]
    });

    // Grab the minimum complaint date
    this._sharedService.wsMinComplaintDate$.subscribe(
        data => this.minDate = new Date(data)
    );

    // Get the list of airports
    this._sharedService.ftAirports$.subscribe(
        data => this.airports = data
    );

    // Need to update the time fields validation after the date is changed
    this.c_date.valueChanges.subscribe(
        () => this.c_time.updateValueAndValidity()
    );

    // Need to see if the date/time is in the future after the time is changed (similar to a custom validator)
    this.c_time.valueChanges.subscribe(
        () => {
            if (this.c_date_time > new Date()) {
                this.c_time.setErrors({futureTime: true});
            }
        }
    );

    // Monitor when the user logs in
    this._sharedService.userInfo$.subscribe(
        user => {
            this._userInfo = user;
            if (user) {
                this.user_id.setValue(user.id);
                this._apiService.getUserLocation(user.id).subscribe(
                    data => {
                        const loc = data.objects[0];
                        if (loc) {
                          this.location = loc;
                          this.user_location_id.setValue(loc.id);
                        }
                    }
                );
                if (!this.complaints) {
                    // Pull the list of complaints from the server and create checkboxes
                    // Only works if the user is authenticated
                    // Only pull if we don't already have the complaints
                    this._apiService.getComplaintReasons().subscribe(
                        data => {
                            this.complaints = data.objects;
                            this.complaints.forEach(
                                c => {
                                    // Update the complaint description with our descriptions
                                    // Makes it much easier for translation purposes
                                    const desc = this._NOISE_CODES[c.reason] ? this._NOISE_CODES[c.reason] : c.description;
                                    c.description = desc;
                                    this.form.addControl(c.reason, new FormControl(''));
                                }
                            );
                        }
                    );
                }
            } else {
                this.user_id.setValue('');
                this.location = null;
            }
        }
    );
  }

  public updateNoiseSelection(selOptions: SelectionModel<MatListOption>): void {
    // Loop through the selected options and populate the reasons field
    const vals = [];
    selOptions.selected.forEach(
        matOption => vals.push(matOption.value)
    );
    this.reasons.setValue(vals);
  }

  public submitForm(): void {
    this.failedValidation = !this.form.valid;
    // Make a final check to see if the user is logged in (basically making sure they didn't log out from a different window)
    this._apiService.getUserStatus().subscribe(
        o => {
            if (!o.authenticated) {
                this._sharedService.publishOpenAuthenticationDialog();
            } else if (!this.failedValidation) {
                // Google Analytics
                window['gtag']('event', 'complaint', { 'event_category': 'Submit' });

                this.isSaving = true;
                const data = {
                    user_location: this.user_location_id.value,
                    reasons: this.reasons.value,
                    icao_code: this.icao_code.value === 'none' ? '' : this.icao_code.value,
                    adflag: this.adflag.value,
                    complaint_date: this.c_date_time.toISOString()
                };

                this._apiService.addComplaint(data).pipe(finalize(() => this.isSaving = false)).subscribe(
                    success => {
                        if (success.errors) {
                            // The data was submitted but the API threw an error
                            this._sharedService.publishFTMessage(COMPLAINT_TEXT.MSG_ERROR, null);
                        } else {
                            // The data was submitted and successful
                            this._sharedService.publishFTMessage(COMPLAINT_TEXT.MSG_SUCCESS, null);
                            this._wipeForm();
                            // Switch the sidenav to the success block
                            this.isSubmitted = true;
                        }
                    },
                    err => {
                        this._sharedService.publishFTMessage(COMPLAINT_TEXT.MSG_ERROR, null);
                    }
                );
            }
        }
    )
  }

  public newComplaint(): void {
      this.isSubmitted = false;
  }

  private _wipeForm(): void {
    // Clear the failed flag
    this.failedValidation = false;
    // Reset our fields that need defaults
    this.form.reset({
        'adflag': '',
        'icao_code': '',
        'user_location_id': this.location.id,
        'c_date': new Date(),
        'c_time': (new Date()).getHours() + ':00',
        'reasons': []
    });
    // Deselect our noise reasons
    this.noiseList.deselectAll();
  }

  get user_id() {
      return this.form.get('user_id');
  }

  get user_location_id() {
      return this.form.get('user_location_id');
  }

  get icao_code() {
      return this.form.get('icao_code');
  }

  get adflag() {
      return this.form.get('adflag');
  }

  get c_date() {
      return this.form.get('c_date');
  }

  get c_time() {
      return this.form.get('c_time');
  }

  get reasons() {
      return this.form.get('reasons');
  }

  get c_date_time(): Date {
      if (this.c_date.value) {
        return new Date(this.c_date.value.toDateString() + ' ' + this.c_time.value);
      } else {
          return null;
      }
  }

}
