import {NgModule} from '@angular/core';

import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';  // Tooltip defaults that we need to override
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';  // Dialog defaults that we need to override

/* Custom options the configure the tooltip's default show/hide delays. */
export const customTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 750,
  hideDelay: 500,
  touchendHideDelay: 1000,
};

/* Custom options to configure the max width of the dialog (mainly for phones) */
export const customDialogDefaults: MatDialogConfig = {
  maxWidth: '98vw',
  maxHeight: '80vh',
  hasBackdrop: true,
};

// Other material design modules
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';


@NgModule({
  imports: [
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatListModule,
    MatDividerModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatProgressBarModule,
    MatStepperModule,
  ],
  exports: [
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatListModule,
    MatDividerModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatProgressBarModule,
    MatStepperModule,
  ],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: customDialogDefaults},
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}}
  ],
})

export class MaterialModule {
  constructor() {

  }
}


