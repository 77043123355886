import { Component, OnInit } from '@angular/core';
import { SharedService } from './shared.service';
import {flight_toolbar as FT_TEXT} from './env-translate';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  // Used to set which sidenav menus are opened
  public mainMenuOpened = true;
  public auxMenuOpened = false;
  public auxMenu = 'base_layers';
  public mainMenuMinified = true;
  private _isSmallScreen = false;
  // Used to set a class on the innermost sidenav container
  public selectedReplay: string;
  public REPLAY_OPTIONS_ANIMATED = FT_TEXT.REPLAY_OPTIONS_ANIMATED;

  public mainMenuStatus = new Subject<string>();
  private _mainMenuStatus$ = this.mainMenuStatus.asObservable();

  constructor(private _sharedService: SharedService, private _breakpointObs: BreakpointObserver) { }

  ngOnInit(): void {
    // Poll the users authentication status
    this._sharedService.pollUserStatus();

    // Subscribe to the sidenavClicked Observable so we know which sidenav needs to be open/closed
    this._sharedService.sidenavClicked$.subscribe(
      data => this._toggleSidenav(data)
    );
    // Subscribe to the replay field so we can set the class on the innermost sidenav container
    this._sharedService.replay$.subscribe(
      data => this.selectedReplay = data
    );
    // Subscribe to the breakpoint observer to track media matches
    this._breakpointObs
      .observe(['(max-width: 570px)'])
      .subscribe((state: BreakpointState) => {
        // Only triggers when the screen goes above or below 570 pixels
        // If below 570 pixels (state.matches === true) then we don't want to display or use the mini nav bar
        this._isSmallScreen = state.matches;
        this.mainMenuMinified = !this._isSmallScreen;
        this.mainMenuOpened = !this._isSmallScreen;
      });
    // iOS triggers the opened and closed events twice, causing the main menu to shrink back to mini mode immediately
    // This observable prevents the duplicate events from triggering
    this._mainMenuStatus$
      .pipe(distinctUntilChanged())
      .subscribe(status => {
        if (status === 'opened') {
          this.resizeMap();
        } else {
          this.mainMenuNavOnClose();
        }
      });
  }

  public mainMenuNavOnClose() {
    /*
     * Triggered on the close event of the main menu nav
     * This is how we simulate the auto expand/shrink of the main nav
     * When it's big, it closes and reopens as small...and when it's small it closes and reopens as big
     * ONLY DO THIS WHEN the screen size is > 570 px, otherwise don't reopen the main menu (at < 570 we remove the mini menu)
     */
    if (!this._isSmallScreen) {
      this.mainMenuMinified = !this.mainMenuMinified;
      this._openMainMenu();
    }
  }

  private _toggleSidenav(menuId: string) {
    /*
     * Wrapper function that determines which sidenav to toggle based on the menuId returned
     */
    if (menuId === 'main') {
      this._toggleMainMenu();
    } else if (!!menuId) {
      this._toggleAuxMenu(menuId);
    }
  }


  private _toggleMainMenu(): void {
    /*
     * In reality the main menu never stays closed, it always closes and reopens and simply changes from mini or full mode
     * Added this function so that it's called similar to the other menus
     */
    this.mainMenuOpened = !this.mainMenuOpened;
  }

  private _openMainMenu(): void {
    /*
     * Sets the flag to open the main menu, needs to be a separate private function and not a closure
     */
    this.mainMenuOpened = true;
  }

  private _closeMainMenu(): void {
    /*
     * Sets the flag to close the main menu, needs to be a separate private function and not a closure
     */
    this.mainMenuOpened = false;
  }

  private _toggleAuxMenu(menuId: string): void {
    /*
     * Open or close the aux side menu (help, filters, base_layers)
     * Also handle the main menu appropriately
     */
    if (this.auxMenuOpened && this.auxMenu === menuId) {
      this.auxMenuOpened = false;
    } else {
      if (!this.mainMenuMinified) {
        this._closeMainMenu();
      }
      this.auxMenu = menuId;
      this.auxMenuOpened = true;
    }
  }

  public resizeMap(): void {
    /*
     * Dispatches a window resize event when the main menu is opened in mini mode
     * This causes the map to trigger it's own resize event
     */
    if (this.mainMenuMinified) {
       window.dispatchEvent(new CustomEvent('resize'));
    }
  }

}
