import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {SharedService} from '../shared.service';
import {flight_toolbar as FT_CONST, site_defaults as DEFAULTS} from '../env-constants';
import {flight_toolbar as FT_TEXT, message_snackbar as MSG_TEXT} from '../env-translate';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';


class TimeOption {
  label: string;
  value: number;
}

@Component({
  selector: 'app-flight-toolbar',
  templateUrl: './flight-toolbar.component.html',
  styleUrls: ['./flight-toolbar.component.scss'],
})

export class FlightToolbarComponent implements OnInit {
  public replayOptions: string[];
  public playSpeedOptions: number[] = FT_CONST.PLAY_SPEED_OPTIONS;
  public durationOptions: number[] = FT_CONST.DURATION_OPTIONS;
  public startTimeOptions: TimeOption[];

  public sliderMin: number;
  public sliderMax: number;
  public sliderCurrent: number;
  public sliderStep: number;
  public sliderPlaying: boolean;
  public nonRTInterfaceDisabled = true;

  public DELAY_PSA: string;
  public MINUTES = FT_TEXT.MINUTES;
  public MINUTE = FT_TEXT.MINUTE;
  public HOURS = FT_TEXT.HOURS;
  public HOUR = FT_TEXT.HOUR;
  public REPLAY_OPTIONS_ANIMATED = FT_TEXT.REPLAY_OPTIONS_ANIMATED;
  public REPLAY_OPTIONS_REALTIME = FT_TEXT.REPLAY_OPTIONS_REALTIME;
  public REPLAY_OPTIONS_STATIC = FT_TEXT.REPLAY_OPTIONS_STATIC;
  public PLACEHOLDER_START_TIME = FT_TEXT.PLACEHOLDER_START_TIME;
  public PLACEHOLDER_START_DATE = FT_TEXT.PLACEHOLDER_START_DATE;
  public PLACEHOLDER_DURATION = FT_TEXT.PLACEHOLDER_DURATION;
  public PLACEHOLDER_PLAY_SPEED = FT_TEXT.PLACEHOLDER_PLAY_SPEED;
  public PLACEHOLDER_TRACKS = FT_TEXT.PLACEHOLDER_TRACKS;
  public LABEL_STATIC_REFRESH_BUTTON = FT_TEXT.LABEL_STATIC_REFRESH_BUTTON;
  public LABEL_STATIC_REFRESH_BUTTON_LOADING = FT_TEXT.LABEL_STATIC_REFRESH_BUTTON_LOADING;
  public LABEL_ANIMATED_PLAY = FT_TEXT.LABEL_ANIMATED_PLAY;
  public LABEL_ANIMATED_PAUSE = FT_TEXT.LABEL_ANIMATED_PAUSE;
  public LABEL_ANIMATED_LOADING = FT_TEXT.LABEL_ANIMATED_LOADING;
  public LABEL_ANIMATED_SMOOTH = FT_TEXT.LABEL_ANIMATED_SMOOTH;
  public LABEL_ANIMATED_ROUGH = FT_TEXT.LABEL_ANIMATED_ROUGH;

  public timezone: string;

  // Date/time update by our _animatedTimer
  public animatedDate;
  private _animatedTimer;
  // Minimum/maximum dates for our start date calendar
  public calMinDate;
  public calMaxDate = new Date();
  private _startDateValid = true;
  private _minDelay: number;
  // Monitor our static tracks
  public staticTracksLoading = false;
  // Should we show the loading icon (if the user clicks play and we have no data in the range then true)
  public showLoadingIcon = false;
  // Smooth playback or not
  public smoothPlayback = true;
  // Array of Start Dates for data we have in cache
  private _wsCacheDates: any[] = [];
  private _wsInterval: number;

  form: FormGroup;

  constructor(private _fb: FormBuilder, private _sharedService: SharedService, private _snackBar: MatSnackBar) {}

  ngOnInit() {
    this.replayOptions = [
      this.REPLAY_OPTIONS_ANIMATED,
      this.REPLAY_OPTIONS_REALTIME,
      this.REPLAY_OPTIONS_STATIC
    ];

    this.startTimeOptions = this._generateStartTimeOptions();
    this._initSlider();

    this.form = this._fb.group({
      'selectedReplay': this.replayOptions[1],
      'startDate': new Date(Date.now() - (1000 * 60 * 60 * 24)),
      'startTime': (new Date().getHours()) * 60 * 60 * 1000,
      'playSpeed': FT_CONST.PLAY_SPEED_DEFAULT,
      'duration': FT_CONST.DURATION_DEFAULT
    });
    // Subcribe to the wsInterval so we know how to calculate our date ranges from our start dates
    this._sharedService.wsInterval$.pipe(first()).subscribe(
      data => this._wsInterval = data * 60 * 1000
    );
    // Subscribe to the _wsCacheDates so we know when the slider can start moving
    this._sharedService.wsCacheDates$.subscribe(
      data => this._wsCacheDates = data
    );
    // Subscribe to the wsCalMinDate so we know the earliest date the calendar can go back to
    this._sharedService.wsCalMinDate$.subscribe(
      data => this.calMinDate = data
    );
    // Subscribe to wsDataPullInProgress so we know when our web call has finished
    this._sharedService.wsDataPullInProgress$.subscribe(
      data => this.staticTracksLoading = data
    );

    // Subscribe to any apiErrors
    this._sharedService.ftMessage$.subscribe(data => this._displaySystemMessage(data));

    /**
     * Display the delay in minutes in the UI using the data that comes back from the config API.
    * Also set the _minDelay
     */
    this._sharedService.wsMinDelay$.pipe(first()).subscribe(
      data => {
        this._createDelayPSA(data);
        this._minDelay = data;
      }
    );

    // When the url has been parsed update the form fields with any values from the stateful url
    this._sharedService.urlParsed$
      .pipe(first(data => data === true))
      .subscribe(
      data => {
        // Start tracking any field changes
        this._trackFormChanges();
        // Grab any data from the url for default values
        this._pullStatefulData();
        // Set the realtime timer (if realtime is selected)
        if (this.form.get('selectedReplay').value === this.REPLAY_OPTIONS_REALTIME) {
          // This is a little bit of a hack
          // When there are no url params the urlParsed value is returned BEFORE the websocket finishes initializing
          // So our call to pull realtime data is ignored because the sockets observable isn't hot yet
          // By subscribing to the wsMapAPIKey (a value we don't need) we know when the websocket is ready for use
          // We add first() so we stop monitoring once the first config triggers (since the socket can actually accept multiple configs)
          this._sharedService.wsMapAPIKey$
            .pipe(first())
            .subscribe(
            apikey => this._sharedService.pullRealtimeDelayedSample(true)
            );
        }
      }
      );
  }

  private _createDelayPSA(data: number): void {
    /**
    * Display the delay in minutes in the UI using the data that comes back from the config API.
    */
//    const delay = moment.duration(data, 'seconds');
//    const second_delay = delay.seconds();
//    const minute_delay = delay.minutes();
//    const hour_delay = delay.hours();
//    const days_delay = delay.asDays();
    const second_delay = data;
    const minute_delay = second_delay / 60;
    const hour_delay = minute_delay / 60;
    const days_delay = hour_delay / 24;

    let add_commas = false;
    const delay_text = [FT_TEXT.DELAY_PSA_END, ' ', ];
    if (days_delay >= 1) {
      delay_text.push(days_delay.toFixed());
      delay_text.push(' ');
      if (days_delay > 1) {
        delay_text.push(FT_TEXT.DAYS);
      } else {
        delay_text.push(FT_TEXT.DAY);
      }
      add_commas = true;
    } else if (Math.floor(hour_delay) > 0) {
      if (add_commas) {
        delay_text.push(', ');
      }
      delay_text.push(hour_delay.toFixed());
      delay_text.push(' ');
      if (Math.floor(hour_delay) > 1) {
        delay_text.push(FT_TEXT.HOURS);
      } else {
        delay_text.push(FT_TEXT.HOUR);
      }
      add_commas = true;
    } else if (minute_delay > 0) {
      if (add_commas) {
        delay_text.push(', ');
      }
      delay_text.push(minute_delay.toString());
      delay_text.push(' ');
      if (minute_delay > 1) {
        delay_text.push(FT_TEXT.MINUTES);
      } else {
        delay_text.push(FT_TEXT.MINUTE);
      }
    }
    // Zero out the delay text if there is no delay.
    if (delay_text.length === 2) {
      delay_text.length = 0;
    }
//    const this_tz = moment.tz.guess();
//    this.DELAY_PSA = [FT_TEXT.DELAY_PSA_START, moment().tz(this_tz).format('z'), delay_text.join(''), ')'].join('');
    this.DELAY_PSA = [FT_TEXT.DELAY_PSA_START, this._sharedService.pullTimezone(), delay_text.join(''), ')'].join('');
  }

  private _trackFormChanges(): void {
    /*
     * Sets the observables we need to track any changes on the form
     */
    this.form.valueChanges
      .subscribe(
      (values) => {
        this._disableEnableNonRTInterface(values);
        this._disableEnableRadar(values);
        this._disableEnableCharts(values);
        this._disableEnableGate(values);
        this._disableEnableCorridors(values);
        // Send changes to _sharedService
        this._recordChanges(values);
      }
      );

    this.form.get('selectedReplay').valueChanges.subscribe(
      val => {
        // Default the system date time to 0 so the map knows to turn off all RMTs
        this._sharedService.publishSystemCurrentDateTime(0);
        // Need to start or stop the realtime service if the user selects or de-selects Realtime replay
        this._sharedService.pullRealtimeDelayedSample(val === this.REPLAY_OPTIONS_REALTIME);
        // Stop the slider if the user switches replay from Animated
        if (val !== this.REPLAY_OPTIONS_ANIMATED) {
          this._stopSlider();
        }
      }
    );

    // DatePicker has built in validation for bad dates as well as honoring min and max, use this to disable the slider
    // This change triggers before the full form change
    this.form.get('startDate').statusChanges.subscribe(val => this._startDateValid = (val === 'VALID'));
    // Changing the value of the date or time fields should reset the slider to the beginning (no matter the status of the fields)
    this.form.get('startDate').valueChanges.subscribe(val => {
      this._stopSlider();
      this._resetSlider();
    });
    this.form.get('startTime').valueChanges.subscribe(val => {
      this._stopSlider();
      this._resetSlider();
    });
    // Changing the play speed should stop and then restart the slider (this restarts it with the new speed) but not reset it
    this.form.get('playSpeed').valueChanges.subscribe(
      val => {
        // Update the slider step
        this.sliderStep = this._calculateSliderStep(val);
        if (!!this.sliderPlaying) {
          this._stopSlider();
          this._startSlider();
        }
      }
    );
  }

  private _recordChanges(values): void {
    /*
     * Writes the field values to the appropriate _sharedService publish events (needed for stateful url)
     * Only track dates and times when replay is not real time
     */
    const replay = values.selectedReplay;
    this._sharedService.publishReplay(replay);
    if (replay !== this.REPLAY_OPTIONS_REALTIME) {
      if (this._startDateValid) {
        // Only publish the last VALID date the user entered, prevents the system from tracking bad dates as they are typing
        this._sharedService.publishStartDate(new Date(values.startDate).valueOf());
      }
      this._sharedService.publishStartTime(values.startTime);
      if (replay === this.REPLAY_OPTIONS_ANIMATED) {
        this._sharedService.publishPlaySpeed(values.playSpeed);
      } else {
        this._sharedService.publishDuration(values.duration);
      }
    }
  }

  private _pullStatefulData(): void {
    /*
     * Primarily used to pull values ONE TIME when a page is loaded via a stateful url
     * We don't want to subscribe to the individual services because it will create a loop which would fire each time the user
     * changed a field on the form (_trackFormChanges)
     */
    const urlParams = this._sharedService.pullUrlParams();
    const vals = {};

    if (urlParams.replay !== undefined) {
      vals['selectedReplay'] = urlParams.replay;
    }
    if (urlParams.startdate !== undefined) {
      vals['startDate'] = new Date(urlParams.startdate);
    }
    if (urlParams.starttime !== undefined) {
      vals['startTime'] = urlParams.starttime;
    }
    if (urlParams.duration !== undefined) {
      vals['duration'] = urlParams.duration;
    }
    if (urlParams.playspeed !== undefined) {
      vals['playSpeed'] = urlParams.playspeed;
    }
    this.form.patchValue(vals);
  }

  private _initSlider(): void {
    /*
     * Initializes the slider variables when the site is created
     * Sets the slider minimum value and step
     * Sets the maximum value on the slider based off of the minimum value
     * Added the -1 because we want the slider to range from 12:00:00 to 12:14:59 (not 12:15:00)
     */
    this.sliderMin = 0;
    this.sliderMax = this.sliderMin + (FT_CONST.SLIDER_BAR_LENGTH_IN_MINUTES * 60) - 1;
    this.sliderStep = this._calculateSliderStep(FT_CONST.PLAY_SPEED_DEFAULT);
    this.sliderCurrent = this.sliderMin;
    this.sliderPlaying = false;
  }


  private _generateStartTimeOptions(): TimeOption[] {
    /*
     * Create the options for the Start Time select field
     */
    const interval = FT_CONST.SLIDER_BAR_LENGTH_IN_MINUTES;
    const partialHours = 60 / interval;
    const numOfOptions = 24 * partialHours;
    const options = [];

    for (let x = 0, y = numOfOptions; x < y; x++) {
      const hour = Math.floor(x / partialHours);
      const minutes = (x % partialHours) * interval;
      const period = hour < 12 ? 'AM' : 'PM';
      const value = (hour * 60 * 60 * 1000) + (minutes * 60 * 1000);
      const label = (hour === 0 ? '12' : (hour > 12 ? hour - 12 : hour)) + ':' + (minutes === 0 ? '00' : minutes) + ' ' + period;
      options.push({label: label, value: value});
    }
    return options;
  }

  private _calculateSliderStep(playSpeed: number): number {
    /*
     * Takes a play speed and calculates the values for each step on the slider
     * Calculation changes based on whether we are using smooth animation or not
     */
    const timeInterval = FT_CONST.ANIMATED_DATA_PULL_INTERVAL_IN_SECONDS;
    return this.smoothPlayback ? timeInterval : timeInterval * playSpeed;
  }

  public captureSliderChange(val: number): void {
    /*
     * Tracks manual changes to the slider thumb and updates the current value
     * Sends the new date/time to the systemCurrentDateTime service
     */
    // Update the current slider value
    this.sliderCurrent = val;
    // curStartTime is returned as a number and the value is milliseconds
    const curStartTime = this.form.get('startTime').value;
    // curStartDate is returned as a Date object from the form and is a date only (no time)
    const curStartDate = this.form.get('startDate').value;
    // Calculate the exact moment represented by the flight bar using the date, time and slider
    const currentFlightBarMoment = new Date(curStartDate.valueOf() + curStartTime + (1000 * this.sliderCurrent));
    // Now emit the new date/time so the map will update
    this._updateSystemClock('animated', currentFlightBarMoment);
  }

  public toggleSlider(): void {
    /*
     * Toggles the run state of the slider
     * Triggered when user clicks the play/pause button
     */
    if (!this.showLoadingIcon) {
      this.sliderPlaying = !this.sliderPlaying;
      if (!!this.sliderPlaying) {
        this._startSlider();
        window['gtag']('event', 'play', {'event_category': 'Animated'});
      } else {
        this._stopSlider();
        window['gtag']('event', 'pause', {'event_category': 'Animated'});
      }
    }
  }

  public toggleSmoothing(): void {
    /*
     * Turns smooth playback on or off
     */
    const playSpeed = this.form.get('playSpeed').value;
    this.smoothPlayback = !this.smoothPlayback;
    this.sliderStep = this._calculateSliderStep(playSpeed);
    if (this.sliderPlaying) {
      this._stopSlider();
      this._startSlider();
    }
  }

  public pullStaticTracks(): void {
    // curStartTime is returned as a number and the value is milliseconds
    const curStartTime = this.form.get('startTime').value;
    // curStartDate is returned as a Date object from the form and is a date only (no time)
    const curStartDate = this.form.get('startDate').value;
    // Duration is returned as a number, it's the number of minutes
    const duration = this.form.get('duration').value;
    const start_time = new Date(curStartDate.valueOf() + curStartTime);
    const end_time = new Date(start_time.valueOf() + duration * 60 * 1000);
    if (!this.staticTracksLoading) {
      this._sharedService.pullStaticTracks(start_time, end_time);
      this._updateSystemClock('static', start_time, end_time);
    }
  }

  private _startSlider(): void {
    /*
     * Places the slider in its running state
     * Starts the animated timer
     * If we don't have cached data for the current time period, emit the current time period and don't move the slider
    */
    const delay = this.smoothPlayback ? 1000 / this.form.get('playSpeed').value : FT_CONST.ANIMATED_DATA_PULL_INTERVAL_IN_SECONDS * 1000;
    this.sliderPlaying = true;

    this._animatedTimer = setInterval(() => {
      // curStartTime is returned as a number and the value is milliseconds
      const curStartTime = this.form.get('startTime').value;
      // curStartDate is returned as a Date object from the form and is a date only (no time)
      const curStartDate = this.form.get('startDate').value;
      // Calculate the exact moment represented by the flight bar using the date, time and slider
      const currentFlightBarMoment = new Date(curStartDate.valueOf() + curStartTime + (1000 * this.sliderCurrent));
      // Calculate the NEXT moment represented by the flight bar using the date, time and slider
      const nextFlightBarMoment = new Date(curStartDate.valueOf() + curStartTime + (1000 * (this.sliderCurrent + this.sliderStep)));
      // Calculate the maximum future time we can display data for (current real time minus the interval)
      const maxFutureTime = this._calcMaxStoredDataEndTime();

      // FIRST - If the next date is ahead of what data we can actually show so we need to stop
      if (nextFlightBarMoment.valueOf() >= maxFutureTime) {
          this._stopSlider();
      } else if (!this._foundCachedDataForDateTime(currentFlightBarMoment)) {
        // If we don't have any cached data or we don't have data for the current moment on the flight bar
        // emit the current moment and don't move the slider
        // Possible scenarios are first time using animated replay or user changes date or time field or moves the slider too far
        this.animatedDate = currentFlightBarMoment;
      } else {
        // We have data and can move the slider (it should automatically reset itself when the change event triggers for start date or time fields)
        if (this.sliderCurrent >= this.sliderMax) {
          // Slider reached the end.
          if (curStartTime === this.startTimeOptions[this.startTimeOptions.length - 1].value) {
            // Reached end of day, reset time interval to 0 and increment the date by 1
            this.form.patchValue({
              'startDate': new Date(curStartDate.setDate(curStartDate.getDate() + 1)),
              'startTime': this.startTimeOptions[0].value
            });
          } else {
            // Increment to the next time interval
            this.form.patchValue({
              'startTime': curStartTime + (FT_CONST.SLIDER_BAR_LENGTH_IN_MINUTES * 60 * 1000)
            });
          }
          // The slider is automatically reset and paused when the date or time fields are changed, so we need to start it back up
          this._startSlider();
        } else {
          // Normal slider mode, move to the next tick
          this.sliderCurrent = this.sliderCurrent + this.sliderStep;
        }
        this.animatedDate = nextFlightBarMoment;
      }
      this._updateSystemClock('animated', this.animatedDate);
      // console.log('Automatic Flight Track Replay: ' + this.animatedDate);
    }, delay);
  }

  private _foundCachedDataForDateTime(targetDate: any): boolean {
    /*
     * Loop through the cache dates array and figure out if the targetDate is in any of them
     * If not we don't have any relevant data stored in cache
     */
    const hasDateRangeWithData = !!this._wsCacheDates.filter(
      data => {
        const startDate = data;
        const endDate = new Date(startDate.valueOf() + this._wsInterval);
        return targetDate >= startDate && targetDate < endDate;
      }
    ).length;
    // Show the loading icon if we DON'T have data in the range the user is asking for
    this.showLoadingIcon = !hasDateRangeWithData;
    return hasDateRangeWithData;
  }

  private _stopSlider(): void {
    /*
     * Places the slider in it's stopped state
     * Since this also clears the animated timer we should set the showLoadingIcon to false in case we were trying to load data and there was an error
     */
    this.sliderPlaying = false;
    this.showLoadingIcon = false;
    clearInterval(this._animatedTimer);
  }

  private _resetSlider(): void {
    /*
     * Sets the current value to sliderMin
     * Triggered when user changes the start date or start time
     */
    this.sliderCurrent = this.sliderMin;
  }

  private _disableEnableNonRTInterface(values): void {
    /*
     * Disable or re-enable the non-realtime interfaces (slider/play button for Animated and Refresh Static Tracks button for Static)
     * Based on the data in the startDate and startTime fields
     * Disables on invalid or null Start Dates or future date/time combinations
     * Triggered any time a field is changed on the form
     */
    const badDate = values.startDate == null || !this._startDateValid;
    let dateTimeIsFuture = false;

    if (!badDate) {
      // Figure out if this date/time selected is in the future or not
      const nowTime = this._calcMaxAPIStartTime();
      const startDateTimeCombo = values.startDate.setHours(0, 0, 0, 0) + values.startTime;
      dateTimeIsFuture = startDateTimeCombo >= nowTime;
    }

    const disabled = badDate || dateTimeIsFuture;

    this.nonRTInterfaceDisabled = disabled;
    if (disabled) {
      this._stopSlider();
      this._resetSlider();
    }
  }

  private _disableEnableRadar(values): void {
    /*
     * Disable or re-enable the radar overlay and button based on the data in the startDate and startTime fields
     * If Replay Type = Animated, disables radar overlay on invalid or null Start Dates or future date/time combinations
     * If Replay Type = Static, always disable radar overlay
     * Triggered any time a field is changed on the form
     */
    const staticReplay = values.selectedReplay === this.REPLAY_OPTIONS_STATIC;
    const badDate = values.startDate == null || !this._startDateValid;
    let dateTimeIsFuture = false;
    let radarDisabled;

    if (!badDate) {
      // Figure out if this date/time selected is in the future or not
      const nowTime = this._calcMaxAPIStartTime();
      const startDateTimeCombo = values.startDate.setHours(0, 0, 0, 0) + values.startTime;
      dateTimeIsFuture = startDateTimeCombo >= nowTime;
    }

    radarDisabled = staticReplay || badDate || dateTimeIsFuture;
    // Disable or re-enable the radar
    this._sharedService.publishRadarDisabled(radarDisabled);
  }

  private _disableEnableCharts(values): void {
    /*
     * Disable or re-enable the charts button based on the data in the selectedRelpay field
     * If Replay Type = Static, always disable charts
     */
    const chartsDisabled = values.selectedReplay === this.REPLAY_OPTIONS_STATIC;
    this._sharedService.publishChartsDisabled(chartsDisabled);
  }

  private _disableEnableGate(values): void {
    /*
     * Disable or re-enable the Gates button based on data in the selectedReplay field
     * Should only be enabled when Replay Type = Static
     */
    const gateDisabled = values.selectedReplay !== this.REPLAY_OPTIONS_STATIC;
    this._sharedService.publishGateDisabled(gateDisabled);
  }

  private _disableEnableCorridors(values): void {
    /*
     * Disable or re-enable the Corridors layer based on data in the selectedReplay field
     * Should only be enabled when Replay Type = Static
     */
    const disabled = values.selectedReplay !== this.REPLAY_OPTIONS_STATIC;
    this._sharedService.publishCorridorsDisabled(disabled);
  }

  private _updateSystemClock(replayType: string, newDt: any, endDt?: any): void {
    /*
     * Tracks and updates the date/times for each replay mode
     * Also publishes the systemCurrentDateTime which the Map subscribes to
     * replayType = animated, realtime or static
     */
    if (replayType === 'animated') {
      this._sharedService.publishSystemClock('animated', newDt);
    } else if (replayType === 'realtime' || replayType === 'realtime_delayed') {
      this._sharedService.publishSystemClock('realtime', newDt);
    } else {
      this._sharedService.publishSystemClock('static', newDt, endDt);
      // console.log('published static');
    }
    // Now publish the systemCurrentDateTime so the Map will know to update itself
    this._sharedService.publishSystemCurrentDateTime(newDt);
  }

  private _calcMaxStoredDataEndTime(): number {
    /*
     * The system can only display flight data up to a certain maximum time (ex current time - interval)
     * Calculate that maximum end time using the minimum delay supplied by the ws_config
     * min_delay is usually supplied in seconds
     * This will give us the very last time we can have data stored for
     */
    const floor = Math.floor(new Date().valueOf() / this._wsInterval) * this._wsInterval;
    const startDate = new Date(floor);
    const delay_in_ms = this._minDelay * 1000;
    return startDate.valueOf() - delay_in_ms;
  }

  /**
   * This is the farthest in the future we can call the API without getting an api error stating "Start time must be more than ..... seconds"
   * Calculate that maximum start time using the minimum delay supplied by the ws_config
   * min_delay is usually supplied in seconds
   * Then subtract the interval time (wsInterval) in order to ensure we can pull a full interval of data
   */
  private _calcMaxAPIStartTime(): number {
    const delay_in_ms = this._minDelay * 1000;
    return new Date().valueOf() - delay_in_ms - this._wsInterval;
  }

  private _displaySystemMessage(msgObj: any): void {
    /*
     * msgObj = {
     *  msg : the string we want displayed in the snackbar
     *  errType : api, wsconnect, http, timeout
     *  action? : {label: string, callback: string || function} for the snackbar action (if there is one)
     */
    const replay = this.form.get('selectedReplay').value;
    const isError = !!msgObj.errType;
    let msg = msgObj.msg;
    const errType = msgObj.errType;
    // If the system timed out then we want to leave the snackbar up indefinitely
    const dur = errType === 'wsconnect' ? DEFAULTS.WS_RECONNECT_TIMER : (errType !== 'timeout' ? DEFAULTS.DURATION_NORMAL_MSG : 0);
    const sbOptions = {duration: dur, panelClass: 'flight-tracker-snackbar'};
    const sbActionLabel = msgObj.action === undefined ? '' : msgObj.action.label;
    const sbAction = msgObj.action === undefined ? undefined : (typeof(msgObj.action.callback) === 'string' ? this[msgObj.action.callback].bind(this) : msgObj.action.callback );

    // Stop the animated timer if replay = animated and this is an error (either date range is wrong (api error) or server is down(wsconnect error)
    if (isError && replay === this.REPLAY_OPTIONS_ANIMATED) {
      this._stopSlider();
    }
    // If this is an api error returned for a static data pull then we need to replace the error message since the default api message is misleading
    if (errType === 'api' && replay === this.REPLAY_OPTIONS_STATIC) {
      // The delay is the minimum delay + the interval
      const delay = ((this._minDelay * 1000) + this._wsInterval) / (60 * 1000);
      msg = MSG_TEXT.MSG_STATIC_OUT_OF_RANGE.replace('${delay}', delay.toString());
    }

    const matSnackBarRef = this._snackBar.open(msg, sbActionLabel, sbOptions);
    if (!!sbActionLabel && sbAction !== undefined) {
      matSnackBarRef.onAction().subscribe(data => sbAction());
    } else if (sbAction !== undefined) {
      sbAction();
    }
  }

  /**
   * Tries to restart the websocket call that failed when the websocket was disconnected
   */
  private _retryDataCall(): void {
    const replay = this.form.get('selectedReplay').value;
    if (replay === this.REPLAY_OPTIONS_REALTIME) {
      this._sharedService.pullRealtimeDelayedSample(true);
    } else if (replay === this.REPLAY_OPTIONS_ANIMATED) {
      // I don't understand why but _startSlider needs to be called twice before it will trigger...need to fix this in the future
      this._startSlider();
      this._startSlider();
    } else {
      this.pullStaticTracks();
    }
  }
}
