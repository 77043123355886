import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WeatherInfoDialogComponent } from '../weather-info-dialog/weather-info-dialog.component';

import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import { map , catchError, retry, first, debounceTime, distinctUntilChanged, switchMap, filter} from 'rxjs/operators';

import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { ft_api_urls as FT_URLS, map_component as MAP_CONST } from '../env-constants';
import { WEATHER_ICON_KEY_VALUE_PAIRS, macnoms_brand } from '../env-constants';
import { weather_app as WEATHER_TEXT, search_toolbar as ST_TEXT, flight_toolbar as FT_TEXT } from '../env-translate';

import WKT from 'ol/format/WKT';
import { Extent } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import {HttpClient} from '@angular/common/http';


class Location {
  text: string;
  id: string;
  place_name: string;
  center: number[];
  bbox: number[];
}


@Component({
  selector: 'app-search-toolbar',
  templateUrl: './search-toolbar.component.html',
  styleUrls: ['./search-toolbar.component.scss'],
})


export class SearchToolbarComponent implements OnInit {
  // Date used by our clock
  public systemClock: any;
  public currentReplayType: string;
  public REPLAY_OPTIONS_ANIMATED = FT_TEXT.REPLAY_OPTIONS_ANIMATED;
  public REPLAY_OPTIONS_STATIC = FT_TEXT.REPLAY_OPTIONS_STATIC;
  public MACNOMS_BRAND = macnoms_brand;

  private _systemWeather: any;
  // Address input
  public searchCtrl: FormControl;
  // Observable that returns addresses for auto complete
  public filteredLocs: Observable<Location[]>;
  // Last selected address object
  private _lastSelectedLoc: any;
  // Used by the geolocation code
  private _mapboxKey: string;
  private _extent: string;

  public ARIA_LABEL_MENU_BUTTON = ST_TEXT.ARIA_LABEL_MENU_BUTTON;
  public PLACEHOLDER_ADDRESS = ST_TEXT.PLACEHOLDER_ADDRESS;
  public ARIA_LABEL_ADDRESS = ST_TEXT.ARIA_LABEL_ADDRESS;
  public ARIA_LABEL_WEATHER_BUTTON = ST_TEXT.ARIA_LABEL_WEATHER_BUTTON;
  public LABEL_CLEAR_BUTTON = ST_TEXT.LABEL_CLEAR_BUTTON;
  public LABEL_WEATHER = WEATHER_TEXT.LABEL_WEATHER;
  public MPH = WEATHER_TEXT.MPH;

  // Weather Constants
  public weatherIcon: string;
  public weatherTemp: number;
  public currentCondition: string;
  public windSummary: string;
  public observationTime: string;
  public windSpeed: number;
  public windDir: string;
  public windDeg: number;
  public pressure: number;
  public windDirAbbrev: string;
  public arrowDeg: number;

  constructor(private _apiService: ApiService, private _sharedService: SharedService, private _dialog: MatDialog, private _http: HttpClient) { }

  ngOnInit() {
    this.searchCtrl = new FormControl();
    this.filteredLocs = this._getFilteredLocations();
    this._initSharedServices();
  }

  private _initSharedServices(): void {
    /*
     * Initialize the services we need to track dates/times for our clock
     * Sets up the weather app
     * Also gets values we need for geolocation
     */
    this._sharedService.replay$.subscribe(
      data => {
        this.currentReplayType = data;
        this._updateWeatherDisplay();
      }
    );
    this._sharedService.systemClock$.subscribe(
      data => this.systemClock = data
    );

    this._sharedService.wsMapAPIKey$.pipe(first()).subscribe(
      data => this._mapboxKey = data
    );

    this._sharedService.wsExtent$.pipe(first()).subscribe(
      data => this._extent = data
    );

    this._sharedService.wsWeather$.subscribe(
      data => {
        this._systemWeather = data;
        this._updateWeatherDisplay();
      }
    );
  }

  public openMainSidenav(): void {
    /*
     * Function that triggers when the hamburger button is clicked
     */
    this._sharedService.publishSidenav('main');
  }

  public searchDisplayFn(loc?: any): string | undefined {
    /*
     * Applied to the auto complete on the search field
     * Allows us to save the selected option as an object but display the place name as the selected value
     */
    return loc ? loc.place_name : undefined;
  }

  public clearSearch(): void {
    /*
     * Clears the search input field when user clicks on the clear icon
     */
    this.searchCtrl.setValue('');
    this._lastSelectedLoc = undefined;
  }

  public selectAddress(evt): void {
    /*
     * Called when the user clicks on an address in the address auto complete
     * Stores the location object selected by the user from the address bar auto complete
     * Applies the location to the map by calling the sharedService observable
     */
    const loc = evt['option']['value'];
    const lat = loc['center'][1];
    const lon = loc['center'][0];
    const zoom = 18;
    this._lastSelectedLoc = loc;
    this._sharedService.publishMapCenter([lon, lat, zoom], true);
    // Google Analytics
    window['gtag']('event', 'search', {'event_category': 'General'});
  }

  public openWeatherDialog(): void {
    const dialogRef = this._dialog.open(WeatherInfoDialogComponent, {
      width: '360px',
      data: {
        weatherIcon: this.weatherIcon,
        weatherTemp: this.weatherTemp,
        weatherTempC: this.weatherTemp !== undefined ? this.weatherTemp - 32 : undefined,
        currentCondition: this.currentCondition,
        windSummary: this.windSummary,
        observationTime: this.observationTime,
        windDeg: this.windDeg,
        pressure: this.pressure,
        arrowDeg: this.arrowDeg
      }
    });
    // Google Analytics
    window['gtag']('event', 'weather', {'event_category': 'General'});
  }

  private _getFilteredLocations() {
    /* Calls the _callGeoLocation to get addresses for the type ahead
     * debounceTime - Number of milliseconds wait until the service is called
     * distinctUntilChanged - Only call the service if the value actually changes (prevents add/delete letter scenario)
     * switchMap - If there are multiple calls to the service, pull the values from each stream and create a single stream
     */
    return this.searchCtrl.valueChanges
    .pipe(
      debounceTime(400),
      distinctUntilChanged(),
      // Prevent blanks and undefined from being sent
      filter( val => !!val),
      switchMap(val => this._callGeoLocation('place', val)),
      map(results => results['features'])
    );
  }

 private _callGeoLocation(type: string, val: string): Observable<any> {
    /*
     * Mapbox call to pull geolocation information for our Address search
     */
    const baseUrl = FT_URLS.GEOLOCATION_BASE;
    const extUrl = FT_URLS.GEOLOCATION_AFTER_VAL;
    const accessToken = '&access_token=' + this._mapboxKey;
    const format = new WKT();
    const geom: Geometry = format.readGeometry(this._extent, { dataProjection: MAP_CONST.PROJ_WGS84 });
    const ext: Extent = geom.getExtent();
    const boundingBox = '&bbox=' + ext.join(',');
    const url = baseUrl + val + extUrl + boundingBox + accessToken;
    return this._http
      .get(url)
      .pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this._apiService.handleError) // then handle the error
      );
  }

  private _updateWeatherDisplay(): void {
    /*
     * {date_range:[<date>, <date>],weather: [<weather obj>]}
     * Parses the returned weather information and updates the weather app
     * epoch_seconds: number (1514033580)
     * id: number (1848870)
     * inm_low_wind
     * observation_time: string ("2017-12-23T12:53:00+00:00")
     * pressure_in: number (30.19)
     * runup_wind
     * temp_f: number (12)
     * weather: string ("A Few Clouds")
     * wind_degrees: number (280)
     * wind_dir: string ("West")
     * wind_mph: number(6)
     * windstring: string ("West at 5.8 MPH (5 KT)")
     */
    let w: any;
    if (!this._systemWeather || this.currentReplayType === this.REPLAY_OPTIONS_STATIC) {
      w = undefined;
    } else if (this.currentReplayType === this.REPLAY_OPTIONS_ANIMATED) {
      w = this._systemWeather['animated'][0];
    } else {
      w = this._systemWeather['realtime'][0];
    }

    if (w !== undefined) {
      // We may not always get data back for every parameter so handle those cases
      this.weatherTemp = w['temp_f'] || 0;
      this.currentCondition = w['weather'] || '';
      this.windSummary = w['windstring'] || '';
      this.windSpeed = w['wind_mph'] || 0;
      this.windDir = w['wind_dir'] || '';
      this.windDeg = w['wind_degrees'] || 180;
      this.pressure = w['pressure_in'] || 0;
      this.windDirAbbrev = this._calcDirection(this.windDeg);
      this.observationTime = w['observation_time'] || '';
    } else {
      // Clear the weather information
      this.weatherTemp = undefined;
      this.currentCondition = '';
      this.windSummary = '';
      this.windSpeed = undefined;
      this.windDir = '';
      this.windDeg = 180;
      this.pressure = undefined;
      this.windDirAbbrev = '';
      this.observationTime = '';
    }

    // Now calculate the direction that the arrow needs to point (it's the opposite of wind deg)
    this.arrowDeg = (this.windDeg || 0) + 180;
    this._mapWeatherIcon();
  }

  private _mapWeatherIcon(): void {
    /*
     * Use the currentCondition to map the correct weather icon
     */
    let iconKey = 'unknown';
    let foundAMatch = false;
    const kvPairs = WEATHER_ICON_KEY_VALUE_PAIRS;
    const iconMap = new Map(kvPairs);
    const weatherStringsMap = new Map();

    // Map the service strings to our icon keys
    weatherStringsMap.set('cloudy', ['Mostly Cloudy'])
      .set('fog', ['Mist', 'Smoke', 'Fog', 'Dust', 'Haze'])
      .set('hail', ['Hail', 'Light Hail', 'Heavy Hail', 'Small Hail', 'Hail Showers', 'Ice Pellet'])
      .set('thunderstorm', ['Thunderstorm'])
      .set('clearday', ['Clear', 'Fair'])
      .set('partlycloudy', ['Partly Cloudy', 'Clouds', 'Overcast'])
      .set('pouring', ['Heavy Rain', 'Squalls'])
      .set('rain', ['Rain', 'Drizzle', 'Light Rain'])
      .set('snow', ['Snow', 'Light Snow', 'Heavy Snow', 'Low Drifting Snow', 'Blowing Snow', 'Snow Showers', 'Snow Grains'])
      .set('snowrainmix', ['Freezing'])
      .set('windy', ['Funnel Cloud', 'Dust Whirls']);

    for (const [key, value] of weatherStringsMap) {
      for (const stringPartial of value) {
        if (this.currentCondition.indexOf(stringPartial) > -1) {
            iconKey = key;
            foundAMatch = true;
        }
      }
      if (foundAMatch) {
        break;
      }
    }

    this.weatherIcon = iconMap.get(iconKey);
  }

  private _calcDirection(deg: number): string {
    /*
     * Use the wind degrees to calculate our abbreviated direction
     */
    let dir: string;

    if (deg < 22.5 || deg > 337.5 && deg !== 999) {
      dir = 'N';
    } else if (deg < 67.5) {
      dir = 'NE';
    } else if (deg < 112.5) {
      dir = 'E';
    } else if (deg < 157.5) {
      dir = 'SE';
    } else if (deg < 202.5) {
      dir = 'S';
    } else if (deg < 247.5) {
      dir = 'SW';
    } else if (deg < 292.5) {
      dir = 'W';
    } else if (deg < 337.5) {
      dir = 'NW';
    } else if (deg === 999) {
      dir = 'V';
    }

    return dir;
  }

}



