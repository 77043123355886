import * as condition from 'ol/events/condition';
import {site_defaults as DEFAULTS} from '../../env-constants';
import {SharedService} from '../../shared.service';
import {map_component as MAP_CONST, feature_text_colors as TEXT_COLORS} from '../../env-constants';

import {ApiService} from '../../api.service';

export class BaseLayerService {
  public selectInteractionSupport = false;

  protected enable_feature_selection = false;
  protected _featureInformationSupport = false;
  protected _featureInformationEnabledAllFeatures = false;
  protected _displayAttributes: string[] = DEFAULTS.INFO_CHOICE_DEFAULT;

  // A list of operation numbers that should have attribute information
  // enabled on them.
  protected _selectedOperations: number[] = [];
  protected _selectedRMTs: number[] = [];

  // A boolean, when true feature information should be displayed
  // for all features.
  protected _interaction_condition: any = condition.click;
  protected _nomsLayerLabel: string;
  protected _tailsEnabled = false;
  protected _tailTimeSeconds = 30;
  protected layer;
  protected _interaction: any;
  public isEnabled = false;

  constructor(protected _map: any, protected _url: string,
    protected _bucketHoldCount: number,
    protected _sharedService: SharedService,
    protected _apiService: ApiService) {
  }

  public layerRemoved() {
    this.isEnabled = false;
  }

  public layerAdded() {
    this.isEnabled = true;
  }


  /**
   * A method that enables feature information (attribute) display for *all* features in this
   * layer.  Note that this will cause the list of specific operations we show data for to be ignored.
   */
  public toggleInformationDisplay(enabled: boolean): boolean {
    if (this._featureInformationSupport && this._featureInformationEnabledAllFeatures !== enabled) {
      this._featureInformationEnabledAllFeatures = enabled;
      //            this.layer.set('_featureInformationEnabled', enabled);
      /**
   * for layers that require it, mark the layers as changed so that
   * the feature information will be re-rendered.
   */
      this._markLayersChanged();
    }
    return enabled;

  }

  /**
   * Called when the list of display fields changes.  This will update how we display
   * data on a flight.
   */
  public setDisplayFields(fields: string[]): void {

    if (fields.length) {
      this._displayAttributes = fields;
    } else {
      this._displayAttributes = DEFAULTS.INFO_CHOICE_DEFAULT;
    }
    this._markLayersChanged();
  }


  /**
   * The functions below ought to be implemented by inheriting layer services
   * that require them.  The placeholders are just here..
   */
  public toggleSelectedRMT(opnum: number): number[] {
    return [];
  }

  public addSelectedRMT(opnum: number): number[] { return []; }

  public removeSelectedRMT(opnum: number): number[] { return []; }

  public clearSelectedRMTs() {}

  public toggleSelectedOperation(opnum: number): number[] { return []; }

  public addSelectedOperation(opnum: number): number[] { return []; }

  public removeSelectedOperation(opnum: number): number[] { return []; }

  public clearSelectedOperations() {}



  protected _markLayersChanged(): void {
  }


  public toggleAircraftTails(enabled: boolean): void {
    this._tailsEnabled = enabled;
    this._markLayersChanged();
  }


  public handleSelectInteraction(evt) {
    throw new TypeError('Method not implemented in child class: ' + this);
  }

  protected _getTextColor(key: string): string {
    const colorMap = TEXT_COLORS[key];
    return this._baseLayerColorConversion(colorMap);
  }

  protected _baseLayerColorConversion(colorMap: string | string[]): string {
    const base_layer = this._sharedService.pullBaseLayer() || MAP_CONST.BASE_LAYERS[0].url;
    const layer_index = MAP_CONST.BASE_LAYERS.findIndex(o => o.url === base_layer);
    let color: string;
    // Check the colormap, it should be an array with the same number of values as we have base layers
    // If it's not an array (and not blank) use the string value
    // If it's an array with an index that doesn't match (ie it only has 3 elements and we're asking for the 4th) then grab the last element
    // Otherwise get the correct color, and if all this fails just send them #fff
    if (Array.isArray(colorMap)) {
      color = colorMap[layer_index] || colorMap[colorMap.length - 1];
    } else {
      color = !!colorMap ? colorMap : undefined;
    }

    return (color || '#fff');
  }
}
