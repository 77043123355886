import {Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {gate_dialog as GATE_TEXT, aircraft_opertype as AC_TYPE} from '../env-translate';
import {gate_chart_adflag_colors as GATE_COLORS, global_chart_config as GLOBAL_CHART_CONFIG} from '../env-constants';
import * as Qty from 'js-quantities';

@Component({
  selector: 'app-gate-info-dialog',
  templateUrl: './gate-info-dialog.component.html',
  styleUrls: ['./gate-info-dialog.component.scss']
})
export class GateInfoDialogComponent implements OnInit {

  public BUTTON_CLOSE = GATE_TEXT.BUTTON_CLOSE;
  public TITLE_CHART = GATE_TEXT.TITLE_CHART;
  public LABEL_Y_AXIS = GATE_TEXT.LABEL_Y_AXIS;
  public LABEL_X_AXIS = GATE_TEXT.LABEL_X_AXIS;
  public TEXT_FLIGHT = GATE_TEXT.TEXT_FLIGHT;
  public TEXT_DISTANCE = GATE_TEXT.TEXT_DISTANCE;
  public TEXT_ALTITUDE = GATE_TEXT.TEXT_ALTITUDE;
  public TEXT_AIRCRAFT = GATE_TEXT.TEXT_AIRCRAFT;
  public TEXT_GATE_TIME = GATE_TEXT.TEXT_GATE_TIME;
  public TEXT_ROUTE = GATE_TEXT.TEXT_ROUTE;

  public GLOBAL_CHART_CONFIG = GLOBAL_CHART_CONFIG;
  public chartData: any;
  public chartLayout: any;

  private units = 'I'; // valid values are I (imperial) or M (metric)

  constructor( @Inject(MAT_DIALOG_DATA) private _data: any) {}

  ngOnInit() {
    this._buildChart();
  }

  private _hoverText(data: any): string {
    const info = [];
    const gateTime = new Date(data.gate_time).toLocaleTimeString();
    const operType = !data.opertype ? AC_TYPE['U'] : AC_TYPE[data.opertype];
    info.push(createMarkup(this.TEXT_FLIGHT, data.flight_id));
    info.push(createMarkup(this.TEXT_DISTANCE, this.convertDistance(data.gate_distance_m)));
    info.push(createMarkup(this.TEXT_ALTITUDE, this.convertDistance(data.gate_altitude_m)));
    info.push(createMarkup(this.TEXT_GATE_TIME, gateTime));
    info.push(createMarkup(this.TEXT_AIRCRAFT, operType + '(' + data.actype + ')'));
    info.push(createMarkup(this.TEXT_ROUTE, calcRoute()));
    return info.join('<BR>');

    function calcRoute() {
      let dep_apt, arr_apt;
      if (data.adflag === 'A') {
        arr_apt = data.airport;
        dep_apt = data.otherport;
      } else if (data.adflag === 'D') {
        dep_apt = data.airport;
        arr_apt = data.otherport;
      } else if (data.adflag === 'D/A') {
        const tmp = data.otherport.split(/\//);
        dep_apt = tmp[0];
        arr_apt = tmp[1];
      }
      return [dep_apt || 'UKN', '→', arr_apt || 'UKN'].join('');
    }

    function createMarkup(label: string, val: any): string {
      return '<SPAN>' + label + '</SPAN>' + val;
    }
  }


  private _buildChart(): void {
    this.chartData = [{
      x: this._data.map(point => this.convertDistance(point['gate_distance_m'])),
      y: this._data.map(point => this.convertDistance(point['gate_altitude_m'])),
      text: this._data.map(point => this._hoverText(point)),
      hoverinfo: 'text',
      mode: 'markers',
      type: 'scatter',
      marker: {
        symbol: 'circle',
        size: 10,
        color: this._data.map(point => GATE_COLORS[point['adflag']]),
        line: {
          color: 'rgba(255, 255, 255, .5)',
          width: 1
        }
      }
    }];

    this.chartLayout = {
      paper_bgcolor: '#009ed2',
      plot_bgcolor: '#009ed2',
      margin: {
        l: 50,
        r: 24,
        t: 24,
        b: 48,
        pad: 4
      },
      dragmode: 'zoom',
      hovermode: 'closest',
      autosize: true,
      height: 350,
      showlegend: false,
      font: {
        family: 'Roboto',
        size: 12,
        color: '#ffffff'
      },
      xaxis: {
        title: this.LABEL_X_AXIS,
        tickangle: -60,
        color: '#ffffff',
        tickfont: {
          size: 8
        },
        showticklabels: true,
        linecolor: 'rgba(255, 255, 255, .1)',
        linewidth: 2,
        showline: true,
        gridcolor: 'rgba(255, 255, 255, .5)',
        gridwidth: 1,
        showgrid: true,
        mirror: true
      },
      yaxis: {
        title: this.LABEL_Y_AXIS,
        color: '#ffffff',
        tickfont: {
          size: 8
        },
        showticklabels: true,
        linecolor: 'rgba(255, 255, 255, .1)',
        linewidth: 2,
        showline: true,
        gridcolor: 'rgba(255, 255, 255, .5)',
        gridwidth: 1,
        showgrid: true,
        mirror: true
      }
    };
  }

  private convertDistance(val: string): number {
    const v = new Qty(val, 'm');
    if (/^i/i.test(this.units)) {
      return v.to('ft').toPrec('1 ft').scalar;
    } else {
      return v.to('m').toPrec('.1 m').scalar;
    }
  }

}
