import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Projection from 'ol/proj/Projection';
import Text from 'ol/style/Text';
import Circle from 'ol/style/Circle';
import BaseLayer from 'ol/layer/Base';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';
import GeoJSON from 'ol/format/GeoJSON';
import Icon from 'ol/style/Icon';
import {SharedService} from '../../shared.service';
import {ApiService} from '../../api.service';
import {aircraft_icons as ICONS_CONST, unit_constants as UNIT_CONSTANTS, attribute_names as ATTRIBUTE_NAMES, map_component as MAP_CONSTANTS} from '../../env-constants';

import {BaseOperationLayerService} from './base-operation-layer.service';

/**
 * the OperationsLayerService is a layergroup, with multiple child
 * layers for various data sets.
 */
export class BaseAnimatedLayerService extends BaseOperationLayerService {
  public selectInteractionSupport = true;
  protected _featureInformationSupport = true;
  protected _operationsCache: any[] = [];
  public nomsLayerLabel = 'animated_tracks';
  public time_enabled = true;
  protected _operationTailSeconds = 30;
  protected _pointGeometries: any[] = [];
  protected tilePixels: Projection;
  protected featureReader;


  constructor(_map: any, _url: string, _bucketHoldCount: number,
    protected _sharedService: SharedService,
    protected _apiService: ApiService) {
    super(_map, _url, _bucketHoldCount, _sharedService, _apiService);
    this.featureReader = new GeoJSON({'featureProjection': 'EPSG:3857'});
    this.tilePixels = new Projection({
      code: 'TILE_PIXELS',
      units: 'tile-pixels'
    });
    this._sharedService.mapPointDistanceGeom$.subscribe((geometries) => {
      this._pointGeometries = geometries || [];
    });

  }


  public getLayer(): any {
    /**
     * We only create one layer for the service, so handle that
     * here.
     */
    if (this.layer === undefined) {
      // Create a new layer.
      this.layer = new LayerGroup({
        layers: [],
        zIndex: 100
      });
    }

    this.setDisplayFields(this._sharedService.pullInfoChoices() || []);
    return this.layer;
  }

  protected publishOpsCounts(current_time: number) {
    let total_operations = 0;
    let displayed_operations = 0;
    this.layer.getLayers().forEach((layer) => {
      layer.getSource().getFeatures().forEach((feature) => {
        if (feature.get('epoch_seconds') === current_time) {
          total_operations++;
          const operation = this.getOperationDetails(feature.get('opnum'));
          if (operation && this._filterFeature(operation)) {
            displayed_operations++;
          }
        }
      });
    });
    this._sharedService.publishActiveData({
      'active': total_operations,
      'displayed': displayed_operations
    });
  }


  /**
   * Given an operations identifier return the details for the related operation
   * using the cached data.  This ought to ALWAYS find the operation data
   * since it's in lock step with the track data.
   */
  public getOperationDetails(opnum: number): any {
    const found_set = this._operationsCache.find(opsdata => {
      if (opsdata[opnum]) {
        return true;
      }
    });
    if (found_set) {
      return found_set[opnum];
    }
    throw TypeError(['Unable to locate operation:', opnum].join(' '));
  }

  /**
   * Called to add a new set of data to the group.
   */
  public addDataSet(data: any): void {
    const operations = data.operations;
    const features = data.features;
    let stime = -1;
    let etime = -1;
    Object.keys(operations).forEach((op) => {
      if (stime === -1) {
        stime = new Date(operations[op].stime).valueOf() / 1000;
        etime = new Date(operations[op].etime).valueOf() / 1000;
      } else {
        stime = Math.min(stime, new Date(operations[op].stime).valueOf() / 1000);
        etime = Math.min(etime, new Date(operations[op].etime).valueOf() / 1000);
      }
    });

    const vectorSource = new VectorSource({
      features: this.featureReader.readFeatures(features)
    });

    const layer = new Vector({
      source: vectorSource,
      zIndex: 100,
      properties: {
        selectable: true,
        noms_layer_label: this.nomsLayerLabel,
        stime: stime,
        etime: etime,
      }
    });

    this.style = this.getStyle(this.layer, false);
    layer.setStyle(this.style);
    const layerCollection = this.layer.getLayers();
    layerCollection.push(layer);
    // Only keep three layers on the collection.
    if (layerCollection.getLength() > this._bucketHoldCount) {
      layerCollection.removeAt(0);
    }

    // Keep the operations cache at max 3 long.
    this._operationsCache.push(operations);
    if (this._operationsCache.length > this._bucketHoldCount) {
      this._operationsCache.shift();
    }

  }


  public getStyle(layer: BaseLayer, show_all: any): any {
    /**
     * This style is used when we want to show a plane for a layer based on
     * the current time that is set on the map.  The layer should have the
     * time set on it as a property - so when the time changes the layer should
     * update and the style will properly use that information.
     *
     * For this reason the layer needs to be provided to the style on creation,
     * additionally this style will get some options that tell it how exactly
     * it needs to work.
     */
    show_all = show_all || false;
    const textStyle = new Text({
      textAlign: 'left',
      textBaseline: 'ideographic',
      font: 'normal 12px Arial',
      fill: new Fill({color: this._getTextColor('fill')}),
      stroke: new Stroke({color: this._getTextColor('stroke'), width: 3}),
      offsetX: 15,
      offsetY: -15,
      overflow: true,
      rotation: 0
    });

    const this_style = new Style({
      text: textStyle,
    });

    const getIcon = (opertype: string, category: string): string => {
      const base_icon = ICONS_CONST[opertype] || ICONS_CONST['*'];
      if (typeof base_icon === 'object') {
        return base_icon[category] || ICONS_CONST['*'];
      } else {
        return base_icon;
      }
    };
    /**
     * We do this here so that the function below has access to it, otherwise it has
     * no reference to the object itself, which makes life harder overall since we then
     * need to propagate stuff to all the layers.
     */
    return (feature, resolution) => {
      const ctime = layer.get('current_time');
      if ((ctime &&
        ctime === feature.get('epoch_seconds')) || show_all) {

        const operation = this.getOperationDetails(feature.get('opnum'));

        if (operation && this._filterFeature(operation)) {
          const icon = new Icon({
            size: [20, 20],
            rotation: (feature.get('heading') * Math.PI) / 180, // *(Math.PI/180), //Rotation is in radians
            scale: 1,
            color: this._styleADFlag(operation.adflag),
            opacity: 1,
            src: getIcon(operation.opertype, operation.category)
          });
          this_style.setImage(icon);
          if ((this._featureInformationEnabledAllFeatures && resolution < MAP_CONSTANTS.ALL_FEATURE_MAX_RESOLUTION) ||
            this._selectedOperations.indexOf(operation.opnum) > -1) {
            const text_components = [];
            this._displayAttributes.forEach(item => {
              const config = ATTRIBUTE_NAMES[item];
              let data;
              if (typeof config.attribute === 'string') {
                data = feature.get(config.attribute) || operation[config.attribute];
              } else {
                data = config.attribute(operation, feature, this._pointGeometries);
              }
              if (data) {
                text_components.push(data + (UNIT_CONSTANTS[item] || ''));
              }
            });
            textStyle.setText(text_components.join('\n'));
            textStyle.getFill().setColor(this._getTextColor('fill'));
            textStyle.getStroke().setColor(this._getTextColor('stroke'));
          } else {
            textStyle.setText(null);
          }
          return [this_style];
        }
      } else if (this._tailsEnabled && feature.get('epoch_seconds') < ctime && feature.get('epoch_seconds') > (ctime - this._tailTimeSeconds)) {
        const operation = this.getOperationDetails(feature.get('opnum'));
        if (operation && ctime < operation.epoch_etime && this._filterFeature(operation)) {
          const image = new Circle({
            radius: .75,
            fill: new Fill({
              color: this._styleADFlag(operation.adflag),
            })
          });
          this_style.setImage(image);
          textStyle.setText(null);
          return [this_style];
        }
      }
      // Don't show anything.
      return [];
    };
  }
}
